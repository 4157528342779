import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { SignUpData } from "../common/types/users-service";
import { useDeviceType } from "../icecube-ux/hooks/provideDeviceType";
import useWindowSize from "../icecube-ux/hooks/useWindowSize";
import { _ } from "../languages/helper";
import { checkEmailValidity } from "../lib/usersService";
import { SignUpFormData } from "../pages/login/SignUp/SignUpFormData";
import { isPasswordStrongEnough } from "../pages/login/utils";
import { deriveUserTimezone } from "../utils/dateUtils";
import { modifySearchParams } from "../utils/searchParameterUtils";
import {
  SHOPIFY_SHOP_STORAGE_KEY,
  SHOPIFY_UUID_STORAGE_KEY,
} from "../utils/shopifyUtils";
import { trackEvent, TRACKING_EVENTS } from "../utils/trackingUtils";

import { useAuth } from "./auth/auth";

export const useSignup = (signupDetails: {
  location?: string;
  testVersion?: string;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobileOrTablet } = useDeviceType();
  const { height, width } = useWindowSize();

  const jsonShopData = localStorage.getItem(SHOPIFY_SHOP_STORAGE_KEY);
  const shopUUID = localStorage.getItem(SHOPIFY_UUID_STORAGE_KEY);
  const shopData = jsonShopData
    ? (JSON.parse(jsonShopData) as {
        myshopify_domain?: string;
        shop_owner?: string;
        name?: string;
      })
    : {};

  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  useEffect(() => {
    trackEvent(TRACKING_EVENTS.USER_SIGNUP_VISITED, {
      windowSize: { height, width },
      isMobileOrTablet,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const error = params.get("error");

    if (error === null) {
      return;
    }

    switch (error) {
      case "invalid_email": {
        setError({
          sso: _`Sorry, personal email domains are not allowed. Please provide a valid business email address.`,
        });
        break;
      }
      case "has_user": {
        setError({
          sso: "Sorry! This e-mail address is already in use.",
        });
        break;
      }
      default: {
        setError({
          sso: _`Something went wrong. If problem persists, please let us know so we can help.`,
        });
        break;
      }
    }

    modifySearchParams(navigate, (draftParams) => {
      delete draftParams.error;
    });
  }, [navigate, params]);

  useEffect(() => {
    const signupData = params.get("signup_data");

    if (signupData === null) {
      return;
    }
    try {
      auth.setSignupData(JSON.parse(signupData) as SignUpData);
    } catch (e) {
      console.error(e);
    }

    modifySearchParams(navigate, (draftParams) => {
      delete draftParams.signup_data;
    });
  }, [navigate, params, auth]);

  const [error, setError] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<SignUpFormData>({
    name: shopData.shop_owner || "",
    email: params.get("email") || "",
    password: "",
    company: shopData.name || "",
    website: shopData.myshopify_domain ?? "",
    timezone: deriveUserTimezone(),
  });

  const updateFormData = (
    id: "email" | "password" | "timezone",
    value: string,
  ) => {
    setFormData({ ...formData, [id]: value });
  };

  const trackSignupSubmitted = (sso?: string) => {
    trackEvent(TRACKING_EVENTS.USER_SIGNUP_SUBMITTED, {
      name: formData.name,
      email: formData.email,
      website: formData.website,
      company: formData.company,
      timezone: formData.timezone,
      windowSize: { height, width },
      isMobileOrTablet,
      location: signupDetails.location,
      testVersion: signupDetails.testVersion,
      path: location.pathname,
      sso,
    });
  };

  const handleSignUp = async () => {
    trackSignupSubmitted();

    setError({});

    const passwordValidOrError = isPasswordStrongEnough(formData.password);
    if (passwordValidOrError !== true) {
      setError({
        password: passwordValidOrError,
      });
      return;
    }

    setLoading(true);
    try {
      const emailValid = await checkEmailValidity(formData.email);
      if (emailValid.free && !jsonShopData) {
        setError({
          email: _`Sorry, personal email domains are not allowed. Please provide a valid business email address.`,
        });
        setLoading(false);
        return;
      }
      if (emailValid.disposable) {
        setError({
          email: _`Sorry, disposable email domains are not allowed. Please provide a valid business email address.`,
        });
        setLoading(false);
        return;
      }
    } catch (e) {
      console.error(e);
    }

    const signupResult = await auth.signUp(
      formData.name,
      formData.email,
      formData.password,
      isMobileOrTablet,
      shopUUID || undefined,
      signupDetails.testVersion,
      signupDetails.location,
    );
    setLoading(false);

    if (signupResult === "already_loggedin") {
      window.location.href = "/";
      return;
    } else if (signupResult === "email_in_use") {
      setError({
        email: _`Sorry! This email address is already in use, please try logging in.`,
      });
      return;
    } else if (signupResult === "email_invalid") {
      setError({
        email: _`This email address is not valid`,
      });
      return;
    } else if (signupResult === "invalid_password") {
      setError({
        password: _`The password is not valid.`,
      });
      return;
    }

    window.location.href = `${window.location.protocol}//${
      window.location.host
    }/emailConfirmation?email=${encodeURIComponent(formData.email)}`;
  };

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      trackEvent(TRACKING_EVENTS.USER_SIGNUP_FAILED, {
        error: error,
        isMobileOrTablet,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    isMobileOrTablet,
    formData,
    error,
    loading,
    handleSignUp,
    updateFormData,
    trackSignupSubmitted,
  };
};
