import styled from "styled-components";

import { useBootstrap } from "../../hooks/bootstrap";
import { useDatePickerMinMaxDate } from "../../hooks/useDatePickerMinMaxDate";
import {
  Box,
  Calendar,
  Checkbox,
  Dropdown,
  Flex,
  Grid,
  Select,
  Text,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  ComparePeriod,
  DateRange,
  Granularity,
  PREFILTERS_COMPARE,
  SmartDateComparePeriod,
  bfcmPrefilters,
  getCompareDateRange,
  demoDateBoundaries,
  isComparePrefilterInRange,
} from "../../utils/dateUtils";

const StyledUneditableDateInput = styled(Text).attrs({
  variant: "body12Regular",
  color: theme.colors.text80,
  lineHeight: "34px",
})`
  background: ${theme.colors.bgLight20};
  padding: 0 12px;
  border-radius: 4px;
`;

interface ComparisonTypeSelectorProps {
  matchingDaysOfWeek: boolean | undefined;
  onDayOfWeekMatchingChange: (value: boolean) => void;
  range: DateRange;
  granularity: Granularity | "none" | undefined;
  compareRange: DateRange;
  onCompareRangeChange: (range: DateRange) => void;
  period: SmartDateComparePeriod;
  onPeriodTypeChange: (period: SmartDateComparePeriod) => void;
}

export default function ComparisonTypeSelector({
  matchingDaysOfWeek,
  onDayOfWeekMatchingChange,
  compareRange,
  range,
  granularity,
  onCompareRangeChange,
  period,
  onPeriodTypeChange,
}: ComparisonTypeSelectorProps) {
  const { isDemoData, weekStart } = useBootstrap();
  const { minDate, maxDate } = useDatePickerMinMaxDate();
  const handleSetComparisonRange = (dateRange: DateRange) => {
    const newCompareRange = getCompareDateRange(
      range,
      period,
      weekStart,
      dateRange,
      !matchingDaysOfWeek,
      granularity,
    );
    onCompareRangeChange(newCompareRange);
  };

  const handleToggleDayOfWeekMatching = () => {
    const newCompareRange = getCompareDateRange(
      range,
      period,
      weekStart,
      compareRange,
      !matchingDaysOfWeek,
      granularity,
    );
    onDayOfWeekMatchingChange(!matchingDaysOfWeek);
    onCompareRangeChange(newCompareRange);
  };

  const handlePeriodTypeChange = (value: string) => {
    const newCompareRange = getCompareDateRange(
      range,
      value as ComparePeriod,
      weekStart,
      compareRange,
      matchingDaysOfWeek,
      granularity,
    );
    onPeriodTypeChange(value as ComparePeriod);
    onCompareRangeChange(newCompareRange);
  };

  const prefilters = [
    ...PREFILTERS_COMPARE.map((p) => ({
      label: p.label,
      value: p.value,
    })),
    ...bfcmPrefilters.map((p) => ({
      label: p.label,
      value: p.value,
    })),
  ];
  const applicablePrefilters = isDemoData
    ? prefilters.filter((p) =>
        isComparePrefilterInRange({
          range,
          dateBoundaries: demoDateBoundaries,
          prefilter: p.value,
        }),
      )
    : prefilters;

  return (
    <Flex flexDirection="column" gap={10}>
      <Select
        label={_`Compare against`}
        options={[
          { label: _`Custom date range`, value: "range" },
          ...applicablePrefilters,
        ]}
        selected={[period]}
        onChange={(v) =>
          handlePeriodTypeChange(`${v[0]}` as SmartDateComparePeriod)
        }
        block
      />
      <Grid gridTemplateColumns="1fr 1fr" columnGap="10px" rowGap="0">
        <Text variant="body11Medium" color={theme.colors.text100}>
          {_`Start date`}
        </Text>
        <Text variant="body11Medium" color={theme.colors.text100}>
          {_`End date`}
        </Text>
        {period === "range" && (
          <Dropdown
            block
            closeOnClick
            placeholder={compareRange?.start.format("YYYY-MM-DD") || ""}
          >
            <Box margin="-8px" width={"280px"} height={"280px"}>
              <Calendar
                selectSingleDate
                onSelectRange={handleSetComparisonRange}
                selectionRange={compareRange}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Box>
          </Dropdown>
        )}
        {period !== "range" && (
          <StyledUneditableDateInput>
            {compareRange?.start.format("YYYY-MM-DD") || ""}
          </StyledUneditableDateInput>
        )}
        <StyledUneditableDateInput>
          {compareRange?.end.format("YYYY-MM-DD") || ""}
        </StyledUneditableDateInput>
      </Grid>
      {period !== "range" && period !== "shiftedPeriod" && (
        <Checkbox
          label={_`Matching days of week`}
          checked={matchingDaysOfWeek}
          onClick={handleToggleDayOfWeekMatching}
        />
      )}
    </Flex>
  );
}
