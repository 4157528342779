import type { SupportedPermissions } from "../../common/permissions/types";
import type { SetupState } from "../../common/setupState/types";
import type { Subscription } from "../../common/types/subscription-service";
import type { ObjectValues } from "../types";

export const FALLBACK_AVATAR = "/img/avatar.png";
export const SUPPORTED_ROLES = [
  "operator",
  "admin",
  "agency-connector",
] as const;
export type SupportedRole = (typeof SUPPORTED_ROLES)[number];
export const isSupportedRole = (v: unknown): v is SupportedRole => {
  return (
    typeof v === "string" &&
    SUPPORTED_ROLES.includes(v.toLowerCase() as SupportedRole)
  );
};

export interface AvailableTenant {
  id: string;
  avatar?: string;
  companyName?: string;
}

export interface UserAttributes {
  id: string;
  email: string;
  name: string;
  tenantId: string;
  role: SupportedRole | "god";
  emailValidated: boolean;
  sso: null | "shopify";
  storeName?: string;
  status?: string | null;
  availableTenants?: AvailableTenant[];
  mfaActivated: boolean;
}

export const PricingModels = {
  PACKAGE: "PACKAGE",
  USAGE_BASED: "USAGE_BASED",
  GMV_TIER_BASED: "GMV_TIER_BASED",
  FIXED_PRICE: "FIXED_PRICE",
} as const;
type PricingModel = ObjectValues<typeof PricingModels>;
export const DEFAULT_PRICING_MODEL = PricingModels.GMV_TIER_BASED;

export interface UserSetting {
  name: string;
  tenantId?: string;
  storeName?: string;
  role: SupportedRole | "god";
  canDebugConnectors?: boolean;
  availableTenants?: {
    [id: string]: {
      role: SupportedRole;
    };
  };
}

export interface GetUserSettings {
  error: boolean;
  result: UserSetting;
}

export interface Connectors {
  [key: string]: { id?: string; status?: string; disabled?: boolean }[];
}

export type FormResponse = {
  questionKey: string;
  selection: string[];
  skipped: boolean;
  isAutocompleted?: boolean;
};

export interface OnboardingFormData {
  isCompleted: boolean;
  responses: FormResponse[];
}

export interface TenantSettings {
  website: string;
  timezone: string;
  timezoneSetByDefault?: boolean;
  currency: string;
  revenue_computation_include_discounts: 0 | 1;
  revenue_computation_include_refunds: 0 | 1;
  revenue_computation_include_shipping: 0 | 1;
  revenue_computation_include_tax: 0 | 1;
  revenue_computation_include_tip: 0 | 1;
  revenue_computation_deduct_cost_of_products: 0 | 1;
  revenue_computation_include_cancelled: 0 | 1;
  revenue_computation_include_0_orders: 0 | 1;
  revenue_computation_deduct_expenses: 0 | 1;
  key_indicators: string[];
  weekstart: string;
  avatar: string;
  snowflake?: boolean;
  warehouse?: "default" | "background" | "medium";
  whiteLabeling?: boolean;
  ga_four_start_date?: { [key: string]: string };
  ga_end_date?: { [key: string]: string };
  use_short_number_format?: number;
  use_thousands_separator_number_format?: number;
  addons?: string[];
  totalAnnualRevenue?: string;
  pricing_model?: PricingModel;
  price_determining_gmv?: number;
  is_price_determining_gmv_fixed?: boolean;
  fixed_price_config?: Record<string, { enabled: boolean; price: number }>;
  company?: string;
  enable_ask_polar_insights?: boolean;
  disableAskPolarEnhancedMode?: boolean;
  onboarding_form?: OnboardingFormData;
  use_new_shopify_tables?: 0 | 1;
}

export interface GetTenantSettings {
  error: boolean;
  result: TenantSettings;
}

export interface TenantStates {
  dashboard?: number;
  dashboardReadyAt?: string;
  availablePlans?: boolean[];
  appActivated?: boolean;
  hasBookedCall?: boolean;
  hasBookedCAPICall?: boolean;
  requestedConnectors?: string[];
  insertedPixelKISection?: boolean;
  displayAudienceResults?: boolean;
  isOnCustomPlan?: boolean;
  isSubscriptionUnpaid?: boolean;
  needsMigrationToStripe?: boolean;
  requireMeeting?: boolean;
  requireUpgrade?: boolean;
  autoKeyIndicatorsRefreshQuota?: number;
  showUsageBasedPlusPlan?: boolean;
  showInventoryAlerts?: boolean;
  useAttributionModelV2?: boolean;
  canEditFacebookCampaigns?: boolean;
  shouldUpdateShopifyScopes?: string[];
  limitedNavigationMode?: "activation";
}

export interface GetTenantStatesResponse {
  error: boolean;
  result: TenantStates | null;
}

export interface Tenant {
  id: string;
  companyName: string;
  ownerId: string;
  createdAt: string;
  settings: TenantSettings;
  states: TenantStates;
  integrations?: {
    slack?: boolean;
  };
}

export interface BootstrapData {
  tenant: Tenant;
  subscription: Subscription;
  setupState: SetupState;
  permissions: SupportedPermissions;
}

export interface GetTenantResult {
  error: boolean;
  result: Tenant;
}

export interface GetTenantsResult {
  error: boolean;
  result: Array<{
    id: string;
    company_name: string;
    created_at: string;
    owner_id: string;
  }>;
}

export interface BaseResult {
  error: boolean;
}

export interface TenantUserRecord {
  user_id: string;
  email: string;
  created_at: string;
  user_role: string;
  tenant_id: string;
}

export interface CheckEmailResult {
  error: boolean;
  result?: { free: boolean; disposable: boolean; undeliverable: boolean };
}

export type SignUpData = {
  limitedNavigationMode?: "activation";
};
