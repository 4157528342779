import classNames from "classnames";
import { forwardRef, useCallback, useEffect, useState } from "react";

import { _ } from "../../languages/helper";
import { EMPTY_ARRAY } from "../../utils/utils";
import { Banner } from "../Banner";
import ScrollSync from "../hooks/ScrollSync/ScrollSync";
import ScrollSyncPane from "../hooks/ScrollSync/ScrollSyncPane";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { BODY_CELL_HEIGHT } from "./components/ReportTableBodyCell";
import ReportTableBodyLines from "./components/ReportTableBodyLines";
import ReportTableFooterColumns, {
  ReportTableFooterColumn,
} from "./components/ReportTableFooterColumns";
import ReportTableHeaderLines from "./components/ReportTableHeaderLines";
import ReportTableSubHeaderLines, {
  ReportTableSubHeaderTexts,
} from "./components/ReportTableSubHeaderLines";
import {
  ReportTableBodyLine,
  ReportTableCheckboxConfig,
  ReportTableHeaderColumn,
} from "./components/types";
import {
  ProvideReportTable,
  ReportTableOrdering,
  useReportTable,
} from "./ReportTable.context";
import {
  StyledReportBody,
  StyledReportFooterLeftTotalCell,
  StyledReportHeader,
  StyledReportFooter,
  StyledReportSide,
  StyledReportTable,
} from "./ReportTable.styled";
import {
  MAX_PIVOTED_COLUMNS,
  ReportTableSideSplit,
  useReportTablePivot,
} from "./ReportTablePivot.hook";
import {
  ReportTableHeightManagementType,
  useReportTableSizeManager,
} from "./ReportTableSizeManager.hook";

type ReportTableProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps &
  ReportTableCheckboxConfig & {
    header: ReportTableSideSplit<ReportTableHeaderColumn[][]>;
    subHeaderTexts?: ReportTableSideSplit<ReportTableSubHeaderTexts>;
    body: ReportTableSideSplit<ReportTableBodyLine[]>;
    footer: ReportTableFooterColumn[];
    heightManagementType?: ReportTableHeightManagementType;
    heightValue?: number;
    page?: number;
    ordering?: ReportTableOrdering;
    loading?: boolean;
    loadingTotal?: boolean;
    loadingSecondary?: boolean;
    loadingSecondaryTotal?: boolean;
    rowsCount?: number;
    pivoted?: boolean;
    readOnly?: boolean;
    onReorderHeaderColumns?: (
      left: boolean,
      values: Array<string | number>,
      from: number,
      to: number,
    ) => void;
    onChangeOrdering?: (ordering: ReportTableOrdering) => void;
    onDeleteColumn?: (left: boolean, key: string) => void;
    allowHover?: boolean;
    cellSize?: "regular" | "big";
    gap?: number;
    maxBodyHeight?: number;
    minimalColumnHeader?: boolean;
  };

export default forwardRef<HTMLDivElement, ReportTableProps>((props, ref) => {
  return (
    <ProvideReportTable allowHover={props?.allowHover}>
      <ReportTableContent ref={ref} {...props} />
    </ProvideReportTable>
  );
});

const ReportTableContent = forwardRef<HTMLDivElement, ReportTableProps>(
  (
    {
      maxCheckedRows,
      checkbox,
      checkboxHeaderIcon,
      checkedRows,
      onRowCheckboxChange,
      className,
      style,
      header,
      body,
      footer,
      subHeaderTexts,
      ordering = { columnKey: "", direction: "DESC" },
      page = 0,
      heightManagementType = ReportTableHeightManagementType.MAX_HEIGHT,
      heightValue = 300,
      pivoted,
      loading,
      loadingTotal,
      loadingSecondary,
      loadingSecondaryTotal,
      rowsCount,
      onReorderHeaderColumns,
      onChangeOrdering,
      onDeleteColumn,
      readOnly,
      cellSize,
      gap = 4,
      maxBodyHeight,
      minimalColumnHeader,
      ...interactionEvents
    },
    forwardedRef,
  ) => {
    const {
      registerChangeOrderingCallback,
      registerDeleteColumn,
      registerOrdering,
    } = useReportTable();
    const [hoverAnchor, setHoverAnchor] = useState<"top" | "bottom">("top");
    const [tableRef, setLocalRef] = useState<HTMLDivElement | null>(null);

    const setRef = useCallback(
      (node: HTMLDivElement) => {
        setLocalRef(node);
        forwardedRef instanceof Function && forwardedRef?.(node);
      },
      [setLocalRef, forwardedRef],
    );

    const handleMouseMove: React.MouseEventHandler<HTMLDivElement> =
      useCallback(
        (event) => {
          if (!tableRef) {
            return;
          }

          const rect = tableRef.getBoundingClientRect();
          const relativePosition = (event.clientY - rect.top) / rect.height;

          if (relativePosition < 0.4) {
            setHoverAnchor("top");
          }

          if (relativePosition > 0.6) {
            setHoverAnchor("bottom");
          }
        },
        [setHoverAnchor, tableRef],
      );

    const {
      totalColumns,
      shownColumns,
      pivotedHeader,
      pivotedSubHeaderTexts,
      pivotedBody,
      pivotedFooter,
    } = useReportTablePivot({ pivoted, subHeaderTexts, header, body, footer });

    const minRowsToShow = Math.min(
      3,
      pivotedBody.left.length,
      pivotedBody.right.length,
    );
    const minimumBodyHeight = minRowsToShow * (BODY_CELL_HEIGHT + gap / 2); // Show at least three rows

    const {
      leftPartSizeDetector,
      rightPartSizeDetector,
      headerSizeDetector,
      footerSizeDetector,
      leftPartRightMargin,
      rightPartRightMargin,
      maximumRowPerPage,
    } = useReportTableSizeManager({
      heightManagementType,
      heightValue,
      pivoted,
    });

    useEffect(() => {
      registerChangeOrderingCallback(
        (columnKey: string, direction: "ASC" | "DESC" | undefined) => {
          if (!direction) {
            onChangeOrdering &&
              onChangeOrdering({
                columnKey,
                direction: ordering.direction === "ASC" ? "DESC" : "ASC",
              });
            return;
          }
          onChangeOrdering &&
            onChangeOrdering({
              columnKey,
              direction,
            });
        },
      );
      onDeleteColumn && registerDeleteColumn(onDeleteColumn);
      registerOrdering(ordering);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      onChangeOrdering,
      onDeleteColumn,
      ordering.columnKey,
      ordering.direction,
    ]);

    const isShowingMessage =
      !!pivoted && !loading && (totalColumns || 0) > MAX_PIVOTED_COLUMNS;
    const isShowingFooter = !pivoted;
    const headerRows =
      Math.max(pivotedHeader.left.length, pivotedHeader.right.length) +
      (subHeaderTexts ? 1 : 0);

    return (
      <ScrollSync proportional={true}>
        <StyledReportTable
          {...interactionEvents}
          ref={setRef}
          className={classNames(className)}
          style={style}
          isShowingMessage={isShowingMessage}
          isShowingFooter={isShowingFooter}
          headerRows={headerRows}
          onMouseMove={handleMouseMove}
        >
          {isShowingMessage ? (
            <Banner
              variant="notification"
              actionsChildren={EMPTY_ARRAY}
              title={_`Not all columns are being shown`}
              subtitle={
                _`Tables with switched rows and columns support up to ${[
                  "max",
                  String(MAX_PIVOTED_COLUMNS),
                ]} columns.` +
                _`Displaying ${["shown", String(shownColumns)]} of your ${[
                  "total",
                  String(totalColumns),
                ]} available columns.`
              }
              style={{
                padding: 0,
                paddingBottom: 4,
                position: "static",
                background: "none",
                boxShadow: "none",
                height: "max-content",
                gridColumn: "1 / -1",
                gridRow: 2,
              }}
            />
          ) : null}
          <StyledReportSide isShowingMessage={isShowingMessage}>
            <StyledReportHeader
              ref={headerSizeDetector.ref}
              headerRows={headerRows}
            >
              <ReportTableHeaderLines
                checkbox={checkbox}
                checkboxHeaderIcon={checkboxHeaderIcon}
                left={true}
                lines={pivotedHeader.left}
                rightMargin={leftPartRightMargin}
                readOnly={readOnly}
                minimalColumnHeader={minimalColumnHeader}
                onReorderHeaderColumns={(values, from, to) =>
                  onReorderHeaderColumns &&
                  onReorderHeaderColumns(true, values, from, to)
                }
              />
              {pivotedSubHeaderTexts && (
                <ReportTableSubHeaderLines
                  checkbox={checkbox}
                  lines={pivotedHeader.left}
                  rightMargin={leftPartRightMargin}
                  subHeaderTexts={pivotedSubHeaderTexts.left}
                />
              )}
            </StyledReportHeader>

            <ScrollSyncPane group={["verticalBody"]}>
              <StyledReportBody
                headerRows={headerRows}
                isShowingMessage={isShowingMessage}
                style={
                  maxBodyHeight
                    ? {
                        minHeight: minimumBodyHeight,
                        maxHeight: maxBodyHeight,
                        overflowY: "scroll",
                      }
                    : { minHeight: minimumBodyHeight }
                }
              >
                <ReportTableBodyLines
                  maxCheckedRows={maxCheckedRows}
                  checkbox={checkbox}
                  checkedRows={checkedRows}
                  onRowCheckboxChange={onRowCheckboxChange}
                  ref={leftPartSizeDetector.ref}
                  loading={loading}
                  lines={pivotedBody.left}
                  headers={pivotedHeader.left}
                  leftPart={true}
                  page={page}
                  pageLength={maximumRowPerPage}
                  hoverAnchor={hoverAnchor}
                  readOnly={readOnly}
                  cellSize={cellSize}
                  gap={gap}
                />
              </StyledReportBody>
            </ScrollSyncPane>

            {isShowingFooter && (
              <StyledReportFooter
                headerRows={headerRows}
                isShowingMessage={isShowingMessage}
              >
                {footer.length > 0 &&
                  pivotedHeader.left.length > 0 &&
                  pivotedHeader.left[pivotedHeader.left.length - 1].length >
                    0 && (
                    <StyledReportFooterLeftTotalCell>
                      <span>
                        Total{" "}
                        {rowsCount !== undefined && (
                          <span>
                            {rowsCount} result{rowsCount || 0 > 1 ? "s" : ""}
                          </span>
                        )}
                      </span>
                    </StyledReportFooterLeftTotalCell>
                  )}
              </StyledReportFooter>
            )}
          </StyledReportSide>

          <StyledReportSide isShowingMessage={isShowingMessage}>
            <StyledReportHeader headerRows={headerRows}>
              <ReportTableHeaderLines
                left={false}
                lines={pivotedHeader.right}
                rightMargin={rightPartRightMargin}
                readOnly={readOnly}
                onReorderHeaderColumns={(values, from, to) =>
                  onReorderHeaderColumns &&
                  onReorderHeaderColumns(false, values, from, to)
                }
                minimalColumnHeader={minimalColumnHeader}
              />
              {pivotedSubHeaderTexts && (
                <ReportTableSubHeaderLines
                  lines={pivotedHeader.right}
                  rightMargin={rightPartRightMargin}
                  subHeaderTexts={pivotedSubHeaderTexts.right}
                />
              )}
            </StyledReportHeader>

            <ScrollSyncPane group={["verticalBody"]}>
              <StyledReportBody
                headerRows={headerRows}
                isShowingMessage={isShowingMessage}
                style={
                  maxBodyHeight
                    ? {
                        maxHeight: maxBodyHeight,
                        overflowY: "scroll",
                        minHeight: minimumBodyHeight,
                      }
                    : {
                        minHeight: minimumBodyHeight,
                      }
                }
              >
                <ReportTableBodyLines
                  ref={rightPartSizeDetector.ref}
                  loading={loading}
                  loadingSecondary={loadingSecondary}
                  lines={pivotedBody.right}
                  headers={pivotedHeader.right}
                  page={page}
                  pageLength={maximumRowPerPage}
                  hoverAnchor={hoverAnchor}
                  readOnly={readOnly}
                  footerColumns={pivotedFooter}
                  cellSize={cellSize}
                  gap={gap}
                />
              </StyledReportBody>
            </ScrollSyncPane>

            {isShowingFooter && (
              <StyledReportFooter
                headerRows={headerRows}
                isShowingMessage={isShowingMessage}
                ref={footerSizeDetector.ref}
              >
                <ReportTableFooterColumns
                  loading={loadingTotal}
                  loadingSecondary={loadingSecondaryTotal}
                  columns={pivotedFooter}
                  headers={pivotedHeader.right}
                  rightMargin={rightPartRightMargin}
                />
              </StyledReportFooter>
            )}
          </StyledReportSide>
        </StyledReportTable>
      </ScrollSync>
    );
  },
);
