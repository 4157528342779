import React from "react";
import "react-notifications/lib/notifications.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import FullPageLoader from "./components/Loader/FullPageLoader";
import SomethingWrong from "./pages/custom/components/placeholders/SomethingWrong";
import PasswordChange from "./pages/login/PasswordChange";
import ShopifyConfirmation from "./pages/login/ShopifyConfirmation";
import OnboardingForm from "./pages/onboardingForm/OnboardingForm";
import Referrals from "./pages/referrals/Referrals";
import { TenantListPopup } from "./shared/Popups/TenantListPopup";
import { ConnectorRoutes } from "./utils/routingUtils/ConnectorRoutes";
import { LoggedInProviders } from "./utils/routingUtils/LoggedInProviders";
import { LoggedInRoutes } from "./utils/routingUtils/LoggedInRoutes";
import { NoMatch } from "./utils/routingUtils/NoMatch";
import { AuthGuard, OutboundFormGuard } from "./utils/routingUtils/RouteGuards";
import SharedProviders from "./utils/routingUtils/SharedProviders";

const Data = React.lazy(() => import("./pages/settings/Data"));
const Settings = React.lazy(() => import("./pages/settings/Settings"));
const Account = React.lazy(() => import("./pages/settings/Account"));
const RootRouteSwitch = React.lazy(
  () => import("./utils/routingUtils/RootRouteSwitch"),
);
const Login = React.lazy(() => import("./pages/login/Login"));
const SignUp = React.lazy(() => import("./pages/login/SignUp/SignUp"));
const InvitationInvalid = React.lazy(
  () => import("./pages/invitations/InvitationInvalid"),
);
const EmailConfirmation = React.lazy(
  () => import("./pages/login/EmailConfirmation"),
);
const PasswordForgotten = React.lazy(
  () => import("./pages/login/PasswordForgotten"),
);
const PasswordResetInProgress = React.lazy(
  () => import("./pages/login/PasswordResetInProgress"),
);
const AcceptInvitation = React.lazy(
  () => import("./pages/invitations/AcceptInvitation"),
);
const InvitationGate = React.lazy(
  () => import("./pages/invitations/InvitationGate"),
);
const PasswordReset = React.lazy(() => import("./pages/login/PasswordReset"));
const SSO = React.lazy(() => import("./pages/login/SSO"));
const ClearLS = React.lazy(() => import("./pages/ClearLS"));
const Demo = React.lazy(() => import("./pages/Demo"));
const Shopify = React.lazy(() => import("./pages/shopify/Shopify"));
const EmailConfirmed = React.lazy(() => import("./pages/login/EmailConfirmed"));
const AdInsight = React.lazy(() => import("./pages/growth/AdInsight"));

export default createBrowserRouter(
  createRoutesFromElements(
    <Route element={<SharedProviders />} errorElement={<SomethingWrong />}>
      {/* Route switch */}
      <Route path="/" element={<RootRouteSwitch />} />

      {/* Logged in routes */}
      <Route
        element={
          <AuthGuard allowWhen={(auth) => auth.isLoggedIn}>
            <LoggedInProviders />
          </AuthGuard>
        }
      >
        <Route path="/shopifyConfirmation" element={<ShopifyConfirmation />} />

        <Route
          element={
            <AuthGuard
              allowWhen={(auth) => !auth.user || auth.user.emailValidated}
            />
          }
        >
          <Route path="/passwordChange" element={<PasswordChange />} />
          <Route path="/referrals" element={<Referrals />} />
          <Route
            path="/workspaces"
            element={<TenantListPopup onClose={() => {}} />}
          />

          <Route element={<OutboundFormGuard whenCompleted={true} />}>
            {ConnectorRoutes}

            <Route
              element={
                <AuthGuard
                  allowWhen={(auth) =>
                    !auth.isAgencyConnector() || auth.isOutboundDemo
                  }
                />
              }
            >
              {LoggedInRoutes}
            </Route>

            <Route path="/settings">
              <Route
                element={
                  <AuthGuard
                    allowWhen={(auth) =>
                      !auth.isAgencyConnector() || auth.isOutboundDemo
                    }
                    fallbackTo="/settings/account"
                  />
                }
              >
                <Route path="" element={<Settings />} />
              </Route>

              <Route
                element={
                  <AuthGuard
                    allowWhen={(auth) => !auth.outboundDemoData}
                    fallbackTo="/settings"
                  />
                }
              >
                <Route path="account" element={<Account />} />
                <Route
                  element={
                    <AuthGuard
                      allowWhen={(auth) => !auth.isAgencyConnector()}
                      fallbackTo="/settings"
                    />
                  }
                >
                  <Route path="data" element={<Data />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<OutboundFormGuard whenCompleted={false} />}>
          <Route path="/onboarding-form" element={<OnboardingForm />} />
        </Route>
      </Route>

      {/* Logged out routes */}
      <Route element={<AuthGuard allowWhen={(auth) => !auth.isLoggedIn} />}>
        <Route path="/login" element={<Login />} />
        <Route path="/passwordForgotten" element={<PasswordForgotten />} />
        <Route
          path="/passwordResetInProgress"
          element={<PasswordResetInProgress />}
        />
        <Route path="/demo" element={<Demo />} />
        <Route path="/invitationInvalid" element={<InvitationInvalid />} />
        <Route path="/login/*" element={<FullPageLoader />} />
      </Route>

      {/* Shared routes */}
      <Route path="/shopify/:uuid" element={<Shopify />} />
      <Route path="/adInsight" element={<AdInsight />} />
      <Route path="/meta-ads-library" element={<AdInsight />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/invitations/gate/:token" element={<InvitationGate />} />
      <Route path="/clearLS" element={<ClearLS />} />
      <Route path="/emailConfirmation" element={<EmailConfirmation />} />
      <Route path="/passwordReset" element={<PasswordReset />} />
      <Route path="/emailConfirmed" element={<EmailConfirmed />} />
      <Route path="/sso" element={<SSO />} />
      <Route
        path="/invitations/accept/:token"
        element={/* Has force log out */ <AcceptInvitation />}
      />

      {/* Fallback */}
      <Route path="*" element={<NoMatch />} />
    </Route>,
  ),
);
