import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import PageLoader from "../../pages/PageLoader";

type AuthGuardProps = {
  allowWhen: (auth: ReturnType<typeof useAuth>) => boolean;
  fallbackTo?: string;
  children?: React.ReactElement;
};

export const AuthGuard = ({
  allowWhen,
  fallbackTo = "/",
  children,
}: AuthGuardProps) => {
  const auth = useAuth();

  if (auth.processing) {
    return <PageLoader />;
  }

  if (!allowWhen(auth)) {
    return <Navigate to={fallbackTo} replace />;
  }

  return (
    <React.Fragment key={auth.user?.tenantId}>
      {children ? children : <Outlet />}
    </React.Fragment>
  );
};

type OutboundFormGuardProps = {
  whenCompleted: boolean;
};

export const shouldSeeOutboundForm = ({
  auth,
  bootstrap,
}: {
  auth: ReturnType<typeof useAuth>;
  bootstrap: ReturnType<typeof useBootstrap>;
}) => {
  return (
    !auth.processing &&
    !bootstrap.setupState.isFinished &&
    bootstrap.tenant.settings?.onboarding_form?.isCompleted !== true &&
    !auth.isVisitor() &&
    auth.isAdmin() && //only admins can update tenant settings
    !auth.isGod()
  );
};

export const OutboundFormGuard = ({
  whenCompleted,
}: OutboundFormGuardProps) => {
  const auth = useAuth();
  const bootstrap = useBootstrap();

  if (auth.processing) {
    return <PageLoader />;
  }
  if (whenCompleted === !shouldSeeOutboundForm({ auth, bootstrap })) {
    return <Outlet />;
  }

  return <Navigate to="/" replace />;
};
