import {
  NotificationMessage,
  Space,
  Text,
  theme,
} from "../../../../icecube-ux";
import { _, _node } from "../../../../languages/helper";

const { primary110, text110, primary100 } = theme.colors;

export const KlaviyoConnectMessage = () => (
  <>
    <NotificationMessage
      variant="long"
      style={{ alignItems: "start", padding: "12px" }}
      textContent={
        <>
          <Text variant="body12Regular" color={text110}>
            <Text variant="body12SemiBold" color={primary110}>
              {_`Important`}
            </Text>
            <Text variant="body12Regular" color={text110} inline>
              {_`If you have an error while connecting your Klaviyo account to the app, follow these steps.`}{" "}
              {_node`Read the detailed article ${[
                "link",
                <a
                  href="https://intercom.help/polar-app/en/articles/5635048-how-can-i-connect-klaviyo-to-my-account"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Intercom help"
                >
                  <Text
                    variant="body12Regular"
                    color={primary100}
                    inline
                    underline
                  >
                    {_`here|||link`}
                  </Text>
                </a>,
              ]}.`}{" "}
            </Text>
          </Text>
          <Text variant="body12Regular" color={text110}>
            <Text
              style={{ marginTop: "12px" }}
              variant="body12SemiBold"
              color={primary110}
            >
              {" "}
              {_`Step ${["number", "1"]}`}{" "}
            </Text>
            <span>
              {_node`In ${[
                "link",
                <a
                  href="https://www.klaviyo.com/settings/account/api-keys"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="User permissions"
                >
                  <Text
                    variant="body12Regular"
                    color={primary100}
                    inline
                    underline
                  >
                    {_`Settings>API Keys|||link`}
                  </Text>
                </a>,
              ]}, create a Private API Key, name it “Polar” and grant it ${[
                "viewAndEdit",
                <span className="italic">Read only</span>,
              ]} to all scopes.`}{" "}
            </span>
          </Text>

          <Text variant="body12Regular" color={text110}>
            <Text
              style={{ marginTop: "12px" }}
              color={primary110}
              variant="body12SemiBold"
            >
              {" "}
              {_`Step ${["number", "2"]}`}{" "}
            </Text>
            <span>
              {_node`Navigate back to your Polar Connectors, fill in the ${[
                "siteid",
                <span className="italic">site id</span>,
              ]} with your account’s ${[
                "PublicAPIKey",
                <span className="italic">Public API Key</span>,
              ]}, and select the associated Shopify store from the drop-down list.`}{" "}
            </span>
          </Text>
        </>
      }
    />
    <Space />
  </>
);
