import { QueryClient } from "@tanstack/react-query";

export const refetchQueriesByQueryKey = async (
  queryClient: QueryClient,
  queryKey: (string | undefined)[],
) => {
  await queryClient.invalidateQueries({
    queryKey,
  });
};
