import { FunctionComponent, useState } from "react";

import { useDimensionProvider } from "../../hooks/dimensions";
import useDebouncedEffect from "../../hooks/useDebounceEffect";
import { Input, Select } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  DimensionTypes,
  getOperatorListOptionsForType,
  INTEGER_RULE_OPERATORS,
  Rule,
  RuleOperators,
  STRING_RULE_OPERATORS,
} from "../../utils/filterUtils";
import { filterIsNotNullOrUndefined } from "../../utils/utils";

import { getBooleanValues } from "./filterUtils";

interface RuleProps {
  rule: Rule;
  onRuleChange: (rule: Rule) => void;
  isDisabled?: boolean;
}

const GenericRule: FunctionComponent<RuleProps> = ({
  rule,
  onRuleChange,
  isDisabled = false,
}: RuleProps) => {
  const dimensionFilter = rule.dimensionFilter;
  const type = dimensionFilter?.type ?? DimensionTypes.string;
  const dimensionKey = dimensionFilter?.name ?? "";
  const dimensionTables = dimensionFilter?.dependencies ?? [];

  const dimensionProvider = useDimensionProvider();
  const [searchText, setSearchText] = useState("");

  useDebouncedEffect(
    () => {
      dimensionProvider.load(dimensionTables, dimensionKey, searchText);
    },
    300,
    [searchText],
  );

  const allRulesOperators = [
    ...INTEGER_RULE_OPERATORS,
    ...STRING_RULE_OPERATORS,
  ];
  const customOperatorOptions =
    dimensionFilter?.operators
      ?.map((operatorKey) =>
        allRulesOperators.find((operator) => operator.value === operatorKey),
      )
      .filter(filterIsNotNullOrUndefined) ?? undefined;
  const operatorOptions =
    customOperatorOptions ?? getOperatorListOptionsForType(type);
  const dimensionOptionsRaw = [
    ...dimensionProvider
      .get(dimensionTables, dimensionKey, searchText)
      .map((d) => ({ label: d, value: d })),
    ...(rule.value as string[]).map((d) => ({ label: d, value: d })), // As we limit values to 500, we need to make sure selected values are in the list of dimension options
  ];
  const dimensionOptions = [
    ...new Map(
      dimensionOptionsRaw.map((item) => [item.value, { ...item, isDisabled }]),
    ).values(),
  ];

  return (
    <>
      {(type === DimensionTypes.string || type === DimensionTypes.integer) && (
        <>
          <Select
            forcedDisplayedValue={
              operatorOptions.find((r) => r.value === rule.operator)?.label ??
              "..."
            }
            disabled={isDisabled}
            options={operatorOptions}
            selected={[rule.operator]}
            onChange={(v) =>
              onRuleChange({
                dimensionFilter: dimensionFilter,
                operator: v[0] as RuleOperators,
                value: [],
              })
            }
            block
          />
          {([RuleOperators.is, RuleOperators.isnot] as string[]).includes(
            rule.operator,
          ) && (
            <>
              <Select
                forcedDisplayedValue={
                  rule.value
                    ? `${rule.value.length} ` + _`selected`
                    : _`Select a value...`
                }
                options={dimensionOptions}
                selected={rule.value as string[]}
                onChange={(v) =>
                  onRuleChange({
                    dimensionFilter: dimensionFilter,
                    operator: rule.operator,
                    value: v as string[],
                  })
                }
                loading={dimensionProvider.isLoading(
                  dimensionTables,
                  dimensionKey,
                  searchText,
                )}
                onOpen={() => {
                  dimensionProvider.load(
                    dimensionTables,
                    dimensionKey,
                    searchText,
                  );
                }}
                onSearchChange={(v) => setSearchText(v)}
                initialSearch={searchText}
                selectedOptionsOnTop
                withSearch
                withCheckboxes
                multiple
                block
                forceArrow
              />
            </>
          )}
          {!([RuleOperators.is, RuleOperators.isnot] as string[]).includes(
            rule.operator,
          ) && (
            <Input
              type={type === DimensionTypes.string ? "text" : "number"}
              value={(rule.value?.[0] as string) || ""}
              onChange={(v) =>
                onRuleChange({
                  dimensionFilter: dimensionFilter,
                  operator: rule.operator,
                  value: [v as string],
                })
              }
              placeholder={_`Write a value...`}
              block
            />
          )}
        </>
      )}
      {type === DimensionTypes.boolean && (
        <Select
          placeholder={`${rule.value.length} ` + _`selected`}
          options={getBooleanValues()}
          selected={rule.value as string[]}
          onChange={(v) =>
            onRuleChange({
              dimensionFilter: dimensionFilter,
              operator: rule.operator,
              value: v as string[],
            })
          }
          withCheckboxes
          multiple
          block
        />
      )}
    </>
  );
};

export default GenericRule;
