import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { redirect } from "react-router-dom";

import {
  FormResponse,
  OnboardingFormData,
  TenantStates,
} from "../../common/types/users-service";
import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { Loader } from "../../icecube-ux";
import { setTenantSetting } from "../../lib/usersService";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

import OnboardingFormCallSlide from "./OnboardingFormCallSlide/OnboardingFormCallSlide";
import OnboardingFormQuestionSlide from "./OnboardingFormQuestionSlide/OnboardingFormQuestionSlide";
import {
  goalOptions,
  onboardingQuestionKeys,
  onboardingQuestions,
} from "./onboardingQuestions";

declare const dataLayer: Record<string, unknown>[];

const NUMBER_OF_QUESTIONS = onboardingQuestions.length;

const PreSetAnswer: { [x: string]: Record<string, Partial<FormResponse>> } = {
  // skip the goal and call booking question if the user is in activation mode
  activation: {
    [onboardingQuestionKeys.goals]: {
      selection: [goalOptions.activate.key],
    },
    [onboardingQuestionKeys.callBooking]: {
      skipped: true,
    },
  },
};
const getInitialResponses = (tenantStates: TenantStates) => {
  const mode = tenantStates.limitedNavigationMode ?? "";
  const presetAnswers = PreSetAnswer?.[mode];

  return onboardingQuestions.map((question) => {
    if (question.slideType === "callBooking") {
      return {
        questionKey: question.key,
        skipped: false,
        selection: [],
        ...presetAnswers?.[question.key],
      };
    } else {
      const guessedValue = question.tryGet?.();
      return {
        questionKey: question.key,
        selection: guessedValue ? [guessedValue] : [],
        skipped: false,
        isAutocompleted: Boolean(guessedValue),
        ...presetAnswers?.[question.key],
      };
    }
  });
};

function OnboardingForm() {
  const auth = useAuth();
  const { tenant, forceRefresh } = useBootstrap();

  const [onboardingForm, setOnboardingForm] = useState<OnboardingFormData>(
    tenant.settings.onboarding_form || {
      isCompleted: false,
      responses: getInitialResponses(tenant.states),
    },
  );
  const [isSettingOnboardingForm, setIsSettingOnboardingForm] = useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(
    onboardingForm.responses.findIndex((r) => r.selection.length === 0) || 0,
  );
  const currentQuestion = onboardingQuestions[currentQuestionIndex];

  const currentResponse = onboardingForm.responses[currentQuestionIndex];
  const lastStepSkipped =
    currentQuestionIndex === NUMBER_OF_QUESTIONS - 1 && currentResponse.skipped;

  const handleSubmit = async (
    latestResponses?: OnboardingFormData["responses"],
  ) => {
    const isCompleted = currentQuestionIndex === NUMBER_OF_QUESTIONS - 1;
    const onboarding_form = {
      responses: latestResponses || onboardingForm.responses,
      isCompleted,
    };
    if (!isCompleted) {
      trackEvent(TRACKING_EVENTS.ONBOARDING_FORM_NEXT_STEP, {
        ...onboarding_form,
        tenantId: tenant?.id,
        email: auth.user?.email,
      });
      setCurrentQuestionIndex((prev) => {
        const isNextAutoCompleted = Boolean(
          onboardingForm.responses[prev + 1].isAutocompleted,
        );
        return isNextAutoCompleted ? prev + 2 : prev + 1;
      });
    }
    setIsSettingOnboardingForm(true);
    await setTenantSetting(
      await auth.getToken(),
      "onboarding_form",
      onboarding_form,
    );
    setIsSettingOnboardingForm(false);
    if (isCompleted) {
      await forceRefresh();
      const gmv = onboarding_form?.responses?.find(
        (response) => response.questionKey === onboardingQuestionKeys.gmv,
      )?.selection[0];
      const region = onboarding_form?.responses?.find(
        (response) => response.questionKey === onboardingQuestionKeys.region,
      )?.selection[0];
      const goals = onboarding_form?.responses?.find(
        (response) => response.questionKey === onboardingQuestionKeys.goals,
      )?.selection[0];
      trackEvent(TRACKING_EVENTS.ONBOARDING_FORM_COMPLETED, {
        ...onboarding_form,
        tenantId: tenant?.id,
        email: auth.user?.email,
        gmv,
        region,
        goals,
      });
      redirect("/");
      try {
        dataLayer?.push({
          event: "onboarding_form_completed",
          gmv,
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (
      lastStepSkipped &&
      !onboardingForm.isCompleted &&
      !isSettingOnboardingForm
    ) {
      setOnboardingForm({
        responses: onboardingForm.responses,
        isCompleted: true,
      });
      void handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStepSkipped, onboardingForm.isCompleted, isSettingOnboardingForm]);

  if (lastStepSkipped) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Polar Analytics</title>
      </Helmet>
      {currentQuestion.slideType === "question" && (
        <OnboardingFormQuestionSlide
          onSubmit={handleSubmit}
          currentQuestion={currentQuestion}
          currentResponse={currentResponse}
          setOnboardingForm={setOnboardingForm}
          currentQuestionIndex={currentQuestionIndex}
          previousQuestion={
            currentQuestionIndex !== 0
              ? () => {
                  trackEvent(TRACKING_EVENTS.ONBOARDING_FORM_CLICK_PREVIOUS, {
                    tenantId: tenant?.id,
                    email: auth.user?.email,
                  });
                  setCurrentQuestionIndex((prev) => {
                    const isPrevAutoCompleted = Boolean(
                      onboardingForm.responses[prev - 1].isAutocompleted,
                    );
                    return isPrevAutoCompleted ? prev - 2 : prev - 1;
                  });
                }
              : undefined
          }
        />
      )}
      {currentQuestion.slideType === "callBooking" && (
        <OnboardingFormCallSlide
          onSubmit={handleSubmit}
          onboardingForm={onboardingForm}
        />
      )}
    </>
  );
}

export default OnboardingForm;
