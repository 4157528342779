import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { UserAttributes } from "../../common/types/users-service";
import { ErrorBlock } from "../../components/Error/ErrorBlock";
import FullPageLoader from "../../components/Loader/FullPageLoader";
import MobileContainer from "../../components/MobileContainer/MobileContainer";
import { useAuth } from "../../hooks/auth/auth";
import {
  PerformanceEvent,
  trackPageLoadCheckpoint,
} from "../../hooks/usePerformanceMeasurement";
import {
  Button,
  Flex,
  HeroFormLayout,
  Text,
  TextField,
} from "../../icecube-ux";
import { useDeviceType } from "../../icecube-ux/hooks/provideDeviceType";
import { _ } from "../../languages/helper";
import { checkEmail } from "../../lib/authService";
import { getUserAttributes } from "../../lib/usersService";
import Form from "../../shared/Forms/Form";
import EmptyLayout from "../../shared/Layouts/EmptyLayout";

import TrustedBy from "./components/TrustedBy";

trackPageLoadCheckpoint(PerformanceEvent.PAGE_LOADED);

function ShopifyConfirmation() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [attributes, setAttributes] = useState<UserAttributes | null>(null);
  const [loading, setLoading] = useState(false);
  const mayChangeEmail =
    !auth.isGod() && (auth.user?.availableTenants?.length || 0) < 2;

  const handleConfirmEmail = async () => {
    setError("");

    if (email === "") {
      setError("Please enter an email address");
      return;
    }

    setLoading(true);
    const userInUse = await checkEmail(await auth.getToken(), email);
    if (userInUse) {
      setError("This email is already in use");
      setLoading(false);
      return;
    }

    if (mayChangeEmail) {
      await auth.changeEmail(email);
    }
    await auth.refreshUser(auth.tokens);
    navigate("/");
    setLoading(false);
  };

  useEffect(() => {
    const getAttributes = async () => {
      if (!auth.processing && auth.tokens !== null) {
        const result = await getUserAttributes(await auth.getToken());
        setAttributes(result.error ? null : result.data);
      }
    };
    void getAttributes();
  }, [auth.processing, auth, auth.user, navigate]);

  if (attributes === null) {
    return <FullPageLoader />;
  }

  return (
    <EmptyLayout>
      <Helmet>
        <title>{_`Confirm your email`} | Polar Analytics</title>
      </Helmet>
      {!isMobile && (
        <div className="h100">
          <HeroFormLayout>
            <HeroFormLayout.Main padding="50px 60px 24px">
              <Form
                onSubmit={() => {
                  void handleConfirmEmail();
                }}
              >
                <Flex
                  flexDirection="column"
                  fullWidth
                  alignItems="stretch"
                  gap={30}
                >
                  <div>
                    <HeroFormLayout.Main.Title
                      textAlign="left"
                      variant="body24Regular"
                    >
                      {_`Welcome`} {attributes.storeName}!
                    </HeroFormLayout.Main.Title>

                    <Text variant="body12Regular">
                      {_`How should we contact you?`}
                    </Text>
                  </div>

                  <Flex gap={24} flexDirection="column">
                    <Flex gap={8} flexDirection="column">
                      <Text fontWeight={500} fontSize="13px" color="#4B4B7E">
                        {_`Your email`}
                      </Text>
                      <TextField
                        type="email"
                        placeholder="you@yourstore.com"
                        value={email}
                        onChange={(v) => setEmail(v as string)}
                      />

                      {error !== "" && (
                        <ErrorBlock
                          className="mbx-18"
                          line1={"Error"}
                          line2={error}
                          mobile
                        />
                      )}
                    </Flex>
                    <div>
                      <Button
                        type="submit"
                        size="xxlarge"
                        loading={loading}
                        block
                      >
                        {_`Verify email`}
                      </Button>
                    </div>
                  </Flex>
                </Flex>
              </Form>
            </HeroFormLayout.Main>

            <HeroFormLayout.Footer>
              <TrustedBy />
            </HeroFormLayout.Footer>
          </HeroFormLayout>
        </div>
      )}
      <MobileContainer title={_`Welcome` + ` ${attributes.storeName}!`}>
        <>
          <Form
            onSubmit={() => {
              void handleConfirmEmail();
            }}
          >
            <Flex gap={18} flexDirection="column">
              <Flex gap={8} flexDirection="column">
                <Text fontWeight={500} fontSize="13px" color="#4B4B7E">
                  {_`Your email`}
                </Text>
                <TextField
                  type="email"
                  placeholder="you@yourstore.com"
                  value={email}
                  onChange={(v) => setEmail(v as string)}
                />

                {error !== "" && (
                  <ErrorBlock
                    className="mbx-18"
                    line1={"Error"}
                    line2={error}
                    mobile
                  />
                )}
              </Flex>
              <Button
                className="mtx-18"
                type="submit"
                loading={loading}
                block={true}
                ctaSize
              >
                {_`Save email`}
              </Button>
            </Flex>
          </Form>
        </>
      </MobileContainer>
    </EmptyLayout>
  );
}

export default ShopifyConfirmation;
