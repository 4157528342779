import type {
  OnboardingFormData,
  BootstrapData,
  UserAttributes,
  AvailableTenant,
} from "../common/types/users-service";
import type {
  DemoPersonalization,
  OutboundDemoData,
} from "../hooks/useOutboundDemoTypes";
import { randomString } from "../utils/utils";

import { createClient } from "./apiClient";
import { getServiceHost } from "./services";

export interface Invites {
  id: string;
  role: string;
  email: string;
  createdAt: Date;
  usedAt: Date | null;
}

export interface Users {
  role: string;
  email: string;
  createdAt: Date;
  id: string;
}

export interface InvitationDetails {
  data: {
    invitation: {
      id: string;
    };
    inviter: {
      name: string;
      id: string;
    };
    tenant: {
      id: string;
      name: string;
    };
    invitee: {
      id?: string;
      email: string;
      isExistingUser: boolean;
      role: string;
    };
  };
}

const client = createClient("users-service");

export const isInMaintenanceMode = async (): Promise<boolean> => {
  try {
    const json = await client
      .new()
      .get("/public/maintenance")
      .fetch<{ data: boolean }>();
    return !json.error && json.data === true;
  } catch (_) {
    return false;
  }
};

export const bootstrap = async (token: string) => {
  try {
    const json = await client
      .new()
      .get("/api/bootstrap")
      .auth(token)
      .trackDuration()
      .fetch<{
        data: BootstrapData;
      }>();
    if (json.error === true) {
      throw new Error("Bootstrap failed");
    }
    return json.data;
  } catch (_) {
    throw new Error("Bootstrap failed");
  }
};

export const getDemoPersonalizationData = async (data: {
  domain?: string;
  linkedinName?: string;
}): Promise<false | DemoPersonalization | null> => {
  if (!data.domain && !data.linkedinName) {
    return false;
  }
  const queries = new URLSearchParams(data);
  const json = await client
    .new()
    .get(`/public/demo?${queries.toString()}`)
    .fetch<{ data: DemoPersonalization | null }>();
  return json.error === false ? json.data : false;
};

export const logAsTenant = async ({
  token,
  tenantId,
}: {
  token: string;
  tenantId: string;
}) => {
  return await client
    .new()
    .auth(token)
    .post(`/api/user/tenant/${tenantId}/log-as`)
    .fetch();
};

export const postOutboundReportRequest = async ({
  message,
  company,
  token,
}: {
  message: string;
  company?: OutboundDemoData;
  token: string;
}) => {
  const json = await client
    .new()
    .post("/public/demo-report-request")
    .auth(token)
    .body({ message, company })
    .fetch();
  return json.error === false;
};

export const uploadAvatar = async (
  token: string,
  filename: string,
  avatar: string,
) => {
  const json = await client
    .new()
    .post("/api/avatar")
    .auth(token)
    .body({ filename, avatar })
    .fetch<{ result: string }>();
  return json.error === false ? json.result : "";
};

export const renameTenant = async (token: string, companyName: string) => {
  const json = await client
    .new()
    .patch("/api/tenant")
    .auth(token)
    .body({ companyName })
    .fetch();
  return json.error === false;
};

export const updateTenantState = async (
  token: string,
  state: string,
  value: string | string[] | boolean,
) => {
  const json = await client
    .new()
    .post(`/api/tenant/states/${state}`)
    .auth(token)
    .body({ value })
    .fetch();
  return json.error === false;
};

export const updateTenantDashboardState = async (
  token: string,
  connectorKey: string,
) => {
  const json = await client
    .new()
    .post(`/api/tenant/states/dashboard/connect/${connectorKey}`)
    .auth(token)
    .body({})
    .fetch();
  return json.error === false;
};

export const setTenantSetting = async (
  token: string,
  key: string,
  value:
    | string
    | number
    | boolean
    | string[]
    | OnboardingFormData
    | { [key: string]: string },
) => {
  const json = await client
    .new()
    .post("/api/tenant/settings/" + encodeURIComponent(key))
    .auth(token)
    .body({ value })
    .fetch();
  return json.error === false;
};

export const createTenant = async (
  token: string,
  body: { timezone: string; companyName?: string; sourceTenantId?: string },
) => {
  return await client
    .new()
    .post("/api/tenant")
    .auth(token)
    .body(body)
    .fetch<{ data: { id: string } }>();
};

export const getTenantUsers = async (token: string) => {
  const json = await client
    .new()
    .get("/api/tenant/users")
    .auth(token)
    .fetch<{ result: Users[] }>();
  return json.error === false ? json.result : null;
};

export const getUserTenants = async (token: string) => {
  const json = await client
    .new()
    .get("/api/user/tenants")
    .auth(token)
    .fetch<{ data: AvailableTenant[] }>();
  return json.error === false ? json.data : null;
};

export const deleteTenantUser = async (token: string, id: string) => {
  const json = await client
    .new()
    .delete("/api/tenant/user/" + id)
    .auth(token)
    .fetch();
  return json.error !== false;
};

export const inviteUser = async (
  token: string,
  email: string,
  role: string,
) => {
  const json = await client
    .new()
    .post("/api/invite")
    .auth(token)
    .body({ email, role })
    .fetch();
  return json;
};

export const getInvitations = async (token: string) => {
  const json = await client
    .new()
    .get("/api/invites")
    .auth(token)
    .fetch<{ result: Invites[] }>();
  return json.error === false ? json.result : null;
};

export const cancelInvitation = async (token: string, id: string) => {
  const json = await client
    .new()
    .delete("/api/invite/" + id)
    .auth(token)
    .fetch();
  return json.error !== false;
};

export const updateInvitationRole = async (
  token: string,
  id: string,
  role: string,
) => {
  const json = await client
    .new()
    .post("/api/invite/role/" + id)
    .auth(token)
    .body({ role })
    .fetch();
  return json.error === false;
};

export const updateUserRole = async (
  token: string,
  user_id: string,
  role: string,
) => {
  const response = await fetch(
    getServiceHost("users-service") + "/api/tenant/role/" + user_id,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
      body: JSON.stringify({ role }),
    },
  );
  const json = (await response.json()) as { error: boolean }; //To fix, request can fail
  return json.error === false;
};

export const acceptInvitation = async (token: string) => {
  const response = await client
    .new()
    .get("/accept/" + token)
    .fetch<{
      data: { password: string; login: string; swapTenant: boolean };
    }>();

  return response.error ? null : response.data;
};

export const verifyInvitationToken = async (token: string) => {
  return await client
    .new()
    .get("/invite/verify/" + token)
    .fetch<InvitationDetails>();
};
export const checkEmailValidity = async (email: string) => {
  const response = await fetch(
    getServiceHost("users-service") +
      `/public/email/check?email=${encodeURIComponent(email)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
      },
    },
  );
  const json = (await response.json()) as {
    result: { free: boolean; disposable: boolean; undeliverable: boolean }; //To fix, request can fail
  };
  return json.result;
};

export const setUserSetting = async (
  token: string,
  key: string,
  value: string | number | string[],
) => {
  const json = await client
    .new()
    .post("/api/user/settings/" + encodeURIComponent(key))
    .auth(token)
    .body({ value })
    .fetch();
  return json.error === false;
};

export const getUserAttributes = async (token: string) => {
  const json = await client
    .new()
    .get("/api/user/attributes")
    .auth(token)
    .fetch<{ data: UserAttributes | null }>();
  return json;
};

export const onboardNewUser = async (
  token: string,
  timezone: string,
): Promise<UserAttributes | null> => {
  const json = await client
    .new()
    .post("/api/user/onboard")
    .auth(token)
    .body({ timezone })
    .fetch<{ data: UserAttributes | null }>();
  return json.error ? null : json.data;
};

export const generateReferralCode = async (token: string) => {
  const json = await client
    .new()
    .post(`/api/referral/generate`)
    .auth(token)
    .fetch();
  return json.error ? null : json.data;
};

export const getUserReferralCode = async (token: string) => {
  const json = await client.new().get(`/api/referral/code`).auth(token).fetch();
  return json.error ? null : json.data;
};
