import * as d3 from "d3";
import { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";

import { WireframeLoader } from "../../../components/Loader/Loader";
import { useAnnotationPopup } from "../../../hooks/useAnnotationPopup";
import { _ } from "../../../languages/helper";
import { Button } from "../../Button";
import { theme } from "../../theme";
import { Text } from "../../Typography";

import { AddAnnotation } from "./AddAnnotation";
import { Annotation } from "./Annotation";
import { AnnotationEntry, AnnotationEvent } from "./AnnotationEntry";
import { usePosition } from "./PositionProvider";
import Tooltip from "./Tooltip";

export interface AnnotationsData {
  [key: string]: {
    events: AnnotationEvent[];
    totalCount: number;
    index: number;
    date: string;
  };
}

const MARKER_SIZE = 12;

interface AnnotationsProps {
  margin: [number, number, number, number];
  totalNumberOfLines: number;
  xLineScale: d3.ScaleLinear<number, number>;
  height: number;
  barWidth: number;
  data?: Record<string, unknown>[];
  annotations?: AnnotationsData;
  chartDimensions: DOMRect | undefined;
  isChartHovered: boolean;
  onClickAnnotation?: (date?: string) => void;
}

export const Annotations = ({
  margin,
  totalNumberOfLines,
  xLineScale,
  height,
  barWidth,
  annotations,
  chartDimensions,
  isChartHovered,
  onClickAnnotation,
}: AnnotationsProps) => {
  const { index, setFrozen } = usePosition();
  const [showTooltip, setShowTooltip] = useState(false);
  const { setOpenInfo, manualAnnotationsFetching } = useAnnotationPopup();
  const selectedAnnotation = useMemo(
    () =>
      Object.values(annotations || {}).find(
        ({ index: annotationIndex }) => annotationIndex === index,
      ),
    [index, annotations],
  );

  const yPosition = height - margin[0] - MARKER_SIZE;

  const dimensions = new DOMRect(
    chartDimensions?.x,
    (chartDimensions?.y ?? 0) + height - margin[0] - MARKER_SIZE,
    MARKER_SIZE,
    MARKER_SIZE,
  );

  const groupedEvents: Record<string, AnnotationEvent[]> | undefined =
    selectedAnnotation?.events
      ?.sort((a: AnnotationEvent, b: AnnotationEvent) => a.order - b.order)
      .reduce(
        (
          acc: Record<string, AnnotationEvent[]>,
          selectedAnnotation: AnnotationEvent,
        ) => {
          if (!acc[selectedAnnotation.eventType]) {
            acc[selectedAnnotation.eventType] = [];
          }
          acc[selectedAnnotation.eventType].push(selectedAnnotation);
          return acc;
        },
        {},
      );

  useEffect(() => {
    if (!isChartHovered || selectedAnnotation) {
      setShowTooltip(false);
    }
  }, [isChartHovered, selectedAnnotation]);

  useEffect(() => {
    if (!selectedAnnotation) {
      setFrozen(false);
    }
  }, [selectedAnnotation, setFrozen]);

  const emptyDays = useMemo(
    () => Object.values(annotations || {}).filter((a) => a.totalCount === 0),
    [annotations],
  );

  const selectedDate =
    Object.entries(annotations || {}).find(
      ([, ann]: [string, { index: number }]) => index === ann.index,
    )?.[0] || "";

  return (
    <>
      {Object.values(annotations || {})
        ?.filter(({ totalCount }) => totalCount !== 0)
        .map(({ index, totalCount }) => (
          <Annotation
            onClick={() => {
              onClickAnnotation?.(selectedAnnotation?.date);
              setShowTooltip(true);
            }}
            key={`annotation_${index}`}
            totalCount={totalCount}
            y={yPosition}
            x={xLineScale(index) + (barWidth * totalNumberOfLines) / 2}
          />
        ))}

      {emptyDays.map(({ index: annotationIndex }) =>
        index === annotationIndex ? (
          <AddAnnotation
            selectedDate={selectedDate}
            dimensions={dimensions}
            key={`add_annotation_${index}`}
            y={yPosition}
            x={xLineScale(index) + (barWidth * totalNumberOfLines) / 2}
          />
        ) : (
          <> </>
        ),
      )}

      {dimensions &&
        ReactDOM.createPortal(
          <Tooltip
            onMouseEnter={() => setFrozen(true)}
            onMouseLeave={() => setFrozen(false)}
            withoutContentScroll
            margin={[0, 0, 0, 0]}
            parentDimensions={dimensions}
            isVisible={
              isChartHovered &&
              showTooltip &&
              Object.keys(groupedEvents || {}).length > 0
            }
          >
            <>
              <div
                style={{
                  paddingBottom: "8px",
                  overflowY: "auto",
                  minHeight: "95px",
                }}
              >
                <Text
                  variant="body10Medium"
                  color={theme.colors.text110}
                  style={{ marginBottom: "8px" }}
                >
                  {selectedAnnotation?.date}
                </Text>

                {manualAnnotationsFetching ? (
                  <WireframeLoader width={120} height={20} />
                ) : (
                  Object.entries(groupedEvents || {}).map(([key, events]) => (
                    <div key={key}>
                      <Text
                        style={{ marginTop: "4px", marginBottom: "2px" }}
                        variant="body10Medium"
                        color={theme.colors.text80}
                      >
                        {events?.[0]?.title}
                      </Text>
                      {events.map((event, eventIndex) => (
                        <AnnotationEntry
                          eventType={event.eventType}
                          key={`annotation-entry-${eventIndex}`}
                          event={event}
                        />
                      ))}
                    </div>
                  ))
                )}
              </div>

              <Button
                style={{ width: "100%", marginTop: "8px" }}
                size="small"
                color="primary"
                leftIcon="PlusOperator"
                onClick={() =>
                  setOpenInfo({
                    mode: "add",
                    selectedDate,
                  })
                }
              >
                {_`Add annotation`}{" "}
              </Button>
            </>
          </Tooltip>,
          document.body,
        )}
    </>
  );
};
