import styled, { css } from "styled-components";

import { Icon } from "../Icon";
import { theme } from "../theme";

export const StyledCustomerJourneyTable = styled.div<{
  areAllJourneysSingleTouch: boolean;
}>`
  position: relative;
  display: grid;
  min-height: 0;
  grid-template-columns:
    minmax(
      ${({ areAllJourneysSingleTouch }) =>
        areAllJourneysSingleTouch ? "221px" : "350px"},
      auto
    )
    530px;
  gap: 8px;
  height: 100%;
  grid-template-rows: 44px 1fr 44px;
`;

const sharedCellStyles = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: ${theme.colors.bgLight30};
  border: 1px solid ${theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r2px};
  padding: 0 8px;
`;

export const StyledCustomerJourneyTableHeaderCell = styled.div`
  ${sharedCellStyles}
  justify-content: flex-start;
  padding: 0 2px;
  cursor: pointer;
`;

export const StyledCustomerJourneyTableHeaderCellArrow = styled(Icon)`
  margin-left: 4px;
  color: ${theme.colors.grey80};

  *:hover > & {
    color: ${theme.colors.grey90};
  }
`;

export const StyledCustomerJourneyTableJourneySection = styled.div<{
  isLoading: boolean;
}>`
  min-height: 0px;
  overflow-y: auto;
  width: min-content;
  position: relative;
  &&::-webkit-scrollbar {
    display: none;
  }
  ${({ isLoading }) => (isLoading ? "opacity: 50%;" : "")}
`;

export const StyledCustomerJourneyTableScrollArea = styled.div<{}>`
  grid-row: 2;
  grid-column: 3;
  overflow-y: auto;
  min-height: 0px;
`;

export const StyledCustomerJourneyTableBody = styled.div<{
  isLoading?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 44px;
  gap: 8px;

  ${(p) => (p.isLoading ? "opacity: 50%;" : "")},
  min-height: 0;
  overflow: auto;

  &&::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledCustomerJourneyTableJourneySectionScrollContainer = styled.div<{
  alignTouches: "left" | "right";
}>`
  overflow-y: auto;
  overflow-x: visible;
  display: flex;
          min-height: 0,
  flex-direction: ${({ alignTouches }) =>
    alignTouches === "left" ? "row" : "row-reverse"};

`;

export const StyledCustomerJourneyTableSide = styled.div`
  display: grid;
  min-width: 0;
  overflow-y: hidden;
  overflow-x: auto;
  gap: 8px;
  grid-template-rows: subgrid;
  grid-row: 1 / -1;
`;

export const StyledCustomerJourneyTableLeftHeaderCell = styled.div`
  ${sharedCellStyles}
  background: ${theme.colors.bgLight10};
  justify-content: space-between;

  background: ${theme.colors.bgLight10};
  grid-row: 1;

  & > button:first-child {
    position: sticky;
    left: 8px;
  }

  & > button:last-child {
    position: sticky;
    right: 8px;
  }
`;

export const StyledCustomerJourneyTableCell = styled.div`
  ${sharedCellStyles}
  background: ${theme.colors.bgLight10};
  & > div {
    flex-grow: 1;
  }
`;

export const StyledCustomerJourneyTableFooterCell = styled.div`
  ${sharedCellStyles}

  & > div {
    flex-grow: 1;
  }
`;

export const StyledCustomerJourneyTableLeftFooterCell = styled.div`
  grid-row: 3;
  ${sharedCellStyles}
  position: relative;
  justify-content: flex-start;
  width: 100%;

  & > div {
    position: sticky;
    left: 18px;
  }
`;

export const StyledCustomerJourneyTableScrollShadow = styled.div<{
  side: string;
}>`
  height: 100%;
  width: 22px;
  background: ${theme.customColors.shadowDivGradient};

  ${({ side }) =>
    side === "left"
      ? css`
          left: 0;
          transform: scaleX(-1);
        `
      : css`
          right: 0;
        `}
  top: 0;
  position: absolute;
  pointer-events: none;
`;
