import { createClient } from "../lib/apiClient";

const client = createClient("admin-service");

export const sendError = async (data: Record<string, unknown>) => {
  try {
    await client.new().post("/client/error").body(data).fetch();
  } catch (error) {
    console.error("Reporting error failed", error);
  }
};
