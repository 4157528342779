import { FunctionComponent, ReactElement } from "react";

import { Box, Flex, Grid, IconButton, Text } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { DimensionTypes, Rule, RuleWithFilter } from "../../utils/filterUtils";

import { DateRule } from "./DateRule";
import { DynamicRule } from "./DynamicRule";
import GenericRule from "./GenericRule";
import MetricRule from "./MetricRule";

interface RuleDetailProps {
  rule: Rule;
  index: number;
  isLastCreated?: boolean;
  isMetricRule?: boolean;
  onRuleChange: (rule: Rule) => void;
  onRuleDelete: () => void;
  isDisabled?: boolean;
  children?: ReactElement;
}

export const RuleDetail: FunctionComponent<RuleDetailProps> = ({
  rule,
  index,
  onRuleChange,
  onRuleDelete,
  children,
  isLastCreated = false,
  isMetricRule = false,
  isDisabled = false,
}) => {
  const dimensionType = rule.dimensionFilter?.type;
  const isDynamic = rule.dimensionFilter?.isDynamic;
  let ruleEditor;

  if (isDynamic) {
    ruleEditor = (
      <DynamicRule
        rule={rule as RuleWithFilter}
        onRuleChange={onRuleChange}
        isDisabled={isDisabled}
      />
    );
  } else if (dimensionType === DimensionTypes.date) {
    ruleEditor = (
      <DateRule
        rule={rule as RuleWithFilter}
        onRuleChange={onRuleChange}
        defaultIsOpen={isLastCreated}
        isDisabled={isDisabled}
      />
    );
  } else if (isMetricRule) {
    ruleEditor = (
      <MetricRule
        rule={rule}
        onRuleChange={onRuleChange}
        isDisabled={isDisabled}
      />
    );
  } else {
    ruleEditor = (
      <GenericRule
        rule={rule}
        onRuleChange={onRuleChange}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <Box marginBottom="10px" key={`rule-${index}`}>
      <Grid gridTemplateColumns="1fr auto" className="rule-title">
        <Box marginBottom="6px">
          <Text variant="body12Regular">
            {_`Rule`} {index}
          </Text>
        </Box>
        <IconButton
          style={{ display: isDisabled ? "none" : undefined }}
          size="small"
          color="error"
          name="Delete"
          onClick={onRuleDelete}
        />
      </Grid>
      <Flex flexDirection="column" gap={8}>
        {children}
        {ruleEditor}
      </Flex>
    </Box>
  );
};
