import { matchPath, useNavigate, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import "../global.css";
import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useMenu } from "../../hooks/menu";
import { useScroll } from "../../hooks/scroll";
import { theme } from "../../icecube-ux";
import { trackEvent, TRACKING_EVENTS } from "../../utils/trackingUtils";

import { NavigationLink } from "./NavigationLink";
import { NavigationSeparator } from "./NavigationSeparator";
import { NavigationItemType } from "./types";

type NavigationItemProps = {
  item: NavigationItemType;
  itemIndex: number;
  isNavOpen: boolean;
  selected?: boolean;
};

const StyledNavigationItem = styled.li`
  padding: 0px 8px;

  display: flex;
  flex-direction: column;

  gap: 5px;
`;

const StyledAnchors = styled.ul<{
  isShown: boolean;
}>`
  margin-left: 52px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  ${(p) =>
    !p.isShown &&
    css`
      display: none;
    `}
`;

const StyledItemLinks = styled.ul<{
  isShown: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${(p) =>
    !p.isShown &&
    css`
      display: none;
    `}
`;

const StyledItemLink = styled.li<{ isSelected: boolean }>`
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
  color: var(--gray3);
  padding: 4px 0px 4px 62px;
  border-radius: 2px;

  ${(p) =>
    p.isSelected &&
    css`
      color: ${theme.colors.primary100};
    `}

  &:hover {
    color: ${theme.colors.primary100};
    background: ${theme.colors.primary10};
  }
`;

const StyledAnchor = styled.li<{ isSelected: boolean }>`
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
  color: var(--gray3);
  padding: 4px 0px;
  padding-left: 4px;

  ${(p) =>
    p.isSelected &&
    css`
      color: ${theme.colors.primary100};
    `}

  &:hover {
    color: ${theme.colors.text100};
  }
`;

export const NavigationItem = ({
  item,
  itemIndex,
  isNavOpen,
  selected,
}: NavigationItemProps) => {
  const menuContext = useMenu();
  const scroll = useScroll();
  const { tenant } = useBootstrap();
  const { outboundDemoData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToAnchor = (index: number) => {
    document
      .getElementById("i" + index)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleAnchorClick = (
    index: number,
    anchor: string,
    item: NavigationItemType,
  ) => {
    trackEvent(TRACKING_EVENTS.SECTION_CLICKED, {
      tenant_id: tenant?.id || "N/A",
      type: anchor,
    });
    if (item.target && matchPath(location.pathname, item.target)) {
      scrollToAnchor(index);
    } else {
      item.target && navigate(item.target);
      scrollToAnchor(index);
    }
  };

  const handleLinkClick = (item: NavigationItemType) => {
    if (!item.selected) {
      scroll.reset?.();
    }
    trackEvent(TRACKING_EVENTS.TAB_CLICKED, {
      tenant_id: tenant?.id || "N/A",
      type: item.label,
      tabKey: item.key,
      ...outboundDemoData,
    });
    scrollToAnchor(0);
    item.target && navigate(item.target);
  };

  const handleItemLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <>
      <StyledNavigationItem>
        <NavigationLink
          isNavOpen={isNavOpen}
          item={item}
          onClick={() => handleLinkClick(item)}
        />

        {item?.anchors && item.anchors.length > 0 ? (
          <StyledAnchors
            isShown={Boolean(
              isNavOpen &&
                menuContext.hideAnchors[item.key] !== true &&
                item.selected &&
                item?.anchors?.length,
            )}
          >
            {item.anchors?.map((anchor, anchorIndex) => (
              <StyledAnchor
                key={`f-${itemIndex}-${anchorIndex}`}
                isSelected={scroll.index === anchorIndex}
                onClick={() => handleAnchorClick(anchorIndex, anchor, item)}
              >
                {anchor}
              </StyledAnchor>
            ))}
          </StyledAnchors>
        ) : (
          <></>
        )}

        {item?.links && item.links.length > 0 ? (
          <StyledItemLinks
            isShown={Boolean(isNavOpen && item?.links?.length && selected)}
          >
            {item.links?.map(({ target, label }, linkIndex) => (
              <StyledItemLink
                key={`f-${itemIndex}-${linkIndex}`}
                isSelected={document.location.pathname.includes(target)}
                onClick={() => handleItemLinkClick(target)}
              >
                {label}
              </StyledItemLink>
            ))}
          </StyledItemLinks>
        ) : (
          <></>
        )}
      </StyledNavigationItem>

      {item.seperatorAfter && <NavigationSeparator style={{ minHeight: 13 }} />}
    </>
  );
};
