import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  AttributesType,
  FlowConfigDTO,
  FlowConfigUpsertObjectType,
} from "../common/types/capi-service";
import { useAuth } from "../hooks/auth/auth";

import { createClient } from "./apiClient";

export interface KlaviyoFlowsSetup {
  shopify_url: string;
  trigger_type: string;
  original_flow_json: { id: string };
  polar_flow_json: { id: string };
}

interface FlowSetup {
  id: number;
  created_at: string;
  tenant_id: string;
  destination: "meta" | "klaviyo" | "google-ads-capi";
  attributes: AttributesType;
  enabled: boolean;
  flow_identifier: string;
}

const client = createClient("capi-service");

export const getTenantKlaviyoFlowsSetups = async (token: string) => {
  const result = await client
    .new()
    .get("/api/klaviyoFlowsSetups")
    .auth(token)
    .fetch();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data as KlaviyoFlowsSetup[];
};

const FlowConfigQueryKey = ["capi", "flow-config"];

const useFlowConfigs = () => {
  const auth = useAuth();
  return useQuery({
    queryKey: FlowConfigQueryKey,
    queryFn: async () => {
      return getFlowConfigs(await auth.getToken());
    },
    enabled: !auth.processing,
    gcTime: Infinity,
  });
};

export const useMetaFlowConfigs = () => {
  const { isLoading, data: flowConfigs } = useFlowConfigs();
  const target = flowConfigs?.find((config) => config.destination === "meta");
  return {
    isLoading,
    data: target,
  };
};

export const useKlaviyoFlowConfigs = () => {
  const { isLoading, data: flowConfigs } = useFlowConfigs();
  const target = flowConfigs?.find(
    (config) => config.destination === "klaviyo",
  );
  return {
    isLoading,
    data: target,
  };
};

export const useGoogleAdsCapiFlowConfigs = () => {
  const { isLoading, data: flowConfigs } = useFlowConfigs();
  const target = flowConfigs?.find(
    (config) => config.destination === "google-ads-capi",
  );
  return {
    isLoading,
    data: target,
  };
};

const getFlowConfigs = async (token: string) => {
  const result = await client
    .new()
    .get(`/api/flow/configs`)
    .auth(token)
    .fetch();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data as FlowConfigDTO[];
};

const useFlowSetups = () => {
  const auth = useAuth();
  return useQuery({
    queryKey: ["capi", "flow-setups"],
    queryFn: async () => {
      return getFlowSetups(await auth.getToken());
    },
    enabled: !auth.processing,
  });
};

export const useMetaFlowSetups = () => {
  const { isLoading, data: flowSetups } = useFlowSetups();
  return {
    isLoading,
    data: flowSetups?.filter((setup) => setup.destination === "meta") ?? [],
  };
};

export const useKlaviyoFlowSetups = () => {
  const { isLoading, data: flowSetups } = useFlowSetups();
  return {
    isLoading,
    data: flowSetups?.filter((setup) => setup.destination === "klaviyo") ?? [],
  };
};

export const useGoogleAdsCapiFlowSetups = () => {
  const { isLoading, data: flowSetups } = useFlowSetups();
  return {
    isLoading,
    data:
      flowSetups?.filter((setup) => setup.destination === "google-ads-capi") ??
      [],
  };
};

const getFlowSetups = async (token: string) => {
  const result = await client.new().get(`/api/flow/setups`).auth(token).fetch();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data as FlowSetup[];
};

export type ShopifyStoreFacebookPixelInfo = {
  facebookPixelFacebookAccountMapping: Record<
    string,
    {
      datasourceId: string;
      pixelId: string;
      pixelName: string;
      businessId: string;
      businessName: string;
    }[]
  >; // pixel id to facebook account details
  shopifyStorePixelMapping: Record<string, string>; // shopify url to pixel id, url starts from https://
};

export const useShopifyStoreFacebookPixelInfo = () => {
  const auth = useAuth();
  return useQuery({
    queryKey: ["capi", "meta", "shopify-facebook-pixel-info"],
    queryFn: async () => {
      return getShopifyStoreFacebookPixelInfo(await auth.getToken());
    },
    enabled: !auth.processing,
  });
};

const getShopifyStoreFacebookPixelInfo = async (token: string) => {
  const result = await client
    .new()
    .get(`/api/meta/shopify-facebook-pixel-info`)
    .auth(token)
    .fetch();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data as ShopifyStoreFacebookPixelInfo;
};

export const useSaveFlowConfigs = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["capi", "save-flow-config"],
    mutationFn: async (data: FlowConfigUpsertObjectType) => {
      await saveFlowConfigs(await auth.getToken(), data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: FlowConfigQueryKey,
      });
    },
  });
};

const saveFlowConfigs = async (
  token: string,
  data: FlowConfigUpsertObjectType,
) => {
  const result = await client
    .new()
    .post(`/api/flow/configs`)
    .body({ ...data })
    .auth(token)
    .fetch();
  if (result.error) {
    throw new Error(result.message);
  }
};
