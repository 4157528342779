import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { FALLBACK_AVATAR } from "../../common/types/users-service";
import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useAvailableTenants } from "../../hooks/useAvailableTenants";
import {
  Text,
  theme,
  Icon,
  MoreOptions,
  Tag,
  OptionItem,
  Flex,
  Stack,
} from "../../icecube-ux";
import { IconNames } from "../../icecube-ux/Icon/Icon";
import { OptionButton } from "../../icecube-ux/MoreOptions/MoreOptions";
import { _ } from "../../languages/helper";
import { LIVE_DEMO_TENANT_ID } from "../../utils/demoConfig";
import { shouldShowReferral } from "../../utils/subscriptionsUtils";

interface AvatarBlockProps {
  onClickOpenTenantList?: () => void;
  onClickSettings?: () => void;
  onClickHelp?: () => void;
  onClickLogout?: () => void;
  onClickReferrals?: () => void;
  onMouseEnter?: () => void;
  logo: string;
  email: string;
  company?: string;
  username?: string;
  enableSubmenu?: boolean;
  isExpanded?: boolean;
}

const StyledWrapper = styled.div<{ isNavOpen: boolean }>`
  margin: 0;
  margin-top: auto;
  transition: width 0.1s ease;
  background-color: white;
  padding: 14px 18px 18px 18px;
  width: calc(100% - 24px);
  width: 100%;
  min-height: 58px;

  display: flex;
  gap: 4px 12px;

  ${(p) =>
    !p.isNavOpen &&
    css`
      justify-content: center;
    `}
`;

const StyledAvatarImg = styled.img`
  border: 1px solid #eae9ff;
  border-radius: 8px;
  width: 35px;
  height: 35px;
  z-index: 2;
  background-color: #eae9ff;
`;

const StyledOptionItemAvatarImg = styled.img`
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin-top: 4px;
  align-self: center;
`;

const StyledCompanyName = styled(Text).attrs({
  variant: "body12Medium",
  color: theme.colors.text110,
})`
  text-overflow: ellipsis;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
`;

export function AvatarBlock({
  onClickOpenTenantList,
  onClickSettings,
  onClickReferrals,
  onClickLogout,
  onClickHelp,
  onMouseEnter,
  logo,
  email,
  company,
  username,
  enableSubmenu = true,
  isExpanded,
}: AvatarBlockProps) {
  const auth = useAuth();
  const { tenant, subscription, isDemoData } = useBootstrap();
  const { handleLogAsTenant } = useAvailableTenants();
  const showReferral = shouldShowReferral(isDemoData, subscription);

  const getAvatar = (avatar?: string) => {
    if (!avatar) {
      return FALLBACK_AVATAR;
    }
    return `${process.env.REACT_APP_CDN_HOST}/${avatar}`;
  };

  const isProfileCompleted =
    tenant.id !== LIVE_DEMO_TENANT_ID && (company || username);

  return (
    <MoreOptions
      style={{ width: "100%", height: "min-content" }}
      forceWidthMatch
      allowFlexibleHeight
      allowToggle
      className="avatar-more-options"
      options={[
        {
          label: _`Settings`,
          icon: "Settings" as IconNames,
          onClick: onClickSettings || (() => {}),
        },
        { type: "divider" },
        {
          label: _`Switch Workspaces`,
          icon: "Grid2" as IconNames,
          rightComponent: <Tag color="primary">{_`New`}</Tag>,
          onClick: onClickOpenTenantList || (() => {}),
        },
        ...[...(auth.user?.availableTenants || [])]
          .sort((t1, t2) =>
            (t1.companyName || "").localeCompare(t2.companyName || ""),
          )
          .slice(0, 3)
          .map(({ id, avatar, companyName }) => {
            return {
              label: companyName || "Unnamed Workspace",
              leftComponents: [
                <StyledOptionItemAvatarImg src={getAvatar(avatar)} />,
              ],
              onClick: async () => {
                await handleLogAsTenant(id);
              },
              rightComponent:
                auth.user?.tenantId === id ? <Icon name="Check" /> : undefined,
            };
          }),
        ...((auth.user?.availableTenants?.length || 0) > 3
          ? [
              {
                type: "button",
                label: "See all workspaces",
                onClick: onClickOpenTenantList || (() => {}),
              } as OptionButton,
            ]
          : []),
        { type: "divider" },
        ...(showReferral
          ? [
              {
                label: _`Refer and get $300`,
                icon: "Gift",
                onClick: onClickReferrals || (() => {}),
              } as OptionItem,
            ]
          : []),
        {
          label: _`Help`,
          icon: "Help",
          onClick: onClickHelp || (() => {}),
        },
        {
          label: _`Log Out`,
          icon: "Logout",
          onClick: onClickLogout || (() => {}),
        },
      ]}
      anchorComponent={
        <StyledWrapper
          isNavOpen={!!isExpanded}
          className="user-box"
          data-cy="user-box"
          onMouseEnter={onMouseEnter}
        >
          <StyledAvatarImg src={logo} alt="" />
          {isExpanded && (
            <>
              <Stack
                justifyContent="center"
                style={{ flexGrow: 1, flex: "1 0 0" }}
                data-cy="user-info"
              >
                <StyledCompanyName>{company || email}</StyledCompanyName>
                <Flex>
                  {isProfileCompleted ? (
                    <Text variant="body11Regular" color={theme.colors.text90}>
                      {username}
                    </Text>
                  ) : (
                    <Link to="/settings/account">
                      <Text
                        variant="body11Regular"
                        color={theme.colors.primary100}
                      >
                        {_`Complete your profile`}
                      </Text>
                    </Link>
                  )}

                  {enableSubmenu && (
                    <Icon
                      color={theme.colors.text100}
                      size={18}
                      name="ArrowDown"
                      style={{ marginTop: -2 }}
                    />
                  )}
                </Flex>
              </Stack>
            </>
          )}
        </StyledWrapper>
      }
    />
  );
}
