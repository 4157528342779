import { SignUpData } from "../common/types/users-service";

import useLocalStorage from "./useLocalStorage";

const SIGNUP_DATA_STORAGE_KEY = "polar_signup_data";

export const useSignupData = () => {
  const [signupData, setSignupData] = useLocalStorage(
    SIGNUP_DATA_STORAGE_KEY,
    {},
  );

  const getSafelySignupData = () => {
    try {
      const res = signupData as SignUpData;
      const data: SignUpData = {};
      if (res.limitedNavigationMode) {
        data["limitedNavigationMode"] = res.limitedNavigationMode;
      }
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    getSignupData: getSafelySignupData,
    setSignupData,
  };
};
