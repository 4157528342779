import styled, { css } from "styled-components";

import { theme } from "../theme";

import { StyledConnectorIconWrapper } from "./ConnectorIconWrapper.styled";

interface IconCounterProps {
  size?: "tiny" | "small" | "large";
}

const sizes = {
  tiny: 12,
  small: 18,
  large: 24,
} as const;

export const StyledConnectorIconWrapperListCounter = styled.div<IconCounterProps>`
  display: inline-block;
  font-family: ${theme.typography.fontFamily.poppins};
  font-size: ${theme.typography.fontSize.s8px};
  font-weight: ${theme.typography.fontWeight.semiBold};
  box-sizing: border-box;
  background: ${theme.colors.primary20};
  border-radius: ${theme.borderRadius.r2px};
  color: ${theme.colors.primary100};
  text-align: center;

  ${({ size = "small" }) => {
    return css`
      width: ${sizes[size]}px;
      height: ${sizes[size]}px;
      line-height: ${sizes[size]}px;
    `;
  }}}
`;

export const StyledWhiteSeparator = styled.div<IconCounterProps>`
  display: inline-block;
  ${({ size = "small" }) => {
    return css`
      width: ${sizes[size]}px;
      height: ${sizes[size]}px;
    `;
  }}}

  border-radius: ${theme.borderRadius.r2px};
  background: ${theme.colors.white100};
`;

const iconMargins = {
  tiny: 4,
  small: 6,
  large: 8,
} as const;

export const StyledConnectorIconWrapperList = styled.div<{
  showCounter: boolean;
  size: IconCounterProps["size"];
}>`
  display: flex;
  isolation: isolate;

  ${({ size = "small", showCounter }) => {
    return css`
      & ${StyledConnectorIconWrapper} {
        margin-left: -${iconMargins[size]}px;
      }

      & ${StyledConnectorIconWrapper}:first-of-type {
        margin-left: ${showCounter ? `-${iconMargins[size]}px` : "0px"};
      }

      & ${StyledWhiteSeparator} {
        margin-left: -${sizes[size] - 2}px;
      }
    `;
  }}
`;
