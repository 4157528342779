import classNames from "classnames";
import React from "react";

import {
  ConnectorIcon,
  ConnectorIconNames,
  isValidConnectorIcon,
} from "../ConnectorIcon";
import Icon, { IconNames } from "../Icon/Icon";
import { Loader } from "../Loader";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledButton } from "./Button.styled";
import { ButtonColor, ButtonSize } from "./types";

export type ButtonProps = InteractionEvents<HTMLButtonElement> &
  ClassAndStyleProps & {
    block?: boolean;
    size?: ButtonSize;
    color?: ButtonColor;
    disabled?: boolean;
    label?: string;
    children?: React.ReactNode;
    leftIcon?: IconNames | ConnectorIconNames | React.ReactElement;
    rightIcon?: IconNames | ConnectorIconNames | React.ReactElement;
    centerIcon?: IconNames;
    dataAttributes?: { [key: string]: string };
    autoHeight?: boolean;
    loading?: boolean;
    type?: "button" | "submit" | "reset";
    ctaSize?: boolean;
    href?: string;
    asLink?: boolean;
    withBackground?: boolean;
  };

const ICON_SIZES = {
  tiny: 11,
  small: 12,
  large: 14,
  xxlarge: 14,
  icon: 14,
};

export default function Button({
  block,
  size = "large",
  color = "primary",
  disabled = false,
  autoHeight,
  rightIcon,
  leftIcon,
  centerIcon,
  label,
  dataAttributes,
  children,
  className,
  loading,
  ctaSize,
  type = "button",
  href,
  asLink,
  style,
  withBackground = true,
  ...interactionEvents
}: ButtonProps) {
  const iconSize = ICON_SIZES[size];

  return (
    <StyledButton
      as={(asLink ? "a" : "button") as unknown as undefined}
      href={href}
      {...interactionEvents}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (interactionEvents.onClick && !disabled && !loading) {
          interactionEvents.onClick(e);
        }
      }}
      type={type}
      style={style}
      className={classNames(className)}
      {...{
        color,
        disabled: disabled || loading,
        size,
        block,
        autoHeight,
        iconMode: centerIcon !== undefined,
        ctaSize,
        hasClickHandler: Boolean(interactionEvents.onClick),
      }}
      {...dataAttributes}
    >
      {leftIcon &&
        (typeof leftIcon !== "string" ? (
          leftIcon
        ) : isValidConnectorIcon(leftIcon) ? (
          <ConnectorIcon
            name={leftIcon}
            size={iconSize}
            withBackground={withBackground}
          />
        ) : (
          <Icon name={leftIcon} size={iconSize} className="left-icon" />
        ))}

      {!centerIcon && !loading && (label || children)}
      {centerIcon && !loading && <Icon name={centerIcon} size={iconSize} />}
      {loading === true && <Loader size={iconSize} />}

      {rightIcon &&
        (typeof rightIcon !== "string" ? (
          rightIcon
        ) : isValidConnectorIcon(rightIcon) ? (
          <ConnectorIcon
            name={rightIcon}
            size={iconSize}
            withBackground={withBackground}
          />
        ) : (
          <Icon name={rightIcon} size={iconSize} className="right-icon" />
        ))}
    </StyledButton>
  );
}
