import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { AmazonSellerConnectMessage } from "../pages/connectors/popups/connect/AmazonSellerConnectMessage";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "amazon-selling-partner",
  category: INTEGRATION_CATEGORIES.sales_channels,
  image: "Amazon",
  label: "Amazon Seller Central",
  isNewIntegrationService: true,

  dependsOnAccounts: true,
  backgroundColor: theme.customColors.amazonBg,
  borderColor: theme.customColors.amazonBorder,
  get description() {
    return _`E-commerce platform`;
  },
  setupInstructionsSection: AmazonSellerConnectMessage,
  canReconnectOAuth: true,
  provider: "fivetran",
});
