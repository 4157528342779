import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import { DEFAULT_PERMISSIONS } from "../common/permissions/consts";
import { buildPermissionCheck } from "../common/permissions/has-permissions";
import { PermissionCheck } from "../common/permissions/types";
import {
  SETUP_STATES,
  CALL_STATES,
  DASHBOARD_STATES,
} from "../common/setupState/consts";
import { SetupState } from "../common/setupState/types";
import {
  Subscription,
  SUBSCRIPTION_TYPES,
} from "../common/types/subscription-service";
import {
  TenantSettings,
  TenantStates,
  Tenant,
} from "../common/types/users-service";
import FullPageLoader from "../components/Loader/FullPageLoader";
import { CURRENCY_SETTING, DEFAULT_CURRENCY } from "../utils/currencyUtils";
import { LIVE_DEMO_TENANT_ID } from "../utils/demoConfig";

import { useAuth } from "./auth/auth";

export interface BootstrapContextProps {
  loading: boolean;
  dateBoundary: moment.Moment;
  subscription: Subscription | null;
  tenant: Tenant;
  currency: string;
  forceRefresh: (showAsLoading?: boolean) => Promise<void>;
  getUserTenantSetting: <TKey extends keyof TenantSettings>(
    key: TKey,
    defaultValue: TenantSettings[TKey],
    nullIfNotLoaded?: boolean,
  ) => TenantSettings[TKey] | null;
  getUserTenantState: <TKey extends keyof TenantStates>(
    key: TKey,
    defaultValue: TenantStates[TKey],
    nullIfNotLoaded?: boolean,
  ) => TenantStates[TKey] | null;
  setupState: SetupState;
  hasPermission: PermissionCheck;
  isDemoData: boolean;
  isAgencyOutbound?: boolean;
  weekStart: number;
}

export const BootstrapContext = createContext<BootstrapContextProps | null>(
  null,
);

export function ProvideBootstrap({ children }: { children: ReactNode }) {
  const bootstrap = useProvideBootstrap();
  return (
    <BootstrapContext.Provider value={bootstrap}>
      {bootstrap.loading ? <FullPageLoader /> : children}
    </BootstrapContext.Provider>
  );
}

export const useBootstrap = () => {
  const context = useContext(BootstrapContext);
  if (context === null) {
    throw Error("Bootstrap context not provided");
  }
  return context;
};

const useBootstrapQuery = () => {
  const auth = useAuth();
  return useQuery({
    queryKey: ["user", "bootstrap", auth.user?.tenantId],
    queryFn: async () => {
      return await auth.getBootstrap();
    },
  });
};

export const useProvideBootstrap = () => {
  const auth = useAuth();
  const {
    data: bootstrapData,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  } = useBootstrapQuery();
  const [isManualRefetch, setIsManualRefetch] = useState(false);

  const [previousPathname, setPreviousPathname] = useState(
    window.location.pathname,
  );
  const tenant = bootstrapData?.tenant || {
    id: "",
    companyName: "",
    ownerId: "",
    createdAt: new Date().toISOString(),
    settings: {} as TenantSettings,
    states: {} as TenantStates,
  };
  const subscription = bootstrapData?.subscription || null;
  const setupState = bootstrapData?.setupState || {
    isFinished: true,
    code: SETUP_STATES.READY_DATA_DONE_CALL,
    call: CALL_STATES.DONE,
    data: DASHBOARD_STATES.READY,
  };

  const getUserTenantSetting: BootstrapContextProps["getUserTenantSetting"] = (
    key,
    defaultValue,
    nullIfNotLoaded = false,
  ) => {
    if ((!tenant || !tenant.settings) && nullIfNotLoaded) {
      return null;
    }
    return tenant?.settings?.[key] || defaultValue;
  };

  const getUserTenantState: BootstrapContextProps["getUserTenantState"] = (
    key,
    defaultValue,
    nullIfNotLoaded,
  ) => {
    if ((!tenant || !tenant.states) && nullIfNotLoaded) {
      return null;
    }
    return tenant?.states?.[key] === undefined
      ? defaultValue
      : tenant?.states?.[key];
  };

  const forceRefresh = useCallback(
    async (showAsLoading?: boolean) => {
      if (showAsLoading) {
        setIsManualRefetch(true);
      }

      await refetch();
      if (showAsLoading) {
        setIsManualRefetch(false);
      }
    },
    [refetch],
  );

  const pathname = window.location.pathname;
  useEffect(() => {
    const fetch = async () => {
      const token = await auth.getToken();
      if (token && pathname !== previousPathname) {
        setPreviousPathname(pathname);
        await forceRefresh();
      }
    };
    void fetch();
  }, [pathname, previousPathname, auth, forceRefresh]);

  const isDemoData = Boolean(
    !setupState.isFinished ||
      tenant?.id === LIVE_DEMO_TENANT_ID ||
      auth.isVisitor(),
  );

  const isAgencyOutbound = auth.outboundDemoData?.persona === "agency";
  const hasPermission = useMemo(() => {
    return buildPermissionCheck(
      bootstrapData?.permissions || DEFAULT_PERMISSIONS,
    );
  }, [bootstrapData?.permissions]);

  return {
    // we do not care about the loading state from refetching. it will cause the full app rerender => sometime will cause an infinite loop of refetching.
    // e.g. in the onboarding form we have a on-mount refetching, if we do not ignore the refetching here, it will cause the onboarding form to rerender infinitely
    loading: isLoading && isFetching && (!isRefetching || isManualRefetch),
    getUserTenantSetting,
    getUserTenantState,
    tenant,
    currency:
      (bootstrapData?.tenant.settings?.[CURRENCY_SETTING] as string) ||
      DEFAULT_CURRENCY,
    forceRefresh,
    subscription,
    setupState,
    hasPermission,
    dateBoundary:
      subscription?.plan === SUBSCRIPTION_TYPES.PLUS ||
      subscription?.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN
        ? moment()
        : moment().subtract(1, "day"),
    isDemoData,
    isAgencyOutbound,
    weekStart: getUserTenantSetting("weekstart", "sunday") === "sunday" ? 0 : 1,
  };
};
