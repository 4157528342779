import { useEffect, useState } from "react";

import { SUBSCRIPTION_TYPES } from "../../common/types/subscription-service";
import Centering from "../../components/Helpers/Centering";
import { Loader } from "../../components/Loader/Loader";
import { useBootstrap } from "../../hooks/bootstrap";
import { EmptyState, Grid, Space, Text, theme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  openIntercom,
  setIsIntercomLauncherHidden,
} from "../../utils/intercomUtils";
import { hasActivePaidPlan } from "../../utils/subscriptionsUtils";

import UpgradeConfirmationPopup, {
  ConfirmationProperties,
} from "./components/ConfirmationPopup";
import FixedPricingActivatePlanCard from "./FixedPricing/FixedPricingActivatePlanCard";
import FixedPricingAnalyzePlanCard from "./FixedPricing/FixedPricingAnalyzePlanCard";
import FixedPricingEnrichPlanCard from "./FixedPricing/FixedPricingEnrichPlanCard";

interface GMVTierPricingPlanContainerProps {
  style?: React.CSSProperties;
}

const planComponents = {
  [SUBSCRIPTION_TYPES.USAGE_BASED_PLAN]: FixedPricingAnalyzePlanCard,
  [SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN]: FixedPricingEnrichPlanCard,
  [SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_ACTIVATE_PLAN]:
    FixedPricingActivatePlanCard,
};

export default function GMVTierPricingPlanContainer(
  _props: GMVTierPricingPlanContainerProps,
) {
  const {
    loading: isLoading,
    subscription,
    tenant,
    getUserTenantSetting,
  } = useBootstrap();
  const fixedPriceConfig = getUserTenantSetting(
    "fixed_price_config",
    undefined,
  );
  const isOnPaidPlan = hasActivePaidPlan(subscription);
  const isOnCustomPlan =
    tenant.states?.isOnCustomPlan ||
    subscription?.plan === SUBSCRIPTION_TYPES.CUSTOM;
  const [confirmationProperties, setConfirmationProperties] =
    useState<null | ConfirmationProperties>(null);

  const onHelp = () => {
    openIntercom();
  };

  useEffect(() => {
    setIsIntercomLauncherHidden(true);
    return () => {
      setIsIntercomLauncherHidden(false);
    };
  }, []);

  if (isLoading)
    return (
      <>
        <Text variant="body16Medium">{_`Pricing Plans`}</Text>
        <Centering style={{ minHeight: 60 }}>
          <Loader />
        </Centering>
      </>
    );

  const plansToDisplay = Object.keys(planComponents)
    .filter((plan) => {
      const isOnAnalyzeAndEnrich =
        subscription?.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN;
      const isOnActivate =
        subscription?.plan ===
        SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_ACTIVATE_PLAN;
      const isUserOnHigherPlanThanThis =
        (isOnAnalyzeAndEnrich &&
          String(plan) === String(SUBSCRIPTION_TYPES.USAGE_BASED_PLAN)) ||
        (isOnActivate &&
          (String(plan) === String(SUBSCRIPTION_TYPES.USAGE_BASED_PLAN) ||
            String(plan) === String(SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN)));
      return (
        fixedPriceConfig?.[String(plan)]?.enabled && !isUserOnHigherPlanThanThis
      );
    })
    .map((plan) => parseInt(plan, 10));

  if (!fixedPriceConfig || !plansToDisplay?.length) {
    return (
      <>
        <Text variant="body16Medium">{_`Pricing Plans`}</Text>
        <EmptyState
          heading={_`Unable to load pricing plans`}
          subheading={_`Please refresh the page or contact sales if the issue persists`}
          buttonText={_`Contact sales`}
          onButtonClick={onHelp}
        />
      </>
    );
  }

  return (
    <>
      <Text variant="body16Medium">{_`Pricing Plans`}</Text>

      {confirmationProperties ? (
        <UpgradeConfirmationPopup properties={confirmationProperties} />
      ) : null}

      {!isOnCustomPlan && (
        <>
          <Space size={2} />

          <Text
            variant="body12Regular"
            color={theme.colors.text80}
          >{_`Price offered based on communication with sales.`}</Text>

          <Space />
        </>
      )}

      <Grid
        style={{ margin: "auto", maxWidth: plansToDisplay.length * 600 }}
        gridTemplateColumns={`repeat(${plansToDisplay.length}, 1fr)`}
        justifyContent="center"
      >
        {plansToDisplay.map((plan, id) => {
          const PlanComponent = planComponents[plan];
          return (
            <PlanComponent
              key={`${plan}`}
              price={fixedPriceConfig[plan].price}
              isShowingPreviousPlan={id > 0}
              setConfirmationProperties={setConfirmationProperties}
              blockSelfUpgrade={
                false && isOnPaidPlan // TODO: Check for missing subscription
              }
            />
          );
        })}
      </Grid>
    </>
  );
}
