export enum ChannelPerformanceMetricsNoPixel {
  total_marketing_spend = "total_marketing_spend",
  paid_roas = "paid_roas",
  paid_cpa = "paid_cpa",
  total_conversion_value_from_pixels = "total_conversion_value_from_pixels",
}
export const channelPerformanceMetricsNoPixel = Object.values(
  ChannelPerformanceMetricsNoPixel,
);

export const ACQUISITION_BASE_URL = "/acquisition";
