import { _ } from "../languages/helper";
import { IDimension } from "../types/synthesizer";

// Useful for memoizing empty arrays to prevent re-renders
export const EMPTY_ARRAY: [] = [];

export const keyToLabel = (key: string) => {
  if (key == null) {
    return "";
  }

  return `${key}`
    .split("_")
    .map((i) => ucFirst(i))
    .join(" ");
};

const getBreakdownLabel = (
  dimensionKey: string,
  dimensions: { [key: string]: IDimension },
): string => {
  return dimensions?.[dimensionKey]?.label ?? keyToLabel(dimensionKey);
};

const formatListAsString = (values: string[]) => {
  const copiedList = [...values];
  const lastValue = copiedList.pop();
  return `${copiedList.join(", ")}${
    copiedList.length > 0 ? ` ${_`and`} ` : ""
  }${lastValue}`;
};

export const breakdownLabel = (
  selectedBreakdowns: string[],
  dimensions: { [key: string]: IDimension },
) => {
  if (selectedBreakdowns.length === 0) {
    return _`Breakdowns`;
  }

  const labelList = selectedBreakdowns.map((el) =>
    getBreakdownLabel(el, dimensions),
  );

  return `${_`By ${["breakdowns", formatListAsString(labelList)]}`}`;
};

export const cloneObject = <T>(item: T) =>
  JSON.parse(JSON.stringify(item)) as T;

export const randomString = (len: number) => {
  const result = [];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < len; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength)),
    );
  }
  return result.join("");
};

export const ucFirst = (value: string) => {
  return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
};

export const removeDuplicates = <T>(array: T[]) => {
  return [...new Set(array)] as T[];
};

export const arrayIntersection = <T>(a: T[], b: T[]) => {
  return a.filter((value) => b.includes(value));
};

export const getCurrencyFullLabel = (key: string) => {
  if (CURRENCY_DESCRIPTIONS[key]) {
    return `${key} ${CURRENCY_DESCRIPTIONS[key].name} (${CURRENCY_DESCRIPTIONS[key].symbol})`;
  }
  return key;
};

export const filterIsNotNullOrUndefined = <T>(
  item: T | undefined | null,
): item is T => item !== null && item !== undefined;

export const getOperativeSystem = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes("mac")) {
    return "Mac";
  }
  return "Windows";
};

// TODO: Translate names of currencies
const CURRENCY_DESCRIPTIONS: {
  [key: string]: { label: string; symbol: string; name: string };
} = {
  AED: { label: "AED", symbol: "د.إ", name: "United Arab Emirates Dirham" },
  AFN: { label: "AFN", symbol: "؋", name: "Afghani" },
  ALL: { label: "ALL", symbol: "L", name: "Albanian Lek" },
  AMD: { label: "AMD", symbol: "֏", name: "Armenian Dram" },
  ANG: { label: "ANG", symbol: "ƒ", name: "Netherlands Antillean Guilder" },
  AOA: { label: "AOA", symbol: "Kz", name: "Kwanza" },
  ARS: { label: "ARS", symbol: "N$", name: "Argentine Peso" },
  AUD: { label: "AUD", symbol: "A$", name: "Australian Dollar" },
  AWG: { label: "AWG", symbol: "ƒ", name: "Aruban Florin" },
  AZN: { label: "AZN", symbol: "₼", name: "Azerbaijani Manat" },
  BAM: {
    label: "BAM",
    symbol: "KM",
    name: "Bosnia and Herzegovina Convertible Mark",
  },
  BBD: { label: "BBD", symbol: "Bds$", name: "Barbadian Dollar" },
  BDT: { label: "BDT", symbol: "৳", name: "Bangladeshi Taka" },
  BGN: { label: "BGN", symbol: "лв", name: "Bulgarian Lev" },
  BHD: { label: "BHD", symbol: "BD", name: "Bahraini Dinar" },
  BIF: { label: "BIF", symbol: "FBu", name: "Burundi Franc" },
  BMD: { label: "BMD", symbol: "$", name: "Bermudian Dollar" },
  BND: { label: "BND", symbol: "$", name: "Brunei Dollar" },
  BOB: { label: "BOB", symbol: "Bs", name: "Bolivian Boliviano" },
  BRL: { label: "BRL", symbol: "R$", name: "Brazilian Real" },
  BSD: { label: "BSD", symbol: "$", name: "Bahamian Dollar" },
  BTN: { label: "BTN", symbol: "Nu.", name: "Ngultrum" },
  BWP: { label: "BWP", symbol: "P", name: "Pula" },
  BYN: { label: "BYN", symbol: "Br", name: "Belarusian Ruble" },
  BZD: { label: "BZD", symbol: "$", name: "Belize Dollar" },
  CAD: { label: "CAD", symbol: "C$", name: "Canadian Dollar" },
  CDF: { label: "CDF", symbol: "Fr", name: "Congolese Franc" },
  CHF: { label: "CHF", symbol: "Fr", name: "Swiss Franc" },
  CLP: { label: "CLP", symbol: "CLP$", name: "Chilean Peso" },
  CNY: { label: "CNY", symbol: "¥", name: "Chinese Yuan" },
  COP: { label: "COP", symbol: "COP$", name: "Colombian Peso" },
  CRC: { label: "CRC", symbol: "₡", name: "Costa Rican Colon" },
  CUP: { label: "CUP", symbol: "$", name: "Cuban Peso" },
  CVE: { label: "CVE", symbol: "$", name: "Cape Verdean Escudo" },
  CZK: { label: "CZK", symbol: "Kč", name: "Czech Koruna" },
  DJF: { label: "DJF", symbol: "Fr", name: "Djiboutian Franc" },
  DKK: { label: "DKK", symbol: "kr", name: "Danish Krone" },
  DOP: { label: "DOP", symbol: "$", name: "Dominican Peso" },
  DZD: { label: "DZD", symbol: "د.ج", name: "Algerian Dinar" },
  EGP: { label: "EGP", symbol: "E£", name: "Egyptian Pound" },
  ERN: { label: "ERN", symbol: "Nkf", name: "Eritrean Nakfa" },
  ETB: { label: "ETB", symbol: "Br", name: "Ethiopian Birr" },
  EUR: { label: "EUR", symbol: "€", name: "Euro" },
  FJD: { label: "FJD", symbol: "$", name: "Fijian Dollar" },
  FKP: { label: "FKP", symbol: "£", name: "Falkland Islands Pound" },
  GBP: { label: "GBP", symbol: "£", name: "British Pound" },
  GEL: { label: "GEL", symbol: "₾", name: "Georgian Lari" },
  GHS: { label: "GHS", symbol: "GH₵", name: "Ghanaian Cedi" },
  GIP: { label: "GIP", symbol: "£", name: "Gibraltar Pound" },
  GMD: { label: "GMD", symbol: "D", name: "Gambian Dalasi" },
  GNF: { label: "GNF", symbol: "FG", name: "Guinea Franc" },
  GTQ: { label: "GTQ", symbol: "Q", name: "Guatemalan Quetzal" },
  GYD: { label: "GYD", symbol: "G$", name: "Guyanese Dollar" },
  HKD: { label: "HKD", symbol: "HK$", name: "Hong Kong Dollar" },
  HNL: { label: "HNL", symbol: "L", name: "Honduran Lempira" },
  HRK: { label: "HRK", symbol: "kn", name: "Croatian Kuna" },
  HTG: { label: "HTG", symbol: "G", name: "Haitian Gourde" },
  HUF: { label: "HUF", symbol: "Ft", name: "Hungarian Forint" },
  IDR: { label: "IDR", symbol: "Rp", name: "Indonesian Rupiah" },
  ILS: { label: "ILS", symbol: "₪", name: "Israeli New Shekel" },
  INR: { label: "INR", symbol: "₹", name: "Indian Rupee" },
  IQD: { label: "IQD", symbol: "ع.د", name: "Iraqi Dinar" },
  ISK: { label: "ISK", symbol: "kr", name: "Icelandic Króna" },
  JMD: { label: "JMD", symbol: "J$", name: "Jamaican Dollar" },
  JOD: { label: "JOD", symbol: "د.ا", name: "Jordanian Dinar" },
  JPY: { label: "JPY", symbol: "¥", name: "Japanese Yen" },
  KES: { label: "KES", symbol: "KES", name: "Kenyan Shilling" },
  KGS: { label: "KGS", symbol: "с", name: "Kyrgyzstani Som" },
  KHR: { label: "KHR", symbol: "៛", name: "Cambodian Riel" },
  KMF: { label: "KMF", symbol: "Fr", name: "Comorian Franc" },
  KRW: { label: "KRW", symbol: "₩", name: "South Korean Won" },
  KWD: { label: "KWD", symbol: "K.D.", name: "Kuwaiti Dinar" },
  KYD: { label: "KYD", symbol: "$", name: "Cayman Islands Dollar" },
  KZT: { label: "KZT", symbol: "₸", name: "Kazakhstani Tenge" },
  LAK: { label: "LAK", symbol: "₭", name: "Kip" },
  LBP: { label: "LBP", symbol: "ل.ل", name: "Lebanese Pound" },
  LKR: { label: "LKR", symbol: "රු", name: "Sri Lankan Rupee" },
  LSL: { label: "LSL", symbol: "L", name: "Lesotho Loti" },
  LTL: { label: "LTL", symbol: "LTL", name: "Lithuania Litas" },
  LVL: { label: "LVL", symbol: "Ls", name: "Latvian Lats" },
  LYD: { label: "LYD", symbol: "ل.د", name: "Libyan Dinar" },
  MAD: { label: "MAD", symbol: "‎DH", name: "Moroccan Dirham" },
  MDL: { label: "MDL", symbol: "L", name: "Moldovan Leu" },
  MGA: { label: "MGA", symbol: "Ar", name: "Malagasy Ariary" },
  MKD: { label: "MKD", symbol: "ден", name: "Macedonian Denar" },
  MMK: { label: "MMK", symbol: "Ks", name: "Burmese Kyat" },
  MNT: { label: "MNT", symbol: "₮", name: "Mongolian Tögrög" },
  MOP: { label: "MOP", symbol: "MOP$", name: "Macanese Pataca" },
  MRO: { label: "MRO", symbol: "UM", name: "Mauritanian Ouguiya" },
  MRU: { label: "MRU", symbol: "UM", name: "Mauritanian Ouguiya" },
  MUR: { label: "MUR", symbol: "₨", name: "Mauritian Rupee" },
  MVR: { label: "MVR", symbol: ".ރ", name: "Maldivian Rufiyaa" },
  MWK: { label: "MWK", symbol: "MK", name: "Malawian Kwacha" },
  MXN: { label: "MXN", symbol: "MXN", name: "Mexican Peso" },
  MYR: { label: "MYR", symbol: "RM", name: "Malaysian Ringgit" },
  MZN: { label: "MZN", symbol: "MT", name: "Mozambican Metical" },
  NAD: { label: "NAD", symbol: "N$", name: "Namibia Dollar" },
  NGN: { label: "NGN", symbol: "₦", name: "Nigerian Naira" },
  NIO: { label: "NIO", symbol: "C$", name: "Nicaraguan Córdoba" },
  NOK: { label: "NOK", symbol: "kr", name: "Norwegian Krone" },
  NPR: { label: "NPR", symbol: "‎रू", name: "Nepalese Rupee" },
  NZD: { label: "NZD", symbol: "NZ$", name: "New Zealand Dollar" },
  OMR: { label: "OMR", symbol: "ر.ع.", name: "Omani Rial" },
  PAB: { label: "PAB", symbol: "B/.", name: "Panamanian Balboa" },
  PEN: { label: "PEN", symbol: "PEN", name: "Peruvian Sol" },
  PGK: { label: "PGK", symbol: "K", name: "Kina" },
  PHP: { label: "PHP", symbol: "₱", name: "Philippine Peso" },
  PKR: { label: "PKR", symbol: "Rs", name: "Pakistani Rupee" },
  PLN: { label: "PLN", symbol: "zł", name: "Polish Złoty" },
  PYG: { label: "PYG", symbol: "Gs", name: "Paraguayan Guarani" },
  QAR: { label: "QAR", symbol: "ر.ق", name: "Qatari Riyal" },
  RON: { label: "RON", symbol: "lei", name: "Romanian Leu" },
  RSD: { label: "RSD", symbol: "дин.", name: "Serbian Dinar" },
  RUB: { label: "RUB", symbol: "₽", name: "Russian Ruble" },
  RWF: { label: "RWF", symbol: "Fr", name: "Rwandan Franc" },
  SAR: { label: "SAR", symbol: "﷼", name: "Saudi Riyal" },
  SBD: { label: "SBD", symbol: "$", name: "Solomon Islands Dollar" },
  SCR: { label: "SCR", symbol: "₨", name: "Seychellois Rupee" },
  SEK: { label: "SEK", symbol: "kr", name: "Swedish Krona" },
  SGD: { label: "SGD", symbol: "S$", name: "Singapore Dollar" },
  SHP: { label: "SHP", symbol: "£", name: "Saint Helena Pound" },
  SLL: { label: "SLL", symbol: "Le", name: "Sierra Leonean Leone" },
  SRD: { label: "SRD", symbol: "$", name: "Surinamese Dollar" },
  STN: { label: "STN", symbol: "Db", name: "São Tomé and Príncipe Dobra" },
  SVC: { label: "SVC", symbol: "$", name: "Salvadoran Colón" },
  SYP: { label: "SYP", symbol: "£", name: "Syrian Pound" },
  SZL: { label: "SZL", symbol: "L", name: "Swazi Lilangeni" },
  THB: { label: "THB", symbol: "฿", name: "Thai Baht" },
  TJS: { label: "TJS", symbol: "ЅМ", name: "Tajikistani Somoni" },
  TMT: { label: "TMT", symbol: "m", name: "Turkmenistan Manat" },
  TND: { label: "TND", symbol: "د.ت", name: "Tunisian Dinar" },
  TOP: { label: "TOP", symbol: "T$", name: "Tongan Paʻanga" },
  TRY: { label: "TRY", symbol: "₺", name: "Turkish Lira" },
  TTD: { label: "TTD", symbol: "$", name: "Trinidad and Tobago Dollar" },
  TWD: { label: "TWD", symbol: "NT$", name: "New Taiwan Dollar" },
  TZS: { label: "TZS", symbol: "Sh", name: "Tanzanian Shilling" },
  UAH: { label: "UAH", symbol: "₴", name: "Ukrainian Hryvnia" },
  UGX: { label: "UGX", symbol: "Sh", name: "Ugandan Shilling" },
  USD: { label: "USD", symbol: "$", name: "US Dollar" },
  UYU: { label: "UYU", symbol: "$U", name: "Uruguayan Peso" },
  UZS: { label: "UZS", symbol: "so'm", name: "Uzbekistani Soʻm" },
  VES: { label: "VES", symbol: "Bs.", name: "Venezuelan Bolívar Soberano" },
  VND: { label: "VND", symbol: "₫", name: "Vietnamese Dồng" },
  VUV: { label: "VUV", symbol: "Vt", name: "Vanuatu Vatu" },
  WST: { label: "WST", symbol: "T", name: "Samoan Tālā" },
  XAF: { label: "XAF", symbol: "Fr", name: "Central African CFA franc" },
  XAG: { label: "XAG", symbol: "", name: "Silver" },
  XAU: { label: "XAU", symbol: "", name: "Gold" },
  XCD: { label: "XCD", symbol: "$", name: "East Caribbean Dollar" },
  XOF: { label: "XOF", symbol: "Fr", name: "West African CFA Franc" },
  XPF: { label: "XPF", symbol: "₣", name: "CFP Franc" },
  YER: { label: "YER", symbol: "﷼", name: "Yemeni Rial" },
  ZAR: { label: "ZAR", symbol: "R", name: "South African Rand" },
  ZMW: { label: "ZMW", symbol: "ZK", name: "Zambian Kwacha" },
};

export const ATTRIBUTION_PIXEL_MODEL = "shopify_attribution_pixel";
export const UNDERSTAND_ATTRIBUTION_PIXEL_INTERCOM_ARTICLE_ID = "6706026";
export const ATTRIBUTION_INTERCOM_LINK =
  "https://intercom.help/polar-app/en/articles/6752194-how-to-set-up-polar-pixel-tracking-parameters-for-polar-attribution";
export const ATTRIBUTION_RECHARGE_INTERCOM_LINK =
  "https://intercom.help/polar-app/en/articles/7105020-how-to-set-up-polar-pixel-on-recharge";
