import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

import Centering from "../../../../components/Helpers/Centering";
import { LOCAL_STORAGE_KEYS } from "../../../../hooks/useLocalStorage";
import { _ } from "../../../../languages/helper";
import { sendError } from "../../../../utils/errorUtils";
import "./placeholders.css";

interface ClientError {
  name: string;
  message: string;
  stack?: string;
}
export default function SomethingWrong() {
  const error = useRouteError() as ClientError;
  useEffect(() => {
    const send = async () => {
      const errorPath = window.location?.href;
      const errorStack = (error.stack ?? "")
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line !== "");

      const { id: userId, tenantId } = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ATTRIBUTES) || "{}",
      ) as { id?: string; tenantId?: string };

      await sendError({
        message: error.message,
        name: error.name,
        errorStack,
        path: errorPath,
        userId,
        tenantId,
      });
    };
    void send();
  }, [error]);

  return (
    <Centering className="placeholder">
      <img src="/img/no-result-found.svg" alt="" />
      <div className="info">{_`Oops! Something went wrong`}</div>
      <div className="subtext gray">
        {_`You can refresh the page and try again.`}
        <br />
        {_`If the issue persists, please contact us in the chat or send an e-mail to`}
        <br />
        <a href="mailto:support@polaranalytics.co">support@polaranalytics.co</a>
      </div>
    </Centering>
  );
}
