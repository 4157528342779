import React from "react";

import useLocalStorage from "../../hooks/useLocalStorage";
import Navigation from "../Navigation/Navigation";
import { NavigationItemType } from "../Navigation/types";
import "./layout.css";

interface LayoutProps {
  logo: string;
  email: string;
  company?: string;
  username?: string;
  items: NavigationItemType[];
  className?: string;
  banner?: React.ReactElement;
  fullHeight?: boolean;
  onClickOpenTenantList?: () => void;
  onClickReferrals?: () => void;
  onClickUserBoxSettings?: () => void;
  onClickUserBoxHelp?: () => void;
  onClickUserBoxLogout?: () => void;
  containerParentRef: (node: HTMLDivElement | null) => void;
  children?: React.ReactNode;
}

export default React.forwardRef<HTMLDivElement, LayoutProps>(function Layout(
  {
    logo,
    email,
    company,
    username,
    items,
    className = "",
    fullHeight = false,
    banner,
    onClickOpenTenantList,
    onClickReferrals,
    onClickUserBoxSettings,
    onClickUserBoxHelp,
    onClickUserBoxLogout,
    containerParentRef,
    ...props
  }: LayoutProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
  ref,
) {
  const [navigationOpened, setNavigationOpened] = useLocalStorage(
    "navigation-open",
    true,
  );

  const content = (
    <div
      {...props}
      className={
        "layout " + (navigationOpened ? " nav-opened " : "") + className
      }
    >
      <Navigation
        isToggledOpen={navigationOpened}
        onToggleOpen={(v) => setNavigationOpened(v)}
        hasTopBanner={!!banner}
        items={items}
        logo={logo}
        email={email}
        company={company}
        username={username}
        onClickReferrals={onClickReferrals}
        onClickOpenTenantList={onClickOpenTenantList}
        onClickUserBoxSettings={onClickUserBoxSettings}
        onClickUserBoxHelp={onClickUserBoxHelp}
        onClickUserBoxLogout={onClickUserBoxLogout}
      />
      <div
        className={
          "relative main-content " +
          (fullHeight ? "layout-h100" : "margin-bottom-x6large")
        }
        ref={ref}
      >
        {props.children}
      </div>
    </div>
  );

  if (banner) {
    return (
      <div className="layout-container has-banner" ref={containerParentRef}>
        {banner}
        {content}
      </div>
    );
  }

  return content;
});
