/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import styled, { css } from "styled-components";

import { useMenu } from "../../hooks/menu";
import {
  EmojiIconBackground,
  legacyTheme,
  MoreOptions,
  Tag,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { PlusIcon } from "../Icons/Icons";

import { NavigationItemType } from "./types";

interface NavigationLinkProps {
  isNavOpen: boolean;
  item: NavigationItemType;
  onClick: () => void;
}

const StyledNavigationLink = styled.a<{
  isNavOpen: boolean;
  disabled: boolean;
}>`
  border-radius: ${theme.borderRadius.r4px};

  padding: 4px;
  padding-left: 14px;

  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  gap: 4px;

  ${(p) =>
    !p.isNavOpen &&
    css`
      justify-content: center;
      margin-left: 0;
      padding: 4px;
    `}

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
    `}
`;

const StyledItemText = styled.div<{
  selected: boolean;
  disabled: boolean;
  isNavOpen: boolean;
}>`
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  color: var(--gray4);
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;

  ${(p) =>
    p.selected &&
    css`
      color: #5a50fe;
    `}

  ${(p) =>
    p.disabled
      ? css`
          color: var(--gray4);
        `
      : css`
          &:hover {
            color: var(--gray5);
          }
        `}

        &:hover {
    overflow-wrap: break-word;
    white-space: normal;
  }

  ${(p) =>
    !p.isNavOpen &&
    css`
      display: none;
    `}
`;

const StyledLeftIcon = styled.div<{ isNavOpen: boolean; disabled: boolean }>`
  margin-right: 12px;
  text-align: center;

  ${(p) =>
    !p.isNavOpen &&
    css`
      margin: 0 0 0 0;
    `}

  .emoji-icon .emoji-icon-text {
    display: inline-block;
  }

  ${(p) =>
    p.disabled
      ? css`
          .icon {
            color: var(--gray4);
          }
        `
      : css`
          .icon {
            vertical-align: middle;
          }
        `}
`;

const StyledRightContent = styled.div<{ isShown: boolean }>`
  margin-left: auto;

  ${(p) =>
    !p.isShown &&
    css`
      display: none;
    `}
`;

const StyledAddItem = styled.div`
  width: 20px;
  height: 20px;
  background: ${legacyTheme.colors.primary10};
  color: ${legacyTheme.colors.primary3};
  line-height: 18px;
  text-align: center;
  border-radius: 4px;

  &:hover {
    background: #eae9ff;
  }

  & > div {
    text-align: left;
  }
`;

const StyledRightIcon = styled.div<{ isNavOpen: boolean }>`
  height: 20px;
  line-height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-left: auto;

  ${(p) =>
    !p.isNavOpen &&
    css`
      display: none;
    `}
`;

export function NavigationLink({
  isNavOpen,
  item,
  onClick,
}: NavigationLinkProps) {
  const menuContext = useMenu();
  const [isLinkHovered, setIsLinkHovered] = useState(false);

  const plusButtonOptions =
    menuContext.elementExtraSettings?.[item.key]?.plusButtonOptionItems ||
    item.plusButtonOptionItems;
  const plusButtonHandler =
    menuContext.elementExtraSettings?.[item.key]?.plusButtonCallback ||
    item.plusButtonCallback;
  const subComponent =
    menuContext.elementExtraSettings?.[item.key]?.subComponent;

  return (
    <>
      <StyledNavigationLink
        href={item.disabled ? undefined : item.target}
        onMouseEnter={() => setIsLinkHovered(true)}
        onMouseLeave={() => setIsLinkHovered(false)}
        isNavOpen={isNavOpen}
        disabled={!!item.disabled}
        className={"navigation-item"}
        onClick={(e) => {
          if (!item.disabled) {
            e.preventDefault();
            e.stopPropagation();
            onClick();
            return false;
          }
        }}
      >
        {item.icon ? (
          <StyledLeftIcon isNavOpen={isNavOpen} disabled={!!item.disabled}>
            <EmojiIconBackground
              background={
                item.selected || isLinkHovered
                  ? item?.iconActiveBackground ?? ""
                  : item?.iconBackground ?? ""
              }
              size="nav"
            >
              {item.icon}
            </EmojiIconBackground>
          </StyledLeftIcon>
        ) : (
          <></>
        )}
        <StyledItemText
          isNavOpen={isNavOpen}
          selected={!!item.selected}
          disabled={!!item.disabled}
        >
          {item.label}
        </StyledItemText>

        <StyledRightContent isShown={isNavOpen}>
          {item.isBeta && <Tag color="primary">{_`Beta`}</Tag>}
          {item.isNew && <Tag color="primary">{_`New`}</Tag>}

          {plusButtonHandler && (
            <StyledAddItem
              onClick={(e) => {
                plusButtonHandler && plusButtonHandler();
                e.stopPropagation();
                e.preventDefault();
                return false;
              }}
            >
              <PlusIcon />
            </StyledAddItem>
          )}

          {plusButtonOptions && (
            <StyledAddItem>
              <MoreOptions
                className="item-add"
                options={plusButtonOptions}
                anchorComponent={<PlusIcon />}
              />
            </StyledAddItem>
          )}

          {item.iconRight && (
            <StyledRightIcon isNavOpen={isNavOpen}>
              {item.iconRight}
            </StyledRightIcon>
          )}
        </StyledRightContent>
      </StyledNavigationLink>
      {isNavOpen && subComponent}
    </>
  );
}
