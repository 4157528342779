import React, { LazyExoticComponent } from "react";

import { IconSVGProps } from "./types";

export const IconSVG: {
  [key: string]: LazyExoticComponent<
    (props: IconSVGProps) => React.ReactElement
  >;
} = {
  ActivateDollar: React.lazy(() => import("./svg/ActivateDollar")),
  ActivateEnvelope: React.lazy(() => import("./svg/ActivateEnvelope")),
  ActivateTrophy: React.lazy(() => import("./svg/ActivateTrophy")),
  ActivateUser: React.lazy(() => import("./svg/ActivateUser")),
  Addons: React.lazy(() => import("./svg/Addons")),
  AddMarker: React.lazy(() => import("./svg/AddMarker")),
  AskPolar: React.lazy(() => import("./svg/AskPolar")),
  Bars: React.lazy(() => import("./svg/Bars")),
  CaretNext: React.lazy(() => import("./svg/CaretNext")),
  CaretBack: React.lazy(() => import("./svg/CaretBack")),
  ArrowNumber: React.lazy(() => import("./svg/ArrowNumber")),
  ArrowLeft: React.lazy(() => import("./svg/ArrowLeft")),
  ArrowLeftAlign: React.lazy(() => import("./svg/ArrowLeftAlign")),
  ArrowRight: React.lazy(() => import("./svg/ArrowRight")),
  ArrowRightAlign: React.lazy(() => import("./svg/ArrowRightAlign")),
  ArrowDown: React.lazy(() => import("./svg/ArrowDown")),
  ArrowDownWithTail: React.lazy(() => import("./svg/ArrowDownWithTail")),
  ArrowUp: React.lazy(() => import("./svg/ArrowUp")),
  ArrowUpWithTail: React.lazy(() => import("./svg/ArrowUpWithTail")),
  BarChart: React.lazy(() => import("./svg/BarChart")),
  LineChart: React.lazy(() => import("./svg/LineChart")),
  PieChart: React.lazy(() => import("./svg/PieChart")),
  Capi: React.lazy(() => import("./svg/Capi")),
  Clock: React.lazy(() => import("./svg/Clock")),
  Percent: React.lazy(() => import("./svg/Percent")),
  Enter: React.lazy(() => import("./svg/Enter")),
  EyeOpen: React.lazy(() => import("./svg/EyeOpen")),
  EyeClosed: React.lazy(() => import("./svg/EyeClosed")),
  Gift: React.lazy(() => import("./svg/Gift")),
  ColoredGift: React.lazy(() => import("./svg/ColoredGift")),
  Help: React.lazy(() => import("./svg/Help")),
  Table: React.lazy(() => import("./svg/Table")),
  Tabulation: React.lazy(() => import("./svg/Tabulation")),
  Indicator: React.lazy(() => import("./svg/Indicator")),
  Target: React.lazy(() => import("./svg/Target")),
  Link: React.lazy(() => import("./svg/Link")),
  Top: React.lazy(() => import("./svg/Top")),
  TopFilled: React.lazy(() => import("./svg/TopFilled")),
  Triangle: React.lazy(() => import("./svg/Triangle")),
  Pin: React.lazy(() => import("./svg/Pin")),
  Pivot: React.lazy(() => import("./svg/Pivot")),
  Documentation: React.lazy(() => import("./svg/Documentation")),
  Dragger: React.lazy(() => import("./svg/Dragger")),
  ExternalLink: React.lazy(() => import("./svg/ExternalLink")),
  Filters: React.lazy(() => import("./svg/Filters")),
  Duplicate: React.lazy(() => import("./svg/Duplicate")),
  Delete: React.lazy(() => import("./svg/Delete")),
  Dislike: React.lazy(() => import("./svg/Dislike")),
  Cards: React.lazy(() => import("./svg/Cards")),
  Check: React.lazy(() => import("./svg/Check")),
  Add: React.lazy(() => import("./svg/Add")),
  Refresh: React.lazy(() => import("./svg/Refresh")),
  Search: React.lazy(() => import("./svg/Search")),
  Send: React.lazy(() => import("./svg/Send")),
  Question: React.lazy(() => import("./svg/Question")),
  Edit: React.lazy(() => import("./svg/Edit")),
  Exit: React.lazy(() => import("./svg/Exit")),
  Guests: React.lazy(() => import("./svg/Guests")),
  Download: React.lazy(() => import("./svg/Download")),
  Dropdown: React.lazy(() => import("./svg/Dropdown")),
  Calendar: React.lazy(() => import("./svg/Calendar")),
  EvolutionDown: React.lazy(() => import("./svg/EvolutionDown")),
  EvolutionUp: React.lazy(() => import("./svg/EvolutionUp")),
  InfoCircle: React.lazy(() => import("./svg/InfoCircle")),
  Letters: React.lazy(() => import("./svg/Letters")),
  Level2: React.lazy(() => import("./svg/Level2")),
  Level3: React.lazy(() => import("./svg/Level3")),
  Logout: React.lazy(() => import("./svg/Logout")),
  MinusOperator: React.lazy(() => import("./svg/MinusOperator")),
  PlusOperator: React.lazy(() => import("./svg/PlusOperator")),
  Clipboard: React.lazy(() => import("./svg/Clipboard")),
  Close: React.lazy(() => import("./svg/Close")),
  CloseCircle: React.lazy(() => import("./svg/CloseCircle")),
  Unlock: React.lazy(() => import("./svg/Unlock")),
  MenuFold: React.lazy(() => import("./svg/MenuFold")),
  MenuUnfold: React.lazy(() => import("./svg/MenuUnfold")),
  Numbers: React.lazy(() => import("./svg/Numbers")),
  Like: React.lazy(() => import("./svg/Like")),
  More: React.lazy(() => import("./svg/More")),
  Paint: React.lazy(() => import("./svg/Paint")),
  Picture: React.lazy(() => import("./svg/Picture")),
  PointingArrow: React.lazy(() => import("./svg/PointingArrow")),
  Settings: React.lazy(() => import("./svg/Settings")),
  SettingsFilled: React.lazy(() => import("./svg/SettingsFilled")),
  ShoppingBag: React.lazy(() => import("./svg/ShoppingBag")),
  Sparkle: React.lazy(() => import("./svg/Sparkle")),
  Bell: React.lazy(() => import("./svg/Bell")),
  Time: React.lazy(() => import("./svg/Time")),
  Verified: React.lazy(() => import("./svg/Verified")),
  VerifiedFilled: React.lazy(() => import("./svg/VerifiedFilled")),
  Warning: React.lazy(() => import("./svg/Warning")),
  TryAddon: React.lazy(() => import("./svg/TryAddon")),
  SuggestAFeature: React.lazy(() => import("./svg/SuggestAFeature")),
  Lock: React.lazy(() => import("./svg/Lock")),
  Loading: React.lazy(() => import("./svg/Loading")),
  AddMessage: React.lazy(() => import("./svg/AddMessage")),
  Connection: React.lazy(() => import("./svg/Connection")),
  Folder: React.lazy(() => import("./svg/Folder")),
  FolderLine: React.lazy(() => import("./svg/FolderLine")),
  VectorDown: React.lazy(() => import("./svg/VectorDown")),
  VectorRight: React.lazy(() => import("./svg/VectorRight")),
  Exchange: React.lazy(() => import("./svg/Exchange")),
  FirstTouchModel: React.lazy(() => import("./svg/FirstTouchModel")),
  FullImpactModel: React.lazy(() => import("./svg/FullImpactModel")),
  FullImpactPaidModel: React.lazy(() => import("./svg/FullImpactPaidModel")),
  FullPaidOverlapModel: React.lazy(() => import("./svg/FullPaidOverlapModel")),
  FullPaidOverlapWithViewsModel: React.lazy(
    () => import("./svg/FullPaidOverlapWithViewsModel"),
  ),
  LastTouchModel: React.lazy(() => import("./svg/LastTouchModel")),
  LinearModel: React.lazy(() => import("./svg/LinearModel")),
  LinearPaidModel: React.lazy(() => import("./svg/LinearPaidModel")),
  UShapedModel: React.lazy(() => import("./svg/UShapedModel")),
  TimeDecayModel: React.lazy(() => import("./svg/TimeDecayModel")),
  FlowChart: React.lazy(() => import("./svg/FlowChart")),
  RoundedCheck: React.lazy(() => import("./svg/RoundedCheck")),
  Columns: React.lazy(() => import("./svg/Columns")),
  Journey: React.lazy(() => import("./svg/Journey")),
  Group: React.lazy(() => import("./svg/Group")),
  Reload: React.lazy(() => import("./svg/Reload")),
  DateRangeLock: React.lazy(() => import("./svg/DateRangeLock")),
  DateRangeWarning: React.lazy(() => import("./svg/DateRangeWarning")),
  Granularity: React.lazy(() => import("./svg/Granularity")),
  GranularityLock: React.lazy(() => import("./svg/GranularityLock")),
  GranularityWarning: React.lazy(() => import("./svg/GranularityWarning")),
  Grid2: React.lazy(() => import("./svg/Grid2")),
  Idea: React.lazy(() => import("./svg/Idea")),
};
