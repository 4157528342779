import moment from "moment";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SETUP_STATES } from "../../common/setupState/consts";
import { SUBSCRIPTION_TYPES } from "../../common/types/subscription-service";
import { FALLBACK_AVATAR } from "../../common/types/users-service";
import { resetViewEditor } from "../../components/CountryFilters/views/ViewProvider";
import Layout from "../../components/Layout/Layout";
import { NavigationItemType } from "../../components/Navigation/types";
import { TENANT_STATE_KEYS, useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useConnectorObservability } from "../../hooks/connectorObservability";
import { useCustomElementEditors } from "../../hooks/customElementEditors";
import { useCanSeePixel } from "../../hooks/pixelConfiguration";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useMutableRef } from "../../hooks/useMutableRef";
import {
  Button,
  EmojiIcon,
  Icon,
  IconButton,
  NotificationBullet,
  Tag,
  theme,
} from "../../icecube-ux";
import { useDeviceType } from "../../icecube-ux/hooks/provideDeviceType";
import { _ } from "../../languages/helper";
import MobileRoutes from "../../MobileRoutes";
import { ACQUISITION_BASE_URL } from "../../pages/acquisition/constants";
import { PolarPixelTabIcon } from "../../pages/connectors/popups/connect/polarPixel/PolarPixelTabIcon";
import { ReferralsFooter } from "../../pages/referrals/ReferralsFooter";
import { LIVE_DEMO_TENANT_ID } from "../../utils/demoConfig";
import { SubscriptionValidityDayCount } from "../../utils/subscriptionsUtils";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";
import ConnectorError from "../Messages/ConnectorError";
import DemoDataBanner from "../Messages/DemoDataBanner";
import DemoDataPopup from "../Messages/DemoDataPopup";
import FreeTrialCountDown from "../Messages/FreeTrialCountDown";
import LiveDemoBanner from "../Messages/LiveDemoBanner/LiveDemoBanner";
import { ShopifyConnectorHistoricalSyncing } from "../Messages/ShopifyConnectorHistoricalSyncing";
import { AddonPopupContainer } from "../Popups/AddonPopupContainer";
import FreeTrialEndedPopup from "../Popups/FreeTrialEndedPopup";
import MigrateToStripePopup from "../Popups/MigrateToStripePopup";
import RequireMeetingPopup from "../Popups/RequireMeetingPopup";
import SubscriptionEndedPopup from "../Popups/SubscriptionEndedPopup";
import SubscriptionUnpaidPopup from "../Popups/SubscriptionUnpaidPopup";
import { TenantListPopup } from "../Popups/TenantListPopup";

type FunctionChild = (containerRef: HTMLDivElement | null) => React.ReactNode;

interface MainLayoutProps {
  children?: React.ReactNode | FunctionChild;
  fullHeight?: boolean;
  isDataView?: boolean;
}

const EMOJI_SIZE = 16;
const TENANT_SELECTOR_SEARCH_PARAM = "accounts-selector";
const DISPLAY_HIDDEN_ITEMS_KEY = "polar-display-hidden-items";

const useNavItems = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const elementEditors = useCustomElementEditors();
  const [displayHiddenItems, setDisplayHiddenItems] = useLocalStorage(
    DISPLAY_HIDDEN_ITEMS_KEY,
    false,
  );
  const {
    subscription,
    tenant,
    getUserTenantState,
    hasPermission,
    isDemoData,
  } = useBootstrap();
  const { connectorsWithErrors, hasLongShopifyHistoricalSync } =
    useConnectorObservability();

  const canSeeInventoryPlanning = getUserTenantState(
    "showInventoryAlerts",
    false,
  );
  const { canSeePixelStatus, canSeePixelMetrics } = useCanSeePixel();

  const isFreePlan = subscription?.plan === SUBSCRIPTION_TYPES.FREE_PLAN;
  const isLimitedNavToActivations =
    tenant.states.limitedNavigationMode === "activation";

  const ITEMS: NavigationItemType[] = [
    ...(auth.isOutboundDemo || isDemoData
      ? [
          {
            key: "setup",
            label: _`Getting started`,
            icon: <EmojiIcon size={EMOJI_SIZE} name="Party" />,
            iconBackground: theme.colors.primary10,
            iconActiveBackground: theme.colors.primary10,
            selected: document.location.pathname === "/setup",
            target: "/setup",
            iconRight:
              document.location.pathname === "/setup" ||
              auth.isOutboundDemo ||
              isLimitedNavToActivations ? undefined : (
                <Tag color="warning">{_`Continue`}</Tag>
              ),
          },
        ]
      : []),
    {
      key: "connectors",
      label: _`Connectors`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Connectors" />,
      iconBackground: "#eef8ff",
      iconActiveBackground: "#e2f3ff",
      seperatorAfter: true,
      selected: document.location.toString().includes("/connectors"),
      target: "/connectors",
      iconRight:
        connectorsWithErrors.length > 0 || hasLongShopifyHistoricalSync ? (
          <NotificationBullet />
        ) : undefined,
    },
    {
      key: "keyIndicators",
      label: _`Key Indicators`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Favorite" />,
      iconBackground: theme.customColors.menuKeyIndicatorsBg,
      iconActiveBackground: theme.customColors.menuKeyIndicatorsBgHover,
      seperatorAfter: true,
      selected: document.location.pathname.startsWith("/keyIndicators/"),
      target: "/keyIndicators",
      anchors: [],
      plusButtonOptionItems: [
        {
          label: _`Create dashboard`,
          onClick: () => {
            navigate("/keyIndicators?create_dashboard");
          },
          disabled: !hasPermission("dashboard.edit"),
        },
        {
          label: _`Create section`,
          onClick: () => {
            navigate("/keyIndicators?create_section");
          },
          disabled: !hasPermission("key_indicator.open"),
        },
      ],
    },
    {
      key: "acquisition",
      label: _`Acquisition`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Acquisition" />,
      iconBackground: theme.customColors.menuAcquisitionBg,
      iconActiveBackground: theme.customColors.menuAcquisitionBgHover,
      selected: document.location.toString().includes(ACQUISITION_BASE_URL),
      target: `${ACQUISITION_BASE_URL}/performance`,
      anchors: [],
      links: [
        {
          target: `${ACQUISITION_BASE_URL}/performance`,
          label: _`Performance`,
        },
        {
          target: `${ACQUISITION_BASE_URL}/insights`,
          label: _`Campaign Insights`,
        },
        ...(canSeePixelMetrics
          ? [
              {
                target: `${ACQUISITION_BASE_URL}/journeys`,
                label: _`Journeys`,
              },
            ]
          : []),
        {
          target: `${ACQUISITION_BASE_URL}/other`,
          label: _`Other reports`,
        },
      ],
      iconRight: isFreePlan ? (
        <Icon name="Sparkle" />
      ) : canSeePixelStatus ? (
        <PolarPixelTabIcon />
      ) : undefined,
    },
    {
      key: "orders",
      label: _`Orders`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Money" />,
      iconBackground: theme.customColors.menuRetentionBg,
      iconActiveBackground: theme.customColors.menuRetentionBgHover,
      selected: document.location.toString().includes("/orders"),
      target: isFreePlan ? "/orders/freeTrial" : "/orders",
      anchors: isFreePlan
        ? undefined
        : [_`LTV summary`, _`Cohort tables`, _`Cohort evolution`],
      iconRight: isFreePlan ? <Icon name="Sparkle" /> : undefined,
    },
    {
      key: "creative-pulse",
      label: _`Creative Studio`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="CreativeStudio" />,
      iconBackground: theme.customColors.menuCreativeStudioBg,
      iconActiveBackground: theme.customColors.menuCreativeStudioBgHover,
      selected: document.location.toString().includes("/creative-studio"),
      target: "/creative-studio",
      anchors: [_`Overall performance`, _`Performance over time`],
    },
    {
      key: "retention",
      label: _`Retention`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Retention" />,
      iconBackground: theme.customColors.menuRetentionBg,
      iconActiveBackground: theme.customColors.menuRetentionBgHover,
      selected: document.location.toString().includes("/retention"),
      target: isFreePlan ? "/retention/freeTrial" : "/retention",
      anchors: isFreePlan
        ? undefined
        : [_`LTV summary`, _`Cohort tables`, _`Cohort evolution`],
      iconRight: isFreePlan ? <Icon name="Sparkle" /> : undefined,
    },
    {
      key: "products",
      label: _`Products`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Products" />,
      iconBackground: theme.customColors.menuProductsBg,
      iconActiveBackground: theme.customColors.menuProductsBgHover,
      selected: document.location.toString().includes("/products"),
      seperatorAfter: false,
      target: isFreePlan ? "/products/freeTrial" : "/products",
      anchors: isFreePlan
        ? undefined
        : [
            _`Product sales`,
            _`Product sales by date`,
            _`Product returns`,
            _`Current inventory`,
            _`LTV by first order product`,
            _`Product bundles`,
          ],
      iconRight: isFreePlan ? <Icon name="Sparkle" /> : undefined,
    },
    ...(canSeeInventoryPlanning
      ? [
          {
            key: "inventory_planning",
            label: _`Inventory Planning`,
            icon: <EmojiIcon size={EMOJI_SIZE} name="Inventory" />,
            iconBackground: theme.customColors.menuInventoryBg,
            iconActiveBackground: theme.customColors.menuInventoryBgHover,
            selected: document.location
              .toString()
              .includes("/inventory-planning"),
            seperatorAfter: false,
            target: "/inventory-planning",
            isBeta: true,
            iconRight: isFreePlan ? <Icon name="Sparkle" /> : undefined,
            anchors: [_`Inventory health`, _`Sales evolution forecast`],
          },
        ]
      : []),
    {
      key: "subscriptions",
      label: _`Subscriptions`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Subscriptions" />,
      iconBackground: theme.customColors.menuSubscriptionsBg,
      iconActiveBackground: theme.customColors.menuSubscriptionsBgHover,
      selected: document.location.toString().includes("/subscriptions"),
      seperatorAfter: false,
      target: isFreePlan ? "/subscriptions/freeTrial" : "/subscriptions",
      anchors: isFreePlan
        ? undefined
        : [
            _`New vs. recurring sales`,
            _`New vs. churned sales`,
            _`Churned subscriptions`,
            _`Churned customers`,
          ],
      iconRight: isFreePlan ? <Icon name="Sparkle" /> : undefined,
    },
    {
      key: "engagement",
      label: _`Engagement`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Engagement" />,
      iconBackground: theme.customColors.menuEngagementBg,
      iconActiveBackground: theme.customColors.menuEngagementBgHover,
      seperatorAfter: false,
      selected: document.location.toString().includes("/engagement"),
      target: isFreePlan ? "/engagement/freeTrial" : "/engagement",
      anchors: isFreePlan
        ? undefined
        : [
            _`New subscribers`,
            _`Campaigns summary`,
            _`Campaigns by date`,
            _`Flows summary`,
            _`Flows by date`,
            _`Expected next order`,
            _`Revenue attribution`,
          ],
      iconRight: isFreePlan ? <Icon name="Sparkle" /> : undefined,
    },
    {
      key: "activate",
      label: _`Activate`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Audience" />,
      iconBackground: theme.customColors.menuAudienceBg,
      iconActiveBackground: theme.customColors.menuAudienceBgHover,
      seperatorAfter: true,
      selected: document.location.toString().includes("/activate"),
      target: "/activate",
    },
    ...(isFreePlan
      ? []
      : [
          {
            key: "askPolar",
            label: _`Ask Polar`,
            icon: <EmojiIcon size={EMOJI_SIZE} name="AskPolar" />,
            iconBackground: theme.customColors.menuAskPolarBg,
            iconActiveBackground: theme.customColors.menuAskPolarBgHover,
            selected: document.location.toString().includes("/ask"),
            target: "/ask",
          },
        ]),
    {
      key: "customReport",
      label: _`Custom Reports`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Custom" />,
      iconBackground: theme.customColors.menuCustomReportBg,
      iconActiveBackground: theme.customColors.menuCustomReportBgHover,
      seperatorAfter: true,
      selected: document.location.toString().includes("/custom"),
      target: (() => {
        if (isFreePlan) {
          return "/custom/freeTrial";
        }
        return auth.isOutboundDemo ? "/custom/generate" : "/custom/list";
      })(),
      plusButtonOptionItems: isFreePlan
        ? undefined
        : [
            {
              label: _`Create report`,
              onClick: () => navigate("/custom/create"),
              disabled: !hasPermission("report.open"),
            },
            {
              label: _`Create dashboard`,
              onClick: () => navigate("/custom/list?create_dashboard"),
              disabled: !hasPermission("dashboard.edit"),
            },
            {
              label: _`Create metric`,
              rightComponent: hasPermission("metric.edit") ? (
                <Button
                  color="link-secondary"
                  size="tiny"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    elementEditors.openMetricEditor();
                    return false;
                  }}
                >
                  {_`See all`}
                </Button>
              ) : undefined,
              onClick: () => {
                elementEditors.createMetric();
                trackEvent(TRACKING_EVENTS.LEFT_MENU_METRIC_CREATE_CLICK, {
                  tenant_id: tenant?.id || "N/A",
                });
              },
              disabled: !hasPermission("metric.edit"),
            },
            {
              label: _`Create dimension`,
              rightComponent: hasPermission("dashboard.edit") ? (
                <Button
                  color="link-secondary"
                  size="tiny"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    elementEditors.showDimensionList();
                    return false;
                  }}
                >
                  {_`See all`}
                </Button>
              ) : undefined,
              onClick: () => {
                elementEditors.createDimension();
                trackEvent(TRACKING_EVENTS.LEFT_MENU_DIMENSION_CREATE_CLICK, {
                  tenant_id: tenant?.id || "N/A",
                });
              },
              disabled: !hasPermission("dashboard.edit"),
            },
          ],
      iconRight: isFreePlan ? <Icon name="Sparkle" /> : undefined,
    },
    {
      key: "alerts",
      label: _`Alerts`,
      icon: <EmojiIcon size={EMOJI_SIZE} name="Alerts" />,
      iconBackground: theme.customColors.menuAlertsBg,
      iconActiveBackground: theme.customColors.menuAlertsBgHover,
      seperatorAfter: false,
      selected: document.location.toString().includes("/alerts"),
      target: isFreePlan ? "/alerts/freeTrial" : "/alerts",
      iconRight: isFreePlan ? <Icon name="Sparkle" /> : undefined,
    },
  ];

  const getItems = () => {
    if (auth.isAgencyConnector()) {
      return ITEMS.filter((item) => item.key === "connectors");
    }
    if (isLimitedNavToActivations) {
      return [
        {
          key: "activation",
          label: _`Activation`,
          icon: <EmojiIcon size={EMOJI_SIZE} name="Audience" />,
          iconBackground: "#eef8ff",
          iconActiveBackground: "#e2f3ff",
          seperatorAfter: true,
          selected: document.location.toString().includes("/activations"),
          target: "/activations",
          iconRight: <Tag color="warning">{_`Start here`}</Tag>,
        },
        {
          key: "explore",
          label: _`Explore other parts of Polar`,
          seperatorAfter: true,
          selected: false,
          iconRight: (
            <IconButton
              name={displayHiddenItems ? "ArrowDown" : "ArrowUp"}
              onClick={() => setDisplayHiddenItems((prev) => !prev)}
            />
          ),
        },
        ...(displayHiddenItems
          ? ITEMS.filter((v) => v.key !== "activate")
          : []),
      ];
    }

    return ITEMS;
  };

  return {
    items: getItems(),
  };
};

export default function MainLayout({
  children,
  fullHeight = false,
  isDataView = false,
}: MainLayoutProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [containerParent, containerParentRef] = useMutableRef<HTMLDivElement>();
  const [containerNode, containerRef] = useMutableRef<HTMLDivElement>();
  const navigate = useNavigate();
  const auth = useAuth();
  const { isMobile } = useDeviceType();
  const { items } = useNavItems();

  const { subscription, tenant, setupState } = useBootstrap();
  const {
    connectorsWithErrors,
    hasLongShopifyHistoricalSync,
    hasShopifyHistoricalSync,
  } = useConnectorObservability();
  const [skippedRequireMeetingAt, setSkippedRequireMeetingAt] =
    useLocalStorage<Date | null>("skippedRequireMeetingAt", null);

  const expiredSubscription = () =>
    subscription &&
    subscription.valid_until !== null &&
    moment(subscription.valid_until).isBefore(moment());

  const requireUpgrade = () =>
    tenant.states?.[TENANT_STATE_KEYS.REQUIRE_UPGRADE] &&
    tenant.states?.[TENANT_STATE_KEYS.REQUIRE_UPGRADE] === true;

  const subscriptionUnpaid = () =>
    !!tenant.states?.[TENANT_STATE_KEYS.IS_SUBSCRIPTION_UNPAID];

  const needToMigrateToStripe = () =>
    !!tenant.states?.[TENANT_STATE_KEYS.NEED_MIGRATION_TO_STRIPE];

  const isRequireMeetingSkipped = () => {
    if (!skippedRequireMeetingAt) {
      return false;
    }
    return moment(skippedRequireMeetingAt).isAfter(
      moment().subtract({ hours: 24 }),
    );
  };

  const requireMeeting = () =>
    subscription?.plan === 0 &&
    tenant.states?.[TENANT_STATE_KEYS.REQUIRE_MEETING] &&
    tenant.states?.[TENANT_STATE_KEYS.REQUIRE_MEETING] === true &&
    !isRequireMeetingSkipped();

  const shouldDisplayMessage = () =>
    subscription &&
    subscription.plan === 0 &&
    subscription.valid_until !== null;

  const hasMessage = shouldDisplayMessage();
  const dayCount = subscription
    ? SubscriptionValidityDayCount(subscription)
    : 30;

  const getAvatar = () => {
    if (
      auth?.isOutboundDemo &&
      auth?.outboundDemoData?.personalization?.logo !== ""
    ) {
      return auth?.outboundDemoData?.personalization?.logo || FALLBACK_AVATAR;
    }
    const avatar = tenant?.settings?.avatar || "";
    if (avatar === "") {
      return FALLBACK_AVATAR;
    }
    return `${process.env.REACT_APP_CDN_HOST}/${avatar}`;
  };

  const getBanner = () => {
    if (!setupState.isFinished && isDataView) {
      return <DemoDataBanner />;
    } else if (connectorsWithErrors.length > 0 && !auth.isVisitor()) {
      return <ConnectorError />;
    } else if (hasShopifyHistoricalSync && !auth.isVisitor()) {
      return (
        <ShopifyConnectorHistoricalSyncing
          displayActionButton={hasLongShopifyHistoricalSync}
        />
      );
    } else if (tenant.id === LIVE_DEMO_TENANT_ID) {
      return <LiveDemoBanner />;
    } else if (
      hasMessage &&
      subscription &&
      subscription.valid_until !== null
    ) {
      return (
        <FreeTrialCountDown
          dayCount={SubscriptionValidityDayCount(subscription)}
        />
      );
    }
  };
  const setupStateCode = setupState.code;
  return (
    <>
      {!isMobile && (
        <div className="h100">
          {isDataView &&
            setupStateCode !== SETUP_STATES.READY_DATA_DONE_CALL && (
              <DemoDataPopup />
            )}
          <Layout
            ref={containerRef}
            containerParentRef={containerParentRef}
            fullHeight={fullHeight}
            items={items}
            logo={getAvatar()}
            email={auth.user?.email || ""}
            username={auth.user?.name || ""}
            company={
              auth.outboundDemoData?.personalization?.name ||
              tenant?.companyName ||
              ""
            }
            onClickReferrals={() => {
              trackEvent(TRACKING_EVENTS.REFERRALS_LINK_CLICKED, {
                tenant_id: tenant?.id || "N/A",
              });
              navigate("/referrals");
            }}
            onClickOpenTenantList={() => {
              trackEvent(TRACKING_EVENTS.OPEN_TENANT_LIST_CLICKED, {
                userId: auth.user?.id,
                tenant_id: tenant?.id || "N/A",
              });
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set(TENANT_SELECTOR_SEARCH_PARAM, "");
              setSearchParams(newSearchParams);
            }}
            onClickUserBoxSettings={() => {
              trackEvent(TRACKING_EVENTS.SETTINGS_LINK_CLICKED, {
                tenant_id: tenant?.id || "N/A",
              });
              navigate("/settings");
            }}
            onClickUserBoxHelp={() => {
              trackEvent(TRACKING_EVENTS.HELP_LINK_CLICKED, {
                tenant_id: tenant?.id || "N/A",
              });
              window.open("https://intercom.help/polar-app/en/", "_blank");
            }}
            onClickUserBoxLogout={() => {
              trackEvent(TRACKING_EVENTS.LOGOUT_LINK_CLICKED, {
                tenant_id: tenant?.id || "N/A",
              });
              auth.logout(resetViewEditor);
              navigate("/");
            }}
            banner={getBanner()}
          >
            {searchParams.has(TENANT_SELECTOR_SEARCH_PARAM) && (
              <TenantListPopup
                onClose={() => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.delete(TENANT_SELECTOR_SEARCH_PARAM);
                  setSearchParams(newSearchParams);
                }}
              />
            )}
            {hasMessage && dayCount < 0 && <FreeTrialEndedPopup />}
            {needToMigrateToStripe() && <MigrateToStripePopup />}
            {subscriptionUnpaid() && <SubscriptionUnpaidPopup />}
            {(expiredSubscription() || requireUpgrade()) && (
              <SubscriptionEndedPopup />
            )}
            {requireMeeting() && (
              <RequireMeetingPopup
                setSkippedRequireMeetingAt={setSkippedRequireMeetingAt}
              />
            )}
            {children &&
              typeof children === "function" &&
              children(containerNode)}
            {children && typeof children !== "function" && children}
            {hasMessage && <div style={{ height: "23px" }}></div>}
            {!fullHeight && (
              <ReferralsFooter
                parentContainer={containerParent}
                childrenContainer={containerNode}
              />
            )}
            <AddonPopupContainer />
          </Layout>
        </div>
      )}
      {isMobile && (
        <div className="h100">
          <MobileRoutes />
        </div>
      )}
    </>
  );
}
