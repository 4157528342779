import moment, { Moment } from "moment";

import { ObjectValues } from "../common/types";
import { noTz } from "../icecube-ux/dateUtils";
import { _ } from "../languages/helper";

import { pluralize } from "./languageUtils";

export const deriveUserTimezone = () => {
  return (
    Intl?.DateTimeFormat?.().resolvedOptions?.()?.timeZone ?? "Europe/Paris"
  );
};

export const getRelativeTimeSince = (daysSince: number) => {
  const [value, unit] = moment
    .duration(daysSince, "days")
    .humanize()
    .split(" ");

  return `${value} ${pluralize(
    value === "a" ? 1 : Number(value),
    unit.replace(/s$/, ""),
  )} ${_`ago`}`;
};

export const getValidTimezoneOptions = () => {
  const timezones = Intl?.supportedValuesOf
    ? Intl.supportedValuesOf("timeZone")
    : [deriveUserTimezone()];

  return timezones.map((tz) => ({
    label: tz.replaceAll("_", " "),
    value: tz,
  }));
};

export type Frequency =
  | "all time"
  | "daily"
  | "weekly"
  | "monthly"
  | "quarterly"
  | "yearly";

enum BfcmPeriods {
  BFCM_2020 = "bfcm2020",
  BFCM_2021 = "bfcm2021",
  BFCM_2022 = "bfcm2022",
  BFCM_2023 = "bfcm2023",
  BFCM_2024 = "bfcm2024",
}

export type Granularity = "day" | "week" | "month" | "quarter" | "year";

export type ComparePeriod =
  | "shiftedPeriod"
  | "previousPeriod"
  | "previousYear"
  | "range";

export type SmartDateComparePeriod = ComparePeriod | BfcmPeriods;

interface RelativeRange {
  amount: number;
  type: Granularity;
}

export const DATE_GRANULARITY: { [key: string]: Granularity } = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
};

export const DATE_GRANULARITY_OPTIONS = [
  { label: _`By day`, value: DATE_GRANULARITY.DAY },
  { label: _`By week`, value: DATE_GRANULARITY.WEEK },
  { label: _`By month`, value: DATE_GRANULARITY.MONTH },
  { label: _`By quarter`, value: DATE_GRANULARITY.QUARTER },
  { label: _`By year`, value: DATE_GRANULARITY.YEAR },
];

export const PREFILTERS_VALUES = {
  TODAY: "today",
  THIS_WEEK: "thisWeek",
  THIS_MONTH: "thisMonth",
  THIS_YEAR: "thisYear",
  YESTERDAY: "yesterday",
  LAST_WEEK: "lastWeek",
  LAST_MONTH: "lastMonth",
  LAST_YEAR: "lastYear",
  BFCM_2024: BfcmPeriods.BFCM_2024,
  BFCM_2023: BfcmPeriods.BFCM_2023,
  BFCM_2022: BfcmPeriods.BFCM_2022,
  BFCM_2021: BfcmPeriods.BFCM_2021,
  BFCM_2020: BfcmPeriods.BFCM_2020,
  ALL: "all",
} as const;

export type PrefilterType = ObjectValues<typeof PREFILTERS_VALUES>;

export const FILTER_TYPES = {
  PREDEFINED: "predefined",
  RELATIVE: "relative",
  RANGE: "range",
} as const;
export type DatePickerTabType = ObjectValues<typeof FILTER_TYPES>;
export const LONG_MONTH_DATE_FORMAT = "MMMM Do YYYY";

export const PREFILTERS_COMPARE_VALUES: {
  [key: string]: SmartDateComparePeriod;
} = {
  SHIFTED_PERIOD: "shiftedPeriod",
  PREVIOUS_PERIOD: "previousPeriod",
  PREVIOUS_YEAR: "previousYear",
  BFCM_2020: BfcmPeriods.BFCM_2020,
  BFCM_2021: BfcmPeriods.BFCM_2021,
  BFCM_2022: BfcmPeriods.BFCM_2022,
  BFCM_2023: BfcmPeriods.BFCM_2023,
  BFCM_2024: BfcmPeriods.BFCM_2024,
};

export const bfcmPrefilters = [
  {
    value: BfcmPeriods.BFCM_2024,
    get label() {
      return _`BFCM` + 2024;
    },
    isDisabledForDemo: true,
  },
  {
    value: BfcmPeriods.BFCM_2023,
    get label() {
      return _`BFCM` + 2023;
    },
  },
  {
    value: BfcmPeriods.BFCM_2022,
    get label() {
      return _`BFCM` + 2022;
    },
  },
  {
    value: BfcmPeriods.BFCM_2021,
    get label() {
      return _`BFCM` + 2021;
    },
    isDisabledForDemo: true,
  },
];

export const PREFILTERS: {
  label: string;
  value: PrefilterType;
  isDisabledForDemo?: boolean;
}[] = [
  {
    get label() {
      return _`Today`;
    },
    value: PREFILTERS_VALUES.TODAY,
    isDisabledForDemo: true,
  },
  {
    get label() {
      return _`This week`;
    },
    value: PREFILTERS_VALUES.THIS_WEEK,
    isDisabledForDemo: true,
  },
  {
    get label() {
      return _`This month`;
    },
    value: PREFILTERS_VALUES.THIS_MONTH,
    isDisabledForDemo: true,
  },
  {
    get label() {
      return _`This year`;
    },
    value: PREFILTERS_VALUES.THIS_YEAR,
    isDisabledForDemo: true,
  },
  {
    get label() {
      return _`Yesterday`;
    },
    value: PREFILTERS_VALUES.YESTERDAY,
    isDisabledForDemo: true,
  },
  {
    get label() {
      return _`Last week`;
    },
    value: PREFILTERS_VALUES.LAST_WEEK,
    isDisabledForDemo: true,
  },
  {
    get label() {
      return _`Last month`;
    },
    value: PREFILTERS_VALUES.LAST_MONTH,
    isDisabledForDemo: true,
  },
  {
    get label() {
      return _`Last year`;
    },
    value: PREFILTERS_VALUES.LAST_YEAR,
  },
  ...bfcmPrefilters,
  {
    get label() {
      return _`All time`;
    },
    value: PREFILTERS_VALUES.ALL,
  },
];

export const PREFILTERS_COMPARE = [
  {
    get label() {
      return _`Previous period`;
    },
    value: PREFILTERS_COMPARE_VALUES.PREVIOUS_PERIOD,
  },
  {
    get label() {
      return _`Previous year`;
    },
    value: PREFILTERS_COMPARE_VALUES.PREVIOUS_YEAR,
    isDisabledForDemo: true,
  },
  {
    get label() {
      return _`Shifted period`;
    },
    value: PREFILTERS_COMPARE_VALUES.SHIFTED_PERIOD,
    isDisabledForDemo: false,
  },
];

export interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
}

export const shiftDate = (
  date: moment.Moment,
  originDate: moment.Moment,
  compareDate: moment.Moment,
) => {
  const spread = originDate.diff(compareDate, "days");
  return date.subtract(spread, "days").clone();
};

export const applyDateBoundaries = (
  date: Moment,
  min?: Moment,
  max?: Moment,
) => {
  if (min && date.isBefore(min)) {
    return moment(min);
  }
  if (max && date.isAfter(max)) {
    return moment(max);
  }
  return date;
};

export const demoDateBoundaries = {
  start: moment("2022").startOf("year"),
  end: moment("2023").endOf("year"),
};

type IsComparePrefilterInRange = {
  range: DateRange;
  dateBoundaries: DateRange;
  prefilter: SmartDateComparePeriod;
};
export const isComparePrefilterInRange = ({
  range,
  dateBoundaries,
  prefilter,
}: IsComparePrefilterInRange) => {
  const filterRange = getCompareDateRange(range, prefilter);
  return (
    dateBoundaries.start <= filterRange.start &&
    filterRange.end <= dateBoundaries.end
  );
};

export const getDemoDateDefaults = (
  page?: string,
  isCompareRange?: boolean,
) => {
  if (isCompareRange) {
    return {
      start: moment("202212").startOf("month"),
      end: moment("202212").endOf("month"),
    };
  }

  if (page === "retention") {
    return {
      start: moment("2023").startOf("year"),
      end: moment("2023").endOf("year"),
    };
  }

  return {
    start: moment("202312").startOf("month"),
    end: moment("202312").endOf("month"),
  };
};

/**
 * Returns the intersection between the allowed (boundaries) and the actual
 * date range.
 *
 * If they do not intersect or are otherwise malformed, the entire allowed date
 * range is returned.
 */
export const clampDateRange = (
  allowed: DateRange,
  actual: DateRange,
): DateRange => {
  if (
    moment(actual.start).isAfter(allowed.end) ||
    moment(actual.end).isBefore(allowed.start) ||
    moment(actual.start).isSame(actual.end)
  ) {
    return {
      start: allowed.start.clone(),
      end: allowed.end.clone(),
    };
  }

  return {
    start: moment.max([allowed.start, actual.start]).clone(),
    end: moment.min([allowed.end, actual.end]).clone(),
  };
};

export const getDateRangeFromRelativeRange = (
  range: RelativeRange,
  includeToday: boolean,
) => {
  return {
    start: moment().subtract(range.amount, range.type).startOf("day"),
    end: includeToday
      ? moment().endOf("day")
      : moment().subtract(1, "day").endOf("day"),
  };
};

const getTodayDateRange = () => {
  return {
    start: moment().startOf("day"),
    end: moment().endOf("day"),
  };
};

const getThisWeekDateRange = (startOfWeek = 0, canIncludeToday = false) => {
  return {
    start: moment().startOf("week").add(startOfWeek, "day"),
    end: moment()
      .subtract(canIncludeToday ? 0 : 1, "day")
      .endOf("day"),
  };
};

const getThisMonthDateRange = (canIncludeToday = false) => {
  return {
    start: moment().startOf("month"),
    end: moment()
      .subtract(canIncludeToday ? 0 : 1, "day")
      .endOf("day"),
  };
};

const getThisYearDateRange = (canIncludeToday = false) => {
  return {
    start: moment().startOf("year"),
    end: moment()
      .subtract(canIncludeToday ? 0 : 1, "day")
      .endOf("day"),
  };
};

const getYesterdayDateRange = () => {
  return {
    start: moment().subtract(1, "day").startOf("day"),
    end: moment().subtract(1, "day").endOf("day"),
  };
};

const getLastWeekDateRange = (startOfWeek = 0) => {
  return {
    start: moment().subtract(1, "week").startOf("week").add(startOfWeek, "day"),
    end: moment().subtract(1, "week").endOf("week").add(startOfWeek, "day"),
  };
};

export const getLastMonthDateRange = () => {
  return {
    start: moment().subtract(1, "month").startOf("month"),
    end: moment().subtract(1, "month").endOf("month"),
  };
};

const getLastYearDateRange = () => {
  return {
    start: moment().subtract(1, "year").startOf("year"),
    end: moment().subtract(1, "year").endOf("year"),
  };
};

const getBFCM2024DateRange = (canIncludeToday = false) => {
  const start = moment("2024-11-29").startOf("day");
  const lastPossibleDate = moment()
    .subtract(canIncludeToday ? 0 : 1, "day")
    .endOf("day");
  return {
    start,
    end: moment.min(lastPossibleDate, moment("2024-12-02").endOf("day")),
  };
};
const getBFCM2023DateRange = () => {
  return {
    start: moment("2023-11-24").startOf("day"),
    end: moment("2023-11-27").endOf("day"),
  };
};
const getBFCM2022DateRange = () => {
  return {
    start: moment("2022-11-25").startOf("day"),
    end: moment("2022-11-28").endOf("day"),
  };
};
const getBFCM2021DateRange = () => {
  return {
    start: moment("2021-11-26").startOf("day"),
    end: moment("2021-11-29").endOf("day"),
  };
};
const getBFCM2020DateRange = () => {
  return {
    start: moment("2020-11-27").startOf("day"),
    end: moment("2020-11-30").endOf("day"),
  };
};

export const getDateRangeFromPeriod = (
  period: PrefilterType,
  startOfWeek = 0,
  canIncludeToday = false,
  minDate?: string,
) => {
  switch (period) {
    case PREFILTERS_VALUES.TODAY:
      return getTodayDateRange();
    case PREFILTERS_VALUES.THIS_WEEK:
      return getThisWeekDateRange(startOfWeek, canIncludeToday);
    case PREFILTERS_VALUES.THIS_MONTH:
      return getThisMonthDateRange(canIncludeToday);
    case PREFILTERS_VALUES.THIS_YEAR:
      return getThisYearDateRange(canIncludeToday);
    case PREFILTERS_VALUES.YESTERDAY:
      return getYesterdayDateRange();
    case PREFILTERS_VALUES.LAST_WEEK:
      return getLastWeekDateRange(startOfWeek);
    case PREFILTERS_VALUES.LAST_MONTH:
      return getLastMonthDateRange();
    case PREFILTERS_VALUES.LAST_YEAR:
      return getLastYearDateRange();
    case PREFILTERS_VALUES.BFCM_2024:
      return getBFCM2024DateRange(canIncludeToday);
    case PREFILTERS_VALUES.BFCM_2023:
      return getBFCM2023DateRange();
    case PREFILTERS_VALUES.BFCM_2022:
      return getBFCM2022DateRange();
    case PREFILTERS_VALUES.BFCM_2021:
      return getBFCM2021DateRange();
    case PREFILTERS_VALUES.BFCM_2020:
      return getBFCM2020DateRange();
  }

  const lowerDateRange = moment(minDate);

  return {
    start: lowerDateRange,
    end: moment()
      .subtract(canIncludeToday ? 0 : 1, "day")
      .endOf("day"),
  };
};

export const getDayOfWeekMatchingSkew = (
  dateA: moment.Moment,
  dateB: moment.Moment,
) => {
  const startWeekDayIndex = dateA.day();
  const newStartWeekDayIndex = dateB.day();
  const value =
    startWeekDayIndex > newStartWeekDayIndex
      ? newStartWeekDayIndex + 7 - startWeekDayIndex
      : newStartWeekDayIndex - startWeekDayIndex;

  if (value > 3) {
    return value - 7;
  }

  return value;
};

const getPreviousYearRangeMatchingDayOfWeek = (prevRange: DateRange) => {
  const newRange = {
    start: prevRange.start
      .clone()
      .startOf("day")
      .year(prevRange.start.year() - 1),
    end: prevRange.end
      .clone()
      .startOf("day")
      .year(prevRange.end.year() - 1),
  };

  return applyDayOfWeekMatching(prevRange, newRange);
};

const applyDayOfWeekMatching = (prevRange: DateRange, newRange: DateRange) => {
  const skew = getDayOfWeekMatchingSkew(prevRange.start, newRange.start);
  newRange.start.subtract(skew, "days");
  newRange.end.subtract(skew, "days");
  return newRange;
};

export const getCompareDateRange = (
  dateRange: DateRange,
  type: SmartDateComparePeriod,
  weekStart: number = 0,
  compareRange?: DateRange,
  matchingDaysOfWeek?: boolean,
  granularity?: Granularity | "none",
) => {
  let newRange: DateRange;
  switch (type) {
    case PREFILTERS_COMPARE_VALUES.SHIFTED_PERIOD:
      return {
        start: dateRange.start
          .clone()
          .subtract(
            1,
            !granularity || granularity === "none" ? "day" : granularity,
          ),
        end: dateRange.end
          .clone()
          .subtract(
            1,
            !granularity || granularity === "none" ? "day" : granularity,
          ),
      };
    case PREFILTERS_COMPARE_VALUES.PREVIOUS_YEAR:
      if (matchingDaysOfWeek) {
        return getPreviousYearRangeMatchingDayOfWeek(dateRange);
      }
      newRange = {
        start: dateRange.start.clone().subtract(1, "year"),
        end: dateRange.end.clone().subtract(1, "year"),
      };
      return newRange;
    case PREFILTERS_COMPARE_VALUES.BFCM_2020:
      return getBFCM2020DateRange();
    case PREFILTERS_COMPARE_VALUES.BFCM_2021:
      return getBFCM2021DateRange();
    case PREFILTERS_COMPARE_VALUES.BFCM_2022:
      return getBFCM2022DateRange();
    case PREFILTERS_COMPARE_VALUES.BFCM_2023:
      return getBFCM2023DateRange();
    case PREFILTERS_COMPARE_VALUES.BFCM_2024:
      return getBFCM2024DateRange();
    case PREFILTERS_COMPARE_VALUES.PREVIOUS_PERIOD: {
      let granularityToUse: Granularity = "day";
      if (
        granularity !== "day" &&
        granularity !== "none" &&
        granularity !== undefined
      ) {
        granularityToUse = granularity;
      }

      const diff = Math.ceil(
        Math.abs(
          dateRange.start
            .clone()
            .add(granularityToUse === "week" ? weekStart : 0, "day")
            .startOf("day")
            .diff(
              dateRange.end
                .clone()
                .add(granularityToUse === "week" ? weekStart : 0, "day")
                .endOf("day"),
              granularityToUse,
              true,
            ),
        ),
      );
      newRange = {
        start: dateRange.start.clone().subtract(diff, granularityToUse),
        end: dateRange.end.clone().subtract(diff, granularityToUse),
      };

      if (matchingDaysOfWeek) {
        applyDayOfWeekMatching(dateRange, newRange);
      }
      return newRange;
    }
    case "range": {
      const dayDiff = moment(dateRange.end.startOf("day")).diff(
        dateRange.start.startOf("day"),
        "days",
      );
      if (compareRange) {
        return {
          start: compareRange.start.clone(),
          end: moment(compareRange.start.clone()).add(dayDiff, "day"),
        };
      }
      return {
        start: dateRange.start.clone(),
        end: dateRange.end.clone(),
      };
    }
    default:
      return compareRange || dateRange;
  }
};

export const dateRangeToServiceRange = (range: DateRange) => {
  return {
    start: range.start.format("YYYY-MM-DD"),
    end: range.end.format("YYYY-MM-DD"),
  };
};

export const createUserMoment = (weekStart: string) => (momentArgs: Moment) => {
  const globalLocale = moment.locale();
  moment.defineLocale("user-settings", {
    week: {
      dow: weekStart === "sunday" ? 0 : 1,
    },
  });
  moment.locale(globalLocale);
  return moment(momentArgs).locale("user-settings");
};

export const enumerateDaysBetweenDates = (
  startDate: moment.Moment,
  endDate: moment.Moment,
) => {
  let _startDate = startDate.clone();
  const dates: moment.Moment[] = [];
  while (moment(_startDate) <= moment(endDate)) {
    dates.push(_startDate);
    _startDate = moment(_startDate).add(1, "days");
  }
  return dates;
};

export const getDatesByGranularity = (
  granularity: Granularity | "none",
  range: DateRange,
  weekStart: string,
): string[] => {
  const dates: string[] = [];
  if (granularity === "none") {
    return dates;
  }

  const userMoment = createUserMoment(weekStart);
  const rangeStart = userMoment(range.start);
  const rangeEnd = userMoment(range.end);

  const currDate = rangeStart.clone().startOf(`${granularity}s`);
  const lastDate = rangeEnd.clone().endOf(`${granularity}s`);

  while (currDate.isBefore(lastDate)) {
    const newDate = currDate.clone();
    newDate.add(newDate.utcOffset(), "minutes");
    dates.push(newDate.toDate().toISOString());
    currDate.add(1, `${granularity}s`);
  }

  return dates;
};

export const getDayCountInRangeType = (rangeType: Frequency) => {
  const rangeDayCount: { [key in Frequency]: number } = {
    "all time": 1,
    daily: 1,
    weekly: 7,
    monthly: 30,
    quarterly: 90,
    yearly: 365,
  };
  return rangeDayCount[rangeType];
};

export const getDayCountInGranularity = (granularity: Granularity): number => {
  const rangeDayCount: { [key in Granularity]: number } = {
    day: 1,
    week: 7,
    month: 30,
    quarter: 90,
    year: 365,
  };
  return rangeDayCount[granularity];
};

const granularityFormats: { [key in Granularity]: string } = {
  day: "MMM D, YYYY",
  week: "[week of] MMM D, YYYY",
  month: "MMM YYYY",
  quarter: "[Q]Q YYYY",
  year: "YYYY",
};

export const getDateFormatByGranularity = (
  granularity?: Granularity | "none",
): string => {
  if (!granularity || granularity === "none") {
    return "MMM D, YYYY";
  }
  return granularityFormats[granularity];
};

export const dateFormatter = (value: string | number | null) =>
  moment(value).format("MMM D, YYYY");

export const dateDefaultFormatter = (value: string | number | null) => ({
  element: dateFormatter(value),
  style: {},
  className: "",
  headerClassName: "",
});

export const formatDatetimeWithTimezone = (value: string) => {
  return moment(value).format("MMM D, YYYY HH:mm");
};
const TOO_MANY_DATAPOINTS_WARNING_LIMIT = 200;

export const shouldWarnTooManyDatapoints = (
  range: DateRange,
  granularity: Granularity | "none",
) => {
  const potentialDataPoints =
    granularity === "none" ? 1 : range.end.diff(range.start, granularity);
  return potentialDataPoints > TOO_MANY_DATAPOINTS_WARNING_LIMIT;
};
export const getGranularityFromRange = (range: DateRange): Granularity => {
  const dayDifference = range.end.diff(range.start, "days");
  if (dayDifference <= 30) {
    return DATE_GRANULARITY.DAY;
  } else if (dayDifference <= 30 * 5) {
    return DATE_GRANULARITY.WEEK;
  } else if (dayDifference <= 365 * 2) {
    return DATE_GRANULARITY.MONTH;
  } else {
    return DATE_GRANULARITY.YEAR;
  }
};

export const getTooltipDateFormatter = (
  granularity: Granularity | "none",
  useNoTz: boolean = true,
) => {
  let dateFormat = LONG_MONTH_DATE_FORMAT;
  if (granularity && granularity !== "day" && granularity !== "none") {
    dateFormat = getDateFormatByGranularity(granularity);
  }

  return (value: string) =>
    moment(value).isValid()
      ? useNoTz
        ? noTz(value).format(dateFormat)
        : moment(value).utc().format(dateFormat)
      : value;
};

export const findClosestDate = (
  targetDate: string,
  dates: string[],
): string | null => {
  if (!dates.length) return null;

  const sortedDates = dates
    .slice()
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const firstDate = sortedDates[0];
  const lastDate = sortedDates[sortedDates.length - 1];

  const targetDateTime = new Date(targetDate).getTime();
  const firstDateTime = new Date(firstDate).getTime();
  const lastDateTime = new Date(lastDate).getTime();

  if (targetDateTime < firstDateTime || targetDateTime > lastDateTime) {
    return null;
  }

  return sortedDates.reduce((closest, current) => {
    const currentDiff = Math.abs(targetDateTime - new Date(current).getTime());
    const closestDiff = Math.abs(targetDateTime - new Date(closest).getTime());
    return currentDiff < closestDiff ? current : closest;
  });
};
