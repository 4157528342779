import styled from "styled-components";

import { FALLBACK_AVATAR } from "../../../common/types/users-service";
import { useAuth } from "../../../hooks/auth/auth";
import { Flex, Icon, theme } from "../../../icecube-ux";

const StyledRoundedLogo = styled.div<{ path: string; cover?: boolean }>`
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 8px;
  width: 53px;
  height: 53px;
  overflow: hidden;
  background: ${theme.colors.borderLight} url(${({ path }) => path}) no-repeat
    center center;
  background-size: ${({ cover }) => (cover ? "cover" : "27px")};
`;

export default function LogoExchange() {
  const auth = useAuth();

  const avatar =
    auth?.outboundDemoData?.personalization?.logo || FALLBACK_AVATAR;
  return (
    <Flex gap={8} alignItems="center">
      <StyledRoundedLogo path={avatar} cover />
      <Icon name="Exchange" size={24} color={theme.colors.primary80} />
      <StyledRoundedLogo path="/logo512.png" />
    </Flex>
  );
}
