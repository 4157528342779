import moment from "moment";
import { ReactNode } from "react";

import { Grid, Select, Text, Tooltip } from "../../icecube-ux";
import { TextFieldSize } from "../../icecube-ux/shared/baseInputFieldSizeStylesMap";
import { _ } from "../../languages/helper";
import {
  bfcmPrefilters,
  DateRange,
  FILTER_TYPES,
  Granularity,
  PREFILTERS_COMPARE,
  SmartDateComparePeriod,
} from "../../utils/dateUtils";
import {
  getDateRangeFromSelector,
  SmartFilterSelector,
} from "../../utils/filterUtils";

import { getSmartDatePickerDisplayValue } from "./selectorUtil";
import SmartDateFilterPicker from "./SmartDateFilterPicker";

import "./smart-date-filter.css";

interface SmartDateFilterProps {
  onApply?: (
    compareRange: DateRange,
    selector: SmartFilterSelector,
    comparePeriod: SmartDateComparePeriod,
  ) => void;
  allowCompare?: boolean;
  withPopup?: boolean;
  size?: TextFieldSize;
  secondary?: boolean;
  matchingDayOfWeek: boolean;
  granularity: Granularity | "none";
  compareRange: DateRange;
  comparePeriod: SmartDateComparePeriod;
  selector: SmartFilterSelector;
  setCompareRange: (value: DateRange) => void;
  setComparePeriod: (value: SmartDateComparePeriod) => void;
  setSelector: (value: SmartFilterSelector) => void;
  setMatchingDayOfWeek: (value: boolean) => void;
  displayDatePicker: boolean;
  setDisplayDatePicker: (display: boolean) => void;
  disableDateRangeSelection?: boolean;
  disableGranularity: boolean;
  showDateRangeWarning?: boolean;
  footerInfoComponent?: ReactNode;
  forcedMaxDate?: moment.Moment;
  forcedMinDate?: moment.Moment;
  maySeeTodayData: boolean;
  weekStart: number;
}

export default function SmartDateFilter({
  onApply,
  allowCompare = false,
  withPopup = true,
  size,
  secondary,
  granularity,
  matchingDayOfWeek,
  compareRange,
  comparePeriod,
  selector,
  setCompareRange,
  setSelector,
  setComparePeriod,
  setMatchingDayOfWeek,
  displayDatePicker,
  setDisplayDatePicker,
  disableDateRangeSelection = false,
  disableGranularity,
  showDateRangeWarning = false,
  footerInfoComponent,
  forcedMaxDate,
  forcedMinDate,
  maySeeTodayData,
  weekStart,
}: SmartDateFilterProps) {
  const dateRange = getDateRangeFromSelector(
    weekStart,
    selector,
    maySeeTodayData,
    forcedMinDate?.toISOString(),
  );
  const dateRangeString = `${moment(dateRange.start).format(
    "MMM D, YY",
  )} - ${moment(dateRange.end).format("MMM D, YY")}`;
  const dateSelectorDisplayValue = getSmartDatePickerDisplayValue(
    selector,
    withPopup,
  );

  const compareRangeString = `${moment(compareRange.start).format(
    "MMM D, YY",
  )} - ${moment(compareRange.end).format("MMM D, YY")}`;
  const compareSelectorDisplayValue =
    (
      PREFILTERS_COMPARE.find((p) => p.value === comparePeriod) ||
      bfcmPrefilters.find((p) => p.value === comparePeriod)
    )?.label || compareRangeString;

  return (
    <>
      {displayDatePicker && (
        <SmartDateFilterPicker
          allowCompare={allowCompare}
          onClose={() => setDisplayDatePicker(false)}
          onApply={onApply}
          asPopup={withPopup}
          {...{
            matchingDayOfWeek,
            granularity: disableGranularity ? "none" : granularity,
            compareRange,
            comparePeriod,
            selector,
            setCompareRange,
            setSelector,
            setComparePeriod,
            setMatchingDayOfWeek,
            footerInfoComponent,
            forcedMaxDate,
            forcedMinDate,
          }}
        />
      )}

      {!withPopup && !displayDatePicker && (
        <>
          {selector.tab === FILTER_TYPES.PREDEFINED && (
            <Grid gridTemplateColumns="auto 1fr" gap={8} alignItems="center">
              <Text variant="BodyRegular">From</Text>
              <Select
                forcedDisplayedValue={dateSelectorDisplayValue}
                options={[]}
                selected={[]}
                notOpenable={true}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 200 }}
                block
              />
            </Grid>
          )}
          {selector.tab === FILTER_TYPES.RELATIVE && (
            <Grid
              gridTemplateColumns="auto 1fr 1fr"
              gap={8}
              alignItems="center"
            >
              <Text variant="BodyRegular">{_`Last|||plural`}</Text>
              <Select
                placeholder={selector.relativeSelection.count.toString()}
                options={[]}
                selected={[]}
                notOpenable={true}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 100 }}
              />
              <Select
                placeholder={
                  selector.relativeSelection.granularity +
                  (selector.relativeSelection.count === 1 ? "" : "s")
                }
                options={[]}
                selected={[]}
                notOpenable={false}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 100 }}
              />
            </Grid>
          )}
        </>
      )}
      {((!displayDatePicker && selector.tab === FILTER_TYPES.RANGE) ||
        withPopup) && (
        <Grid
          gridTemplateColumns={
            "auto 1fr" +
            (allowCompare && window.innerWidth > 1240 ? " auto" : "")
          }
          gap={8}
          alignItems="center"
        >
          <Tooltip content={dateRangeString}>
            <Select
              secondary={secondary}
              disabled={disableDateRangeSelection}
              leftIcon={showDateRangeWarning ? "DateRangeWarning" : "Calendar"}
              size={size}
              forcedDisplayedValue={dateSelectorDisplayValue}
              options={[]}
              selected={[]}
              notOpenable={true}
              onClick={() => setDisplayDatePicker(true)}
              dataAttributes={{ "data-cy": "header-filter-calendar" }}
              block
            />
          </Tooltip>
          {allowCompare && window.innerWidth > 1240 && (
            <>
              <Text variant="BodyRegular">{_`vs.`}</Text>
              <Tooltip content={compareRangeString}>
                <Select
                  leftIcon="Calendar"
                  placeholder={compareRangeString}
                  forcedDisplayedValue={compareSelectorDisplayValue}
                  options={[]}
                  selected={[]}
                  notOpenable={true}
                  onClick={() => setDisplayDatePicker(true)}
                />
              </Tooltip>
            </>
          )}
        </Grid>
      )}
    </>
  );
}
