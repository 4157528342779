export type SerieFocusState = "normal" | "dimmed" | "highlighted";

const serieDisplayTypes = [
  "single-serie-as-bars",
  "line",
  "tooltip-only",
] as const;
export type Serie<T = number | AreaDataPoint> = (
  | {
      /**
       * When a Serie is wrapped by a SerieCollection, the SerieCollection displayType
       * takes precedence.
       */
      displayType: Exclude<(typeof serieDisplayTypes)[number], "tooltip-only">;
      yAxisKey: string;
    }
  | {
      /**
       * Is never to be displayed on the chart, thus does not have an axis.
       */
      displayType: "tooltip-only";
    }
) & {
  name: string;
  color: string;
  colors?: string[];
  colorFunction?: (value: number, index: number) => string;
  legendStyle?: {
    border?: string;
    backgroundColor?: string;
  };
  focusState?: SerieFocusState;
  fillColor?: string;
  strokeWidth?: number;
  dashed?: boolean;
  withCircles?: boolean;
  withAreaRange?: boolean;
  values: T[];
  label?: string;
  valueFormatter?: (value: number) => string;
  zOrder?: number;
};

const stackedSerieDisplayTypes = [
  "stacked-bars",
  "stacked-lines",
  "bar-collection",
] as const;
export type SerieCollection<T = number> = {
  displayType: (typeof stackedSerieDisplayTypes)[number];
  segments: Serie<T>[];
  zOrder?: number;
};

export const isSerie = <T>(
  serie: Serie<T> | SerieCollection<T>,
): serie is Serie<T> => {
  return serieDisplayTypes.includes(serie.displayType as Serie["displayType"]);
};

export interface AreaDataPoint {
  y: number;
  yUpper: number;
  yLower: number;
}

export type DataPoint = string | number | AreaDataPoint;

export const isAreaDataPoint = (data: DataPoint): data is AreaDataPoint => {
  if (data === undefined) {
    return false;
  }
  return (data as AreaDataPoint).y !== undefined;
};

export const fixSeries = <TSerie extends Serie | SerieCollection>(
  series: TSerie[],
): TSerie[] => {
  return series.map((serie) => {
    if (isSerie(serie)) {
      return {
        ...serie,
        values: serie.values.map((v) => {
          if (isAreaDataPoint(v)) {
            return {
              y: isNaN(v.y) ? 0 : v.y,
              yUpper: isNaN(v.yUpper) ? 0 : v.yUpper,
              yLower: isNaN(v.yLower) ? 0 : v.yLower,
            };
          }
          return isNaN(v) ? 0 : v;
        }),
      };
    }

    return { ...serie, segments: fixSeries(serie.segments) };
  });
};

export const flattenSeries = <T>(
  series: (Serie<T> | SerieCollection<T>)[],
): Serie<T>[] => {
  return series.flatMap((serie) =>
    isSerie<T>(serie) ? serie : serie.segments,
  );
};

export const transposedValues = (serie: SerieCollection): number[][] => {
  const result: number[][] = [];

  serie.segments?.[0]?.values?.forEach((_, index) => {
    result.push(serie.segments.map((segment) => segment.values[index]));
  });

  return result;
};

export const hashSeries = <T>(
  series: (Serie<T> | SerieCollection<T>)[],
  fieldsToCauseUpdates: string[],
): string => {
  const result = series
    .flatMap((serie) => {
      if (isSerie<T>(serie)) {
        return JSON.stringify(serie, fieldsToCauseUpdates);
      }

      return `{ "${serie.displayType}": ${hashSeries(
        serie.segments,
        fieldsToCauseUpdates,
      )} }`;
    })
    .join(",");

  return `[ ${result} ]`;
};
