import { useNavigate } from "react-router-dom";

import {
  PixelStatus,
  usePixelConfiguration,
} from "../../../../../hooks/pixelConfiguration";
import { theme, Box, Text, Space } from "../../../../../icecube-ux";
import { _, _node } from "../../../../../languages/helper";
import { pluralize } from "../../../../../utils/languageUtils";
import {
  TRACKING_EVENTS,
  trackEvent,
} from "../../../../../utils/trackingUtils";
import { ACQUISITION_BASE_URL } from "../../../../acquisition/constants";

const getAttributionRateSummaryText = (attributionRateSummary: {
  numberOfAccountsWithoutUtms: number;
  numberOfStoresWithLowAttributionRate: number;
}) => {
  let message = "";
  if (attributionRateSummary.numberOfStoresWithLowAttributionRate > 0) {
    message += ` ${
      attributionRateSummary.numberOfStoresWithLowAttributionRate
    } ${pluralize(
      attributionRateSummary.numberOfStoresWithLowAttributionRate,
      "store has",
      ` stores have`,
    )} low attribution rates`;
  }
  if (
    attributionRateSummary.numberOfStoresWithLowAttributionRate > 0 &&
    attributionRateSummary.numberOfAccountsWithoutUtms > 0
  ) {
    message += " and ";
  } else if (attributionRateSummary.numberOfStoresWithLowAttributionRate > 0) {
    message += ". ";
  }
  if (attributionRateSummary.numberOfAccountsWithoutUtms > 0) {
    message += ` ${
      attributionRateSummary.numberOfAccountsWithoutUtms
    } ${pluralize(
      attributionRateSummary.numberOfAccountsWithoutUtms,
      "account has",
      ` accounts have`,
    )} undetected campaigns. `;
  }
  return message;
};

export const PixelConnectorSubtitle = () => {
  const navigate = useNavigate();
  const { attributionRateSummary, status: globalStatus } =
    usePixelConfiguration();
  return (
    <>
      <Space size={1} />
      <Text color={theme.colors.text80} variant="body10Regular">
        {_node`Live Pixel insights on the ${[
          "tab",
          <Text
            inline
            underline
            pointer
            color={theme.colors.primary100}
            variant="body10Regular"
            onClick={() => {
              trackEvent(TRACKING_EVENTS.PIXEL_SETUP_CTA_CLICKED, {
                buttonId: "Connectors Page - Subtitle - Acquisition Tab",
              });
              navigate(ACQUISITION_BASE_URL);
            }}
          >
            {" "}
            {_`Acquistion Tab`}
          </Text>,
        ]}`}
      </Text>

      {globalStatus === PixelStatus.AttributionRateProblems && (
        <Box>
          <Text inline variant="body10Medium">
            {_`Problem found`}:{" "}
          </Text>
          <Text inline color={theme.colors.text90} variant="body10Regular">
            {getAttributionRateSummaryText(attributionRateSummary)}
          </Text>
          {attributionRateSummary.numberOfStoresWithLowAttributionRate > 0 && (
            <Text
              underline
              inline
              pointer
              variant="body10Regular"
              color={theme.colors.warning110}
              onClick={() => {
                navigate("/connectors/polar-pixel/setup/shopify");
                trackEvent(TRACKING_EVENTS.PIXEL_SETUP_CTA_CLICKED, {
                  buttonId: "Connectors Page - Subtitle - Check status",
                });
              }}
            >
              {_`Check status`}
            </Text>
          )}{" "}
          {attributionRateSummary.numberOfAccountsWithoutUtms > 0 && (
            <Text
              underline
              inline
              pointer
              variant="body10Regular"
              color={theme.colors.warning110}
              onClick={() => {
                trackEvent(TRACKING_EVENTS.PIXEL_SETUP_CTA_CLICKED, {
                  buttonId: "Connectors Page - Subtitle - Setup UTMs",
                });
                navigate("/connectors/polar-pixel/utm");
              }}
            >
              {_`Setup UTMs`}
            </Text>
          )}
        </Box>
      )}
    </>
  );
};
