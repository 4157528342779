import { ConnectorIcon, ConnectorIconNames } from "../../../../icecube-ux";
import {
  ConnectorSetting,
  getConnectorList,
} from "../../../../integrations/integration";
import { IntegrationCategoryKey } from "../../../../utils/integrationUtils";
import ConnectorIconList from "../../components/ConnectorIconList";
import { FILTER_BADGE_COLORS, FILTER_BADGE_KEY } from "../consts";
import "./table-logo.css";

interface TableLogoProps {
  badgeIndex?: number;
  icon: ConnectorIconNames | ConnectorIconNames[];
  forceBackgroundColor?: string;
}

const connectorList = getConnectorList();
const iconSettings = Object.keys(connectorList).reduce(
  (prev: ConnectorSetting[], key: string) => [
    ...prev,
    ...connectorList[key as IntegrationCategoryKey],
  ],
  [],
);

export default function TableLogo({
  badgeIndex,
  icon,
  forceBackgroundColor,
}: TableLogoProps) {
  return (
    <div
      className="table-logo-container"
      style={{
        backgroundColor:
          forceBackgroundColor ||
          iconSettings.find((d) => d.image === icon)?.backgroundColor ||
          "#f1f0ff",
      }}
    >
      {badgeIndex !== undefined && badgeIndex > -1 && (
        <div
          className="dot"
          style={{
            background:
              FILTER_BADGE_COLORS[badgeIndex % FILTER_BADGE_COLORS.length],
          }}
        >
          {FILTER_BADGE_KEY[badgeIndex % FILTER_BADGE_KEY.length]}
        </div>
      )}
      {Array.isArray(icon) ? (
        <ConnectorIconList names={icon} size={"tiny"} />
      ) : (
        <ConnectorIcon name={icon} size={14} />
      )}
    </div>
  );
}