import classNames from "classnames";
import { useState } from "react";

import { _ } from "../../languages/helper";
import { IconButton } from "../IconButton";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { TextField } from "../TextField";

import { StyledPaginator, StyledPaginatorPageInfo } from "./Paginator.styled";
import { clampValue } from "./utils";

export type PaginatorProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    itemsPerPage: number;
    totalItemsCount: number;
    page: number;
    onChange: (value: number) => void;
    setItemsPerPage: (value: number) => void;
    disabled?: boolean;
    readOnly?: boolean;
  };

const MINIMUM_ITEMS_PER_PAGE = 3;
const MAXIMUM_ITEMS_PER_PAGE = 100;

export default function Paginator({
  itemsPerPage,
  totalItemsCount,
  page,
  onChange,
  setItemsPerPage,
  disabled,
  className,
  style,
  readOnly = false,
  ...interactionEvents
}: PaginatorProps) {
  const [editableValue, setEditableValue] = useState(itemsPerPage);

  const handleConfirmRowPerPage = () => {
    const currentMax = Math.min(totalItemsCount, MAXIMUM_ITEMS_PER_PAGE);
    const valueWithinRange = clampValue(
      MINIMUM_ITEMS_PER_PAGE,
      currentMax,
      editableValue,
    );
    setItemsPerPage(valueWithinRange);
    setEditableValue(valueWithinRange);
  };

  if (totalItemsCount < MINIMUM_ITEMS_PER_PAGE) {
    return null;
  }

  return (
    <StyledPaginator
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      {" "}
      {!readOnly ? (
        <TextField
          value={editableValue}
          type="number"
          size="small"
          onChange={(value) => {
            setEditableValue(parseInt(`${value}`));
          }}
          onBlur={handleConfirmRowPerPage}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleConfirmRowPerPage();
            }
          }}
          style={{ width: 60 }}
          disabled={disabled}
        />
      ) : (
        <></>
      )}
      <StyledPaginatorPageInfo>
        {_`Rows`} {page * itemsPerPage + 1}-
        {Math.min(page * itemsPerPage + itemsPerPage, totalItemsCount)}{" "}
        {_`of|||rows`} {totalItemsCount}
      </StyledPaginatorPageInfo>
      <IconButton
        disabled={page === 0 || disabled}
        onClick={() => onChange(page - 1)}
        name="CaretBack"
        color="default"
        size="large"
      />
      <IconButton
        disabled={
          page * itemsPerPage + itemsPerPage >= totalItemsCount || disabled
        }
        onClick={() => onChange(page + 1)}
        name="CaretNext"
        color="default"
        size="large"
        className="margin-left-regular"
      />
    </StyledPaginator>
  );
}
