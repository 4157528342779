import React, { useCallback, useState } from "react";

import { useResizeObserver } from "../hooks/useResizeObserver";

interface RatioWrapperProps {
  ratio?: number;
  maxHeight?: number;
  children: React.ReactElement;
  className?: string;
  onStyle?: boolean;
  adaptiveSize?: boolean;
}

export const RatioWrapper = ({
  ratio = 1 / 2.75,
  maxHeight = Infinity,
  children,
  className = "",
  onStyle = false,
  adaptiveSize = false,
}: RatioWrapperProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { width } = useResizeObserver(container);

  const containerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  let height: number;
  if (adaptiveSize) {
    height =
      container?.parentElement?.scrollHeight ||
      Math.min(width * ratio, maxHeight);
  } else {
    height = Math.min(width * ratio, maxHeight);
  }

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        display: "flex",
        justifyContent: "center",
        ...(adaptiveSize ? { width: "100%", height: "100%" } : null),
      }}
    >
      {React.cloneElement(children, {
        width,
        height,
        adaptiveSize,
        style: onStyle
          ? {
              width: `${width}px`,
              height: `${height}px`,
            }
          : undefined,
      })}
    </div>
  );
};
