import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { _ } from "../languages/helper";
import {
  createSchedule,
  deleteSchedule as apiDeleteSchedule,
  getSchedules,
  patchSchedule,
  Schedule,
  ScheduleType,
  testSchedule as apiTestSchedule,
  ScheduleTestTargets,
} from "../lib/schedulesService";

import { useAuth } from "./auth/auth";

type ScheduleList = { [type in ScheduleType]: Schedule[] };

interface SchedulesContextProps {
  loading: boolean;
  loaded: boolean;
  schedules: ScheduleList;
  saveSchedule: (schedule: Schedule, id?: number) => Promise<string | boolean>;
  deleteSchedule: (schedule: Schedule) => Promise<boolean>;
  testSchedule: (
    target: ScheduleTestTargets,
    schedule: Schedule,
  ) => Promise<{ error: boolean; message?: string }>;
}

const SchedulesContext = createContext<SchedulesContextProps | null>(null);

export function ProvideSchedules({ children }: { children: ReactNode }) {
  const provider = useProvideSchedules();
  return (
    <SchedulesContext.Provider value={provider}>
      {children}
    </SchedulesContext.Provider>
  );
}

export const useSchedules = () => {
  const context = useContext(SchedulesContext);
  if (context === null) {
    throw Error("Schedules context not provided");
  }
  return context;
};

const useProvideSchedules = () => {
  const auth = useAuth();

  const [loadingState, setLoadingState] = useState<
    "initial" | "loading" | "loaded"
  >("initial");
  const [schedules, setSchedules] = useState<ScheduleList>({
    ki: [],
    dashboard: [],
    report: [],
  });

  const validateSchedule = (schedule: Schedule) => {
    if (schedule.blocks.length === 0) {
      return _`Please select at least one item to be included in the schedule.`;
    }

    if (!schedule.medias.email.activated && !schedule.medias.slack) {
      return _`Please configure at least one media.`;
    }

    if (
      schedule.medias.email.activated &&
      schedule.medias.email.emails.length === 0
    ) {
      return _`Please enter at least one email or disable Email media.`;
    }

    return true;
  };

  const saveSchedule = async (schedule: Schedule, id?: number) => {
    const validation = validateSchedule(schedule);
    if (validation !== true) {
      return validation;
    }
    if (id) {
      const result = await patchSchedule(
        await auth.getToken(),
        schedule.type,
        schedule,
        id,
      );
      if (!result.error) {
        const newSchedules = { ...schedules };
        const toUpdateId = newSchedules[schedule.type].findIndex(
          (s) => s.id === id,
        );
        newSchedules[schedule.type][toUpdateId] = schedule;
        setSchedules(newSchedules);
        return true;
      }
    } else {
      const result = await createSchedule(
        await auth.getToken(),
        schedule.type,
        schedule,
      );
      if (!result.error) {
        const newSchedules = { ...schedules };
        newSchedules[schedule.type].push({ ...schedule, id: result.data });
        setSchedules(newSchedules);
        return true;
      }
    }
    return _`An error occured, please try again later`;
  };

  const deleteSchedule = async (schedule: Schedule) => {
    const result = await apiDeleteSchedule(
      await auth.getToken(),
      schedule.id || 0,
    );

    const newSchedules = { ...schedules };
    const newSchedulesList = newSchedules[schedule.type].filter(
      (s) => s.id !== schedule.id,
    );
    newSchedules[schedule.type] = newSchedulesList;
    setSchedules(newSchedules);
    return !result.error;
  };

  const testSchedule = async (
    target: ScheduleTestTargets,
    schedule: Schedule,
  ) => {
    const validation = validateSchedule(schedule);
    if (validation !== true) {
      return { error: true, message: validation };
    }

    const result = await apiTestSchedule(
      await auth.getToken(),
      target,
      schedule,
    );

    return result;
  };

  const loadSchedules = useCallback(async () => {
    setLoadingState("loading");
    const data = await getSchedules(await auth.getToken());

    const newSchedules: ScheduleList = { ki: [], dashboard: [], report: [] };
    data.forEach((item) => {
      newSchedules[item.type].push(item);
    });

    setSchedules(newSchedules);
    setLoadingState("loaded");
  }, [auth]);

  useEffect(() => {
    if (auth.processing || !auth.isLoggedIn) {
      return;
    }
    void loadSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.processing, auth.user?.tenantId, auth.isLoggedIn]);

  return {
    loading: loadingState === "loading",
    loaded: loadingState === "loaded",
    schedules,
    saveSchedule,
    deleteSchedule,
    testSchedule,
  };
};
