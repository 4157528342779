import classNames from "classnames";
import React from "react";

import { Button } from "../Button";
import useDropdown from "../Dropdown/Dropdown.hooks";
import { IconNames } from "../Icon/Icon";
import { IconButton } from "../IconButton";
import { IconButtonColor } from "../IconButton/types";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledMoreOptions,
  StyledMoreOptionsDivider,
  StyledMoreOptionsMenu,
  StyledMoreOptionsMenuItem,
} from "./MoreOptions.styled";

export interface OptionItem {
  type?: "option-item";
  icon?: IconNames;
  leftComponents?: React.ReactElement[];
  label: string;
  onClick: (dataKey?: string) => void;
  rightComponent?: React.ReactElement;
  disabled?: boolean;
}

export interface OptionDivider {
  type: "divider";
}

export interface OptionButton {
  type: "button";
  label: string;
  onClick: () => void;
}

export type MoreOptionsProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    dataKey?: string;
    options: (OptionItem | OptionDivider | OptionButton)[];
    anchorComponent?: React.ReactNode;
    iconButtonColor?: IconButtonColor;
    allowFlexibleHeight?: boolean;
    forceWidthMatch?: boolean;
    allowToggle?: boolean;
    size?: "tiny" | "small" | "large";
  };

export default function MoreOptions({
  dataKey,
  options,
  anchorComponent,
  size = "small",
  className,
  style,
  iconButtonColor,
  allowFlexibleHeight,
  forceWidthMatch,
  allowToggle,
  ...interactionEvents
}: MoreOptionsProps) {
  const { refCallback, listPosition, open, close, handleClick } = useDropdown({
    onClick: interactionEvents.onClick,
    allowFlexibleHeight,
    forceWidthMatch,
    allowToggle,
  });

  return (
    <StyledMoreOptions
      {...interactionEvents}
      aria-label="More Options"
      onClick={anchorComponent ? handleClick : undefined}
      ref={refCallback}
      className={classNames(className, { open })}
      style={style}
      size={size}
    >
      {!anchorComponent && (
        <IconButton
          name="More"
          size={size}
          color={iconButtonColor}
          onClick={handleClick}
        />
      )}
      {anchorComponent}
      {open && options.length > 0 && (
        <StyledMoreOptionsMenu style={{ ...listPosition, minWidth: undefined }}>
          {options.map((o, i) => {
            if (o.type === "divider") {
              return <StyledMoreOptionsDivider />;
            }

            if (o.type === "button") {
              return (
                <Button
                  size="small"
                  style={{
                    width: "calc(100% - 32px)",
                    margin: "8px 16px 0px 16px",
                  }}
                  label={o.label}
                  onClick={o.onClick}
                />
              );
            }

            return (
              <StyledMoreOptionsMenuItem
                key={`${o.label}-${i}`}
                leftIcon={o.icon}
                rightComponents={
                  o.rightComponent ? [o.rightComponent] : undefined
                }
                leftComponents={o.leftComponents}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  !o.disabled && o.onClick(dataKey);
                  !o.disabled && close();
                  return false;
                }}
                block
                disabled={o.disabled}
              >
                {o.label}
              </StyledMoreOptionsMenuItem>
            );
          })}
        </StyledMoreOptionsMenu>
      )}
    </StyledMoreOptions>
  );
}
