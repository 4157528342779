import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import { useAuth } from "../hooks/auth/auth";
import {
  CustomMetric,
  CustomMetricDefinition,
  CustomMetricRule,
} from "../types/synthesizer";
import {
  RuleOperators,
  SmartFilterSelector,
  TypeOrArrayOfType,
  isRuleGroup,
} from "../utils/filterUtils";
import { filterIsNotNullOrUndefined } from "../utils/utils";

import { createClient } from "./apiClient";

const client = createClient("views-service");

export const customMetricsFromViewsServiceQueryKey = (tenantId?: string) => [
  "views-service",
  "customMetrics",
  tenantId,
];
export const useCustomMetricsFromViewsService = () => {
  const auth = useAuth();
  return useQuery({
    queryKey: customMetricsFromViewsServiceQueryKey(auth.user?.tenantId),
    queryFn: async () => {
      return getCustomMetrics(await auth.getToken());
    },
    enabled: !auth.processing,
  });
};

export const getCustomMetrics = async (token: string) => {
  const result = await client
    .new()
    .get("/api/metrics")
    .auth(token)
    .trackDuration()
    .fetch<{ data: CustomMetricDefinition[] }>();
  return result.error ? [] : result.data?.map(parseDatesInCustomMetric);
};

export const getCustomMetric = async (token: string, id: string) => {
  const result = await client
    .new()
    .get("/api/metrics/" + id)
    .auth(token)
    .trackDuration()
    .fetch<{ data: CustomMetricDefinition }>();
  return result.error ? null : parseDatesInCustomMetric(result.data);
};

export const createCustomMetric = async (
  token: string,
  title: string,
  json: CustomMetric,
) => {
  const result = await client
    .new()
    .post("/api/metrics")
    .body({ title, json: JSON.stringify(json) })
    .auth(token)
    .trackDuration()
    .fetch<{ data: string }>();
  return result.error ? null : result.data;
};

export const patchCustomMetric = async (
  token: string,
  title: string,
  json: CustomMetric,
  id: string,
) => {
  const result = await client
    .new()
    .patch("/api/metrics/" + id)
    .body({ title, json: JSON.stringify(json) })
    .auth(token)
    .trackDuration()
    .fetch<
      { error: false },
      {
        error: true;
        conflictingReports: Array<[string, string]>;
        message: string;
      }
    >();
  return result;
};

export const deleteCustomMetric = async (token: string, id: string) => {
  const result = await client
    .new()
    .delete("/api/metrics/" + id)
    .body({})
    .auth(token)
    .trackDuration()
    .fetch();
  return result.error ? null : result.data;
};

export const duplicateCustomMetric = async (token: string, id: string) => {
  const result = await client
    .new()
    .post(`/api/metrics/${id}/duplicate`)
    .body({})
    .auth(token)
    .trackDuration()
    .fetch();
  return result.error ? null : result.data;
};

const parseDateRuleInCustomMetric = (
  dateRule: CustomMetricRule,
): CustomMetricRule => {
  return {
    ...dateRule,
    values: (dateRule.values as SmartFilterSelector[]).map((value) => {
      return {
        ...value,
        rangeSelection: {
          start: moment(value.rangeSelection.start),
          end: moment(value.rangeSelection.end),
        },
      };
    }),
  };
};

const isValidRule = (rule: CustomMetricRule) => {
  if (rule.operator && !Object.values(RuleOperators).includes(rule.operator)) {
    return false;
  }
  return true;
};

const fixRuleOperator = (rule: CustomMetricRule): CustomMetricRule => {
  if (rule.operator && !Object.values(RuleOperators).includes(rule.operator)) {
    return {
      ...rule,
      operator: rule.operator.toUpperCase() as RuleOperators, //in case we still have lowercase filters stored
    };
  }
  return rule;
};

const parseRules = (
  rules: TypeOrArrayOfType<CustomMetricRule>[],
): TypeOrArrayOfType<CustomMetricRule>[] => {
  const parseRule = (r: CustomMetricRule): CustomMetricRule => {
    if (r.operator === RuleOperators.date) {
      return parseDateRuleInCustomMetric(r);
    }
    return r;
  };
  return rules
    .map((rule) => {
      if (isRuleGroup(rule)) {
        return rule.map(fixRuleOperator);
      } else {
        return fixRuleOperator(rule);
      }
    })
    .map((rule) => {
      if (isRuleGroup(rule)) {
        return rule.filter(isValidRule).map((r) => parseRule(r));
      } else {
        const newRule = parseRule(rule);
        return isValidRule(newRule) ? newRule : null;
      }
    })
    .filter(filterIsNotNullOrUndefined);
};

export const parseDateInCustomMetric = (
  customMetric: CustomMetricDefinition,
): CustomMetricDefinition => {
  return {
    ...customMetric,
    elements: customMetric.elements.map((e) => ({
      ...e,
      filters: parseRules(e.filters),
    })),
  };
};
const parseDatesInCustomMetric = (
  customMetric: CustomMetricDefinition,
): CustomMetricDefinition => {
  const newCustomMetric: CustomMetricDefinition = {
    ...customMetric,
    ...parseDateInCustomMetric(customMetric),
  };
  return newCustomMetric;
};
