import {
  DimensionTypes,
  RuleOperators,
  SmartFilterSelector,
  TypeOrArrayOfType,
} from "../utils/filterUtils";

interface RevenueComputationOptions {
  removeDiscounts?: boolean;
  removeRefunds?: boolean;
  removeShipping?: boolean;
  removeTax?: boolean;
  removeTip?: boolean;
  removeCostOfProduct?: boolean;
  removeExpenses?: boolean;
}

export const MATH_OPERATORS = ["+", "-", "÷", "x"];

export interface MetricDescription {
  operator?: "+" | "-" | "÷" | "x" | "include" | "";
  image?: string | string[];
  label?: string;
  openParenthesis?: boolean;
  closeParenthesis?: boolean;
  block?: boolean;
  isRevenueComputation?: boolean;
  isOrderSettings?: boolean;
  revenueComputationOptions?: RevenueComputationOptions;
  disabled?: boolean;
  isSubHeading?: boolean;
  setting?: string;
  fixed?: boolean;
  value?: string;
}

export interface IMetric {
  key: string;
  label: string;
  currency?: boolean;
  percentage?: boolean;
  timedelta?: boolean;
  integer?: boolean;
  suffix?: string;
  prefix?: string;
  ratio?: boolean;
  invertedEvolution?: boolean;
  description?: MetricDescription[];
  dependencies?: string[];
  filterDependencies?: string[];
  tableDependencies?: string[];
  tags?: string[];
  availableIf?: [][];
  in?: string[];
  influences?: string[];
  influencedBy?: string[];
  benchmarkMetric?: string;
  breakdowns?: string[];
  extraSettings?: Record<string, unknown>;
  canBeUsedInReports: boolean;
}

export interface IDimension {
  key: string;
  label: string;
  type: DimensionTypes;
  groupable: boolean;
  filterable: boolean;
  operators?: string[];
  valueLabels?: string[];
  defaultOperator?: RuleOperators;
  defaultValues?: string[];
  in: string[];
}

export interface ITable {
  key: string;
  rootTableKey?: string;
  isAvailable: boolean;
  label: string;
  order: number;
  dimensions: { [key: string]: IDimension };
  metrics: {
    raw: { [key: string]: IMetric };
    computed: { [key: string]: IMetric };
  };
  connectorKeys?: string[];
}

export interface IMetricsList {
  tables: { [tableKey: string]: ITable };
  metrics: { [key: string]: IMetric };
  customMetrics?: { [key: string]: IMetric };
  customDimensions?: { [key: string]: IDimension };
}

export interface MetricsListAPI {
  tables: { [tableKey: string]: ITable };
  metrics: { [key: string]: IMetric };
  customMetrics: {
    [key: string]: { raw: CustomMetricDefinition; canBeUsedInReports: boolean };
  };
}

export interface ComposeTopFilter {
  topDimensions: string[];
  topMetric: string;
  topLimit: number;
  direction?: "ASC" | "DESC";
  groupOthers?: boolean;
}

export type ComposeExtraParams = Record<string, string>;
export interface CustomMetricDefinition extends CustomMetric {
  id: number;
}

export interface CustomMetricRule {
  dimension: string;
  operator: RuleOperators;
  values: string[] | SmartFilterSelector[];
}

export interface CustomMetricElement {
  type: "metric" | "operator" | "value" | "linebreak";
  value: string;
  filters: TypeOrArrayOfType<CustomMetricRule>[];
}

export type CustomMetricFormattingNumberType = "integer" | "decimal";

interface CustomMetricFormatting {
  type: CustomMetricFormattingType;
  prefix: string;
  suffix: string;
  numberType: CustomMetricFormattingNumberType;
  currency?: string;
}

export type CustomMetricFormattingType =
  | "number"
  | "percent"
  | "currency"
  | "timedelta";

export const DEFAULT_CUSTOM_METRIC_FORMATTING: CustomMetricFormatting = {
  type: "number",
  prefix: "",
  suffix: "",
  numberType: "decimal",
};
export interface CustomMetric {
  title: string;
  description?: string;
  elements: CustomMetricElement[];
  formatting: CustomMetricFormatting;
}
