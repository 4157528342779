import { useQuery } from "@tanstack/react-query";
import {
  useCallback,
  ReactNode,
  createContext,
  useContext,
  useEffect,
} from "react";

import { theme } from "../icecube-ux";
import {
  getIntegrationHandler,
  registerFrontendIntegration,
} from "../integrations/integration";
import { ConnectorList, getConnectors } from "../lib/integrationsService";
import { CustomConnectorButtonSection } from "../pages/connectors/popups/connect/customConnectors/CustomConnectorButtonSection";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";
import { keyToLabel } from "../utils/utils";

import { useAuth } from "./auth/auth";

type ConnectorDataContectProps = {
  connectorData: ConnectorList;
  connectorDataIsLoading: boolean;
  connectorDataIsFetched: boolean;
  fetchConnectorData: () => Promise<void>;
};

const ConnectorDataContext = createContext<ConnectorDataContectProps | null>(
  null,
);

export const ProvideConnectorData = ({ children }: { children: ReactNode }) => {
  const connectorData = useProvideConnectorData();
  return (
    <ConnectorDataContext.Provider value={connectorData}>
      {children}
    </ConnectorDataContext.Provider>
  );
};

export const useConnectorData = (): ConnectorDataContectProps => {
  const context = useContext(ConnectorDataContext);
  if (context === null) {
    throw Error("Connector data context not provided");
  }
  return context;
};
const getConnectorsQueryKey = (tenantId?: string) => [
  "getConnectors",
  tenantId,
];
export const useConnectors = () => {
  const auth = useAuth();

  return useQuery({
    queryKey: getConnectorsQueryKey(auth.user?.tenantId),
    queryFn: async () => {
      const token = await auth.getToken();
      return getConnectors(token);
    },
    enabled: auth.isLoggedIn,
    refetchInterval: 1000 * 60 * 10,
  });
};
const useProvideConnectorData = () => {
  const { data, isFetched, refetch, isFetching } = useConnectors();
  const fetch = useCallback(async () => {
    await refetch();
  }, [refetch]);
  useEffect(() => {
    if (!data) {
      return;
    }
    Object.keys(data).forEach((connectorKey) => {
      if (getIntegrationHandler(connectorKey)) {
        return;
      }
      registerFrontendIntegration({
        key: connectorKey,
        image: "Polar",
        backgroundColor: theme.customColors.polarBg,
        borderColor: theme.customColors.polarBorder,
        description: "Custom Connector",

        label: keyToLabel(connectorKey.replaceAll("-", "_")),
        category: INTEGRATION_CATEGORIES.other_datasources,
        isCustomConnector: true,
        getButtonSection: () => CustomConnectorButtonSection,
        provider: "custom",
      });
    });
  }, [data]);
  return {
    connectorData: data || {},
    connectorDataIsLoading: isFetching,
    connectorDataIsFetched: isFetched,
    fetchConnectorData: fetch,
  };
};
