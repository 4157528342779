import { useEffect, useState } from "react";
import styled from "styled-components";

import {
  AvailableTenant,
  FALLBACK_AVATAR,
} from "../../common/types/users-service";
import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useAvailableTenants } from "../../hooks/useAvailableTenants";
import {
  Text,
  Flex,
  Popup,
  Space,
  Stack,
  Button,
  theme,
  Input,
  Select,
  Icon,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { getUserTenants } from "../../lib/usersService";

type TenantListPopupProps = {
  onClose: () => void;
};

const StyledAvatarImg = styled.img`
  border: 1px solid #eae9ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  z-index: 2;
  background-color: #eae9ff;
`;

const StyledWorkspacesIconWrapper = styled.div`
  background: ${theme.colors.primary10};
  border-radius: 8px;
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWorkspacesIcon = styled(Icon).attrs(() => {
  return {
    name: "Grid2",
    size: 30,
  };
})`
  path {
    stroke: ${theme.colors.primary30};
    fill: ${theme.colors.primary30};
  }

  path:nth-child(3) {
    stroke: ${theme.colors.primary80};
    fill: ${theme.colors.primary80};
  }
`;

const StyledCurrentAvatarImg = styled.img`
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 4px;
  align-self: center;
`;

export const TenantListPopup = ({ onClose }: TenantListPopupProps) => {
  const auth = useAuth();
  const bootstrap = useBootstrap();
  const {
    handleLogAsTenant,
    loadingLogAsTenant,
    handleCreateTenant,
    loadingCreateTenant,
  } = useAvailableTenants();

  const [showConfirmCreateTenant, setShowConfirmCreateTenant] = useState(false);

  const [newTenantName, setNewTenantName] = useState("");
  const [sourceTenantId, setSourceTenantId] = useState<string>("");
  const [errors, setErrors] = useState({
    tenantName: undefined as string | undefined,
  });
  const [hoveredTenant, setHoveredTenant] = useState("");
  const [locallyFetchedTenants, setLocallyFetchedTenants] = useState<
    null | AvailableTenant[]
  >();

  const availableTenants = auth.user?.availableTenants || locallyFetchedTenants;
  useEffect(() => {
    if (availableTenants?.length || !auth.tokens?.id) {
      return;
    }

    const handler = async () => {
      const result = await getUserTenants(await auth.getToken());
      setLocallyFetchedTenants(result);
    };
    void handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTenants?.length, auth.tokens?.id]);

  const getAvatar = (avatar?: string) => {
    if (!avatar) {
      return FALLBACK_AVATAR;
    }
    return `${process.env.REACT_APP_CDN_HOST}/${avatar}`;
  };

  const handleCancelCreateTenant = () => {
    setNewTenantName("");
    setSourceTenantId("");
    setShowConfirmCreateTenant(false);
  };

  const onCreateTenant = () => {
    if (newTenantName) {
      setErrors({ tenantName: undefined });
    } else {
      setErrors({ tenantName: _`Workspace name is required` });
      return;
    }
    void handleCreateTenant({ newTenantName, sourceTenantId });
  };

  return (
    <>
      <Popup
        fullScreen
        style={{
          maxWidth: "80%",
          width: 600,
          maxHeight: "80%",
          height: 800,
        }}
        headerComponent={
          <Popup.BaseHeader>
            <Flex gap={20}>
              <StyledWorkspacesIconWrapper>
                <StyledWorkspacesIcon />
              </StyledWorkspacesIconWrapper>

              {showConfirmCreateTenant ? (
                <Text variant="body16SemiBold">Create Workspace</Text>
              ) : (
                <Stack gap={2}>
                  <Text variant="body18Medium" color={theme.colors.text110}>
                    Workspaces
                  </Text>
                  <Text variant="body12Regular" color={theme.colors.text80}>
                    Effortlessly switch between your environments
                  </Text>
                </Stack>
              )}
            </Flex>
          </Popup.BaseHeader>
        }
        footerComponent={
          <Flex fullWidth gap={8}>
            <Button
              onClick={() => {
                if (showConfirmCreateTenant) {
                  return handleCancelCreateTenant();
                }

                if (auth.user) {
                  return onClose();
                }

                auth.logout(() => {});
              }}
              style={{ width: "50%" }}
              size="large"
              color="ghost"
            >
              {showConfirmCreateTenant
                ? _`Cancel`
                : auth.user
                ? _`Close`
                : _`Log out`}
            </Button>

            <Button
              onClick={() =>
                showConfirmCreateTenant
                  ? onCreateTenant()
                  : setShowConfirmCreateTenant(true)
              }
              loading={loadingCreateTenant}
              style={{ width: "50%" }}
              size="large"
              leftIcon="Add"
              color={showConfirmCreateTenant ? "primary" : "secondary"}
            >
              {showConfirmCreateTenant
                ? _`Confirm Creation`
                : _`Create Workspace`}
            </Button>
          </Flex>
        }
        onClose={onClose}
      >
        <Space size={16} />

        {showConfirmCreateTenant ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onCreateTenant();
            }}
          >
            <Space size={16} />

            <Input
              label={_`Workspace Name`}
              placeholder={_`Type a name for your workspace…`}
              error={errors.tenantName}
              onChange={(v) => {
                setNewTenantName(String(v));
              }}
            />

            <Space size={16} />

            <Select
              label={_`Import data from`}
              options={[
                {
                  value: "",
                  label: _`-- None (start fresh) --`,
                },
                ...(availableTenants?.map((t) => ({
                  value: t.id,
                  label: t.companyName || "Unnamed Workspace",
                  leftComponents: [
                    <StyledAvatarImg
                      style={{ height: 20, width: 20, marginTop: 4 }}
                      src={getAvatar(t.avatar || "")}
                    />,
                  ],
                })) || []),
              ]}
              selected={[sourceTenantId]}
              onChange={(v) => {
                setSourceTenantId(String(v));
              }}
            />

            <Space size={16} />
          </form>
        ) : (
          <>
            <Stack>
              <Flex>
                <Flex
                  fullWidth
                  gap={16}
                  justifyContent="space-between"
                  style={{
                    padding: "16px 24px",
                    background: theme.colors.bgLight10,
                    borderRadius: theme.borderRadius.r16px,
                  }}
                >
                  <Stack>
                    <Text variant="body18Medium" color={theme.colors.text110}>
                      {auth.user
                        ? _`Selected Workspace`
                        : _`No active workspace`}
                    </Text>
                    <Text
                      variant="body12Regular"
                      color={theme.colors.text80}
                      whiteSpace="pre"
                    >
                      {auth.user
                        ? bootstrap.tenant.companyName
                        : _`Please have your team invite you.\nIf you're new, please create a new workspace.`}
                    </Text>
                  </Stack>

                  <StyledCurrentAvatarImg
                    src={getAvatar(
                      bootstrap.getUserTenantSetting("avatar", "") || undefined,
                    )}
                  />
                </Flex>
              </Flex>

              <Space size={8} />

              {[...(availableTenants || [])]
                .sort((t1, t2) =>
                  (t1.companyName || "").localeCompare(t2.companyName || ""),
                )
                .map(({ id, avatar, companyName }) => {
                  if (id === auth.user?.tenantId) {
                    return null;
                  }

                  const isHovered = hoveredTenant === id;

                  return (
                    <Flex
                      key={id}
                      fullWidth
                      justifyContent="space-between"
                      onMouseEnter={() => {
                        setHoveredTenant(id);
                      }}
                      onClick={() => {
                        const handler = async () => {
                          const result = await handleLogAsTenant(id);

                          if (!result.error) {
                            onClose();
                          }
                        };

                        void handler();
                      }}
                      style={{
                        background: isHovered ? theme.colors.bgLight20 : "none",
                        padding: "10px 12px",
                        borderRadius: 6,
                        cursor: "pointer",
                      }}
                    >
                      <Flex fullWidth alignItems="center" gap={8}>
                        <StyledAvatarImg
                          style={{ width: 30, height: 30 }}
                          src={getAvatar(avatar)}
                          alt=""
                        />
                        <Text
                          variant="body12Regular"
                          color={theme.colors.text110}
                        >
                          {companyName}
                        </Text>
                      </Flex>
                      {bootstrap.tenant.id === id ? (
                        <div />
                      ) : (
                        <Button
                          loading={loadingLogAsTenant === id}
                          color={isHovered ? "primary" : "tertiary"}
                          size="small"
                          style={{ width: 80 }}
                          onClick={() => void handleLogAsTenant(id)}
                        >
                          Switch
                        </Button>
                      )}
                    </Flex>
                  );
                })}
            </Stack>
          </>
        )}
      </Popup>
    </>
  );
};
