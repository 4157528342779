import classNames from "classnames";

import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Text, TextVariant } from "../Typography";

import {
  NotificationMessageVariant,
  NotificationMessageSize,
  StyledNotificationMessage,
} from "./NotificationMessage.styled";

export type NotificationMessageProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    variant: NotificationMessageVariant;
    textContent: string | React.ReactNode;
    title?: string | React.ReactNode;
    size?: NotificationMessageSize;
    actionsChildren?: React.ReactNode;
    withIcon?: boolean;
    icon?: IconNames;
    iconSize?: number;
  };

const ICON_COLOR = {
  primary: theme.colors.primary70,
  long: theme.colors.primary110,
  warning: "#C08B00B2",
};

const TEXT_COLOR = {
  primary: theme.colors.primary100,
  long: theme.colors.text110,
  warning: theme.customColors.textWarningNotification,
};

const TEXT_SIZE: { [key: string]: TextVariant } = {
  small: "body10Regular",
  medium: "body12Regular",
  large: "body14Regular",
};

const TITLE_SIZE: { [key: string]: TextVariant } = {
  small: "body10Medium",
  medium: "body12Medium",
  large: "body14Medium",
};

export function NotificationMessage({
  className,
  style,
  variant,
  textContent,
  title,
  size = "small",
  actionsChildren,
  withIcon = false,
  icon = "InfoCircle",
  iconSize,
  ...interactionEvents
}: NotificationMessageProps) {
  return (
    <StyledNotificationMessage
      {...interactionEvents}
      variant={variant}
      size={size}
      style={style}
      className={classNames(className)}
    >
      {withIcon && (
        <Icon name={icon} color={ICON_COLOR[variant]} size={iconSize} />
      )}
      <Flex flexDirection={"column"} style={{ flexGrow: 1 }}>
        {title && (
          <Text
            variant={TITLE_SIZE[size]}
            color={TEXT_COLOR[variant]}
            style={{ flexGrow: 1 }}
          >
            {title}
          </Text>
        )}
        <Text
          variant={TEXT_SIZE[size]}
          color={TEXT_COLOR[variant]}
          style={{ flexGrow: 1 }}
        >
          {textContent}
        </Text>
      </Flex>
      <Flex gap={4} style={{ alignSelf: "flex-end" }}>
        {actionsChildren}
      </Flex>
    </StyledNotificationMessage>
  );
}
