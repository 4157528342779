import * as React from "react";
import styled from "styled-components";

import { useConnectorObservability } from "../../hooks/connectorObservability";
import { useMetricList } from "../../hooks/metricList";
import {
  ConnectorIcon,
  isValidConnectorIcon,
  Space,
  Tag,
  Text,
  theme,
} from "../../icecube-ux";
import { oldImageNamesMapping } from "../../icecube-ux/utils";
import { _ } from "../../languages/helper";
import ConnectorIconList from "../../pages/custom/components/ConnectorIconList";
import { MATH_OPERATORS, MetricDescription } from "../../types/synthesizer";
import { getMetricIcon } from "../../utils/metricUtils";

import { useParsedDescription } from "./useParsedDescription";

const Divider = styled.div`
  content: "\u00a0";
  width: 100%;
  height: 1px;
  background: ${theme.colors.borderLight};
`;

const StackLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FormulaLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

const ListLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const LabelLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const MetricDescriptionText = ({
  description,
  inlineList,
}: {
  description: MetricDescription[];
  inlineList?: boolean;
}) => {
  const descriptionSections = useParsedDescription(description);
  const { statuses } = useConnectorObservability();
  const { metrics: metricList, tableKeyToConnectorKey } = useMetricList();
  const SectionLayout = inlineList ? FormulaLayout : StackLayout;

  return (
    <SectionLayout>
      {descriptionSections.map((descriptionSection, sectionIndex) => {
        const LayoutComponent = inlineList
          ? React.Fragment
          : descriptionSection.type === "formula"
          ? FormulaLayout
          : ListLayout;
        const LabelWrapper = inlineList ? React.Fragment : LabelLayout;

        return (
          <React.Fragment key={sectionIndex}>
            {sectionIndex > 0 && !inlineList ? <Divider /> : null}

            <LabelWrapper>
              {descriptionSection.label && !inlineList ? (
                <>
                  <Text
                    color={theme.colors.text100}
                    variant={"body11Medium"}
                    inline
                  >
                    {descriptionSection.label}
                  </Text>

                  <Space size={2} />
                </>
              ) : null}

              <LayoutComponent>
                {descriptionSection.elements.map((element, elementIndex) => {
                  const textColor = element.disabled
                    ? theme.colors.text80
                    : theme.colors.text100;

                  return (
                    <div
                      key={`formula-tooltip-${sectionIndex}-${elementIndex}`}
                    >
                      {element.openParenthesis && "("}
                      {elementIndex > 0 &&
                      MATH_OPERATORS.includes(element.operator || "") ? (
                        <Text color={textColor} inline>
                          {element.operator}
                        </Text>
                      ) : inlineList && element.operator === "include" ? (
                        <Tag color={element.disabled ? "default" : "primary"}>
                          includes
                        </Tag>
                      ) : null}{" "}
                      <span style={{ lineHeight: "20px" }}>
                        {element.image &&
                          (Array.isArray(element.image)
                            ? element.image
                            : [element.image]
                          )
                            .filter((i) => i !== "")
                            .map((img, i2) => {
                              const icons = getMetricIcon(
                                String(element.value),
                                metricList,
                                statuses,
                                tableKeyToConnectorKey,
                              );

                              if (icons.length > 1) {
                                return (
                                  <ConnectorIconList
                                    names={icons}
                                    size="small"
                                  />
                                );
                              }
                              return (
                                <ConnectorIcon
                                  key={`img-${img}-${i2}`}
                                  disabled={element.disabled}
                                  style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                  }}
                                  name={
                                    isValidConnectorIcon(img)
                                      ? icons[0]
                                      : oldImageNamesMapping[
                                          `/img/connectors/${img}.png`
                                        ]
                                  }
                                />
                              );
                            })}{" "}
                        <Text color={textColor} inline>
                          {!inlineList && element.operator === "include"
                            ? _`Include ${["label", element.label ?? ""]}`
                            : element.label}
                        </Text>
                      </span>{" "}
                      {element.closeParenthesis && ")"}
                    </div>
                  );
                })}
              </LayoutComponent>
            </LabelWrapper>
          </React.Fragment>
        );
      })}
    </SectionLayout>
  );
};

export default MetricDescriptionText;
