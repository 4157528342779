import classNames from "classnames";
import React from "react";

import { useTenantAddOns } from "../../hooks/useAddonStatuses";
import { _ } from "../../languages/helper";
import { Box } from "../Box";
import { Button } from "../Button";
import { ConnectorIconNames } from "../ConnectorIcon";
import ConnectorIconWrapperList from "../ConnectorIcon/ConnectorIconWrapperList";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { IconButton } from "../IconButton";
import PercentChip from "../PercentChip";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Tooltip } from "../Tooltip";
import { Text } from "../Typography";
import { WireframeLoader } from "../WireframeLoader";

import { ExploreMetricLink } from "./ExploreMetricLink";
import {
  StyledEvolutionInfo,
  StyledKeyIndicatorCard,
  StyledKeyIndicatorGoal,
  StyledKeyIndicatorGoalArrow,
  StyledKeyIndicatorGoalOverlay,
  StyledKeyIndicatorGoalSVG,
  StyledKeyIndicatorGoalTitle,
  StyledKeyIndicatorValues,
} from "./KeyIndicatorCard.styled";
import type { SyncingConnectors } from "./SyncingConnectors";
import { SyncingStatusTag } from "./SyncingStatusTag";
import { useNoDataReason } from "./useNoDataReason";

export interface KeyIndicatorGoal {
  value: number;
  chartValue?: number;
  valueString: string;
  progression: number;
  warning?: boolean;
  prorated: boolean;
}

export type KeyIndicatorCardProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    indicatorKey: string;
    title: string;
    wasValue: string | undefined;
    evolution: string;
    value: string | undefined;
    icons: ConnectorIconNames[];
    iconTooltip?: React.ReactNode;
    negative?: boolean;
    invertedEvolution?: boolean;
    infoComponent?: React.ReactNode;
    loading?: boolean;
    hasNoDataForSelectedView?: boolean;
    loadingConnector?: string;
    notReadyConnector?: string;
    missingConnectorLabel?: string;
    issueWithMetric?: string;
    missingConnectorOnClick: () => void;
    syncingConnectors: SyncingConnectors;
    goal?: KeyIndicatorGoal;
    onEditGoal?: () => void;
    mobileVersion?: boolean;
    showExploreMetric?: boolean;
    isMetricExploreNew?: boolean;
    hideGoalButton?: boolean;
  };

export default React.forwardRef<HTMLDivElement, KeyIndicatorCardProps>(
  function KeyIndicatorCard(
    {
      indicatorKey,
      title,
      wasValue,
      evolution,
      value,
      icons,
      negative = false,
      invertedEvolution = false,
      loading = false,
      loadingConnector,
      notReadyConnector,
      missingConnectorLabel,
      missingConnectorOnClick,
      issueWithMetric,
      syncingConnectors,
      hasNoDataForSelectedView,
      infoComponent,
      goal,
      onEditGoal,
      mobileVersion,
      className,
      style,
      showExploreMetric,
      isMetricExploreNew,
      hideGoalButton = false,
      ...interactionEvents
    }: KeyIndicatorCardProps,
    ref,
  ) {
    const { isHourlyRefreshEnabled } = useTenantAddOns();
    const goalProgressionColor =
      goal && goal.warning ? theme.colors.warning100 : theme.colors.success100;

    const polarToCartesian = (
      centerX: number,
      centerY: number,
      radius: number,
      angleInDegrees: number,
    ) => {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    };

    const describeArc = (
      x: number,
      y: number,
      radius: number,
      startAngle: number,
      endAngle: number,
    ) => {
      const start = polarToCartesian(x, y, radius, endAngle - 90);
      const end = polarToCartesian(x, y, radius, startAngle - 90);
      const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
      return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`;
    };

    const noDataReason = useNoDataReason({
      hasNoDataForSelectedView,
      loadingConnector,
      missingConnectorLabel,
      missingConnectorOnClick,
      issueWithMetric,
      notReadyConnector,
    });

    if (loading) {
      return (
        <StyledKeyIndicatorCard
          {...interactionEvents}
          ref={ref}
          className={classNames(className)}
          style={style}
        >
          <Flex flexDirection="column" justifyContent="space-between">
            <WireframeLoader width="100px" height="20px" radius={4} />
            <Flex flexDirection="column" gap={4} style={{ paddingLeft: "8px" }}>
              <WireframeLoader width="140px" height="20px" radius={4} />
              <WireframeLoader width="180px" height="30px" radius={4} />
            </Flex>
            <WireframeLoader width="150px" height="20px" radius={4} />
          </Flex>
        </StyledKeyIndicatorCard>
      );
    }

    const canDisplayValue =
      !hasNoDataForSelectedView &&
      !loadingConnector &&
      !missingConnectorLabel &&
      !notReadyConnector;

    const iconMetricTooltip =
      icons[0] === "Polar" ? _`Custom Metric` : icons.join(", ");

    const iconComponent = icons?.filter((d) => d !== undefined).length > 0 && (
      <Tooltip direction="top" content={iconMetricTooltip}>
        <ConnectorIconWrapperList names={icons || []} max={4} />
      </Tooltip>
    );

    return (
      <StyledKeyIndicatorCard
        {...interactionEvents}
        showExploreMetric={showExploreMetric}
        ref={ref}
        className={classNames(className)}
        style={style}
      >
        <Flex flexDirection="column">
          <Flex flexDirection="row" gap={8}>
            {!goal && !mobileVersion && !hideGoalButton && (
              <IconButton
                data-cy={`create-goal-button:${indicatorKey}`}
                name="Target"
                size="small"
                color="blue"
                onClick={onEditGoal}
              />
            )}
            {iconComponent}
            {!isHourlyRefreshEnabled && syncingConnectors.any && (
              <SyncingStatusTag
                withText
                syncingConnectors={syncingConnectors}
                context="ki-metric"
              />
            )}
          </Flex>
          <StyledKeyIndicatorValues>
            <Grid gridTemplateColumns="23px auto" alignItems="center">
              {infoComponent}
              <Text
                variant="body13Regular"
                color={theme.colors.text90}
                data-cy="key-indicator-card-title"
                noWrap
                ellipsis
              >
                {title}
              </Text>
            </Grid>
            <Box marginLeft="22px">
              {noDataReason && (
                <Text
                  variant="body11Regular"
                  color={theme.colors.text80}
                  noWrap
                  ellipsis
                >
                  {noDataReason}
                </Text>
              )}
              {canDisplayValue && value !== undefined && (
                <Text
                  fontSize="24px"
                  lineHeight="36px"
                  fontWeight={600}
                  color={theme.colors.text110}
                  data-cy="key-indicator-card-value"
                  noWrap
                  ellipsis
                >
                  {value}
                </Text>
              )}
            </Box>
          </StyledKeyIndicatorValues>
          {canDisplayValue && wasValue !== undefined && (
            <StyledEvolutionInfo>
              <PercentChip
                negative={negative}
                invertedEvolution={invertedEvolution}
                evolution={evolution}
                small
              />
              <Text
                variant="body11Regular"
                color={theme.colors.text80}
                noWrap
                ellipsis
              >
                {_`Was`} {wasValue}
              </Text>
            </StyledEvolutionInfo>
          )}
        </Flex>
        {canDisplayValue && goal && (
          <StyledKeyIndicatorGoal data-cy={"goal"}>
            {!mobileVersion && (
              <StyledKeyIndicatorGoalOverlay>
                <Button
                  data-cy={`edit-goal-button:${indicatorKey}`}
                  color="tertiary-on-blue-background"
                  leftIcon="Edit"
                  onClick={onEditGoal}
                >
                  {_`Edit`}
                </Button>
              </StyledKeyIndicatorGoalOverlay>
            )}
            <StyledKeyIndicatorGoalTitle>
              <Text
                variant="body10Regular"
                color={theme.colors.text80}
                lineHeight="18px"
              >
                {goal.prorated ? _`Prorated target` : _`Target`}
              </Text>
            </StyledKeyIndicatorGoalTitle>
            <Grid
              gridTemplateRows="1fr auto"
              justifyItems="center"
              alignItems="center"
            >
              <Box maxWidth="100%" textAlign="center" overflow="hidden">
                <Text
                  data-cy={`goal-value:${indicatorKey}`}
                  variant="body13SemiBold"
                  color={theme.colors.text90}
                  lineHeight="18px"
                  ellipsis
                  noWrap
                >
                  {goal.valueString}
                </Text>
                <PercentChip
                  hideArrow
                  negative={goal.warning}
                  evolution={`${goal.progression}%`}
                  onBackground="dark"
                  small
                />
              </Box>
              <StyledKeyIndicatorGoalSVG
                width={78}
                height={24}
                viewBox="0 0 78 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="39"
                  cy="32.5"
                  r="30"
                  stroke="rgb(236, 239, 248)"
                  strokeWidth="5"
                />
                <path
                  d={describeArc(
                    39,
                    32.5,
                    30,
                    18,
                    18 +
                      144 *
                        (Math.max(0, Math.min(goal.progression, 100)) / 100),
                  )}
                  fill="none"
                  stroke={goalProgressionColor}
                  strokeWidth="5"
                  strokeLinecap="round"
                />
              </StyledKeyIndicatorGoalSVG>
            </Grid>
            <StyledKeyIndicatorGoalArrow />
          </StyledKeyIndicatorGoal>
        )}
        {showExploreMetric && (
          <ExploreMetricLink
            metricKey={indicatorKey}
            isNew={isMetricExploreNew}
          />
        )}
      </StyledKeyIndicatorCard>
    );
  },
);
