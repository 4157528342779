import classNames from "classnames";
import React, { MouseEventHandler } from "react";

import { ButtonColor } from "../Button";
import { ConnectorIcon, ConnectorIconNames } from "../ConnectorIcon";
import { Counter } from "../Counter";
import { Grid } from "../Grid";
import { Icon } from "../Icon";
import { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { OnBackground } from "../shared/baseInputFieldColorMap";
import { TextFieldSize } from "../shared/baseInputFieldSizeStylesMap";
import InputWrapper from "../shared/InputWrapper";
import { TextField as Input } from "../TextField";
import { theme } from "../theme";
import { stopPropagation } from "../utils";

import useDropdown from "./Dropdown.hooks";
import {
  StyledConnectorIconWrapper,
  StyledDropdown,
  StyledDropdownAddButton,
  StyledDropdownIconRight,
  StyledDropdownMainContent,
  StyledDropdownValueDisplay,
  StyleDropdownChildren,
  SyledFixedBottomShadow,
} from "./Dropdown.styled";

const fixedBottomListPosition = {
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndexes.layer6,
};

export type DropdownProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    color?: ButtonColor;
    dataCy?: string;
    label?: string;
    placeholder?: string | React.ReactNode;
    withSearch?: boolean;
    search?: string;
    onSearchChange?: (value: string) => void;
    counter?: number;
    block?: boolean;
    disabled?: boolean;
    notOpenable?: boolean;
    hideArrow?: boolean;
    leftIcon?: IconNames;
    leftIconSize?: number;
    leftConnectorIcon?: ConnectorIconNames | ConnectorIconNames[];
    withAddButton?: boolean;
    onClickAddButton?: MouseEventHandler<HTMLDivElement>;
    children?: React.ReactNode | ((onClose: () => void) => React.ReactNode);
    onBackground?: OnBackground;
    size?: TextFieldSize;
    maxHeight?: string;
    overlayFixedBottom?: boolean;
    closeOnClick?: boolean;
    allowFlexibleWidth?: boolean;
    forceWidthMatch?: boolean;
    openOnInit?: boolean;
    dropdownChildrenStyle?: React.CSSProperties;
    renderIconComponent?: () => React.ReactNode;
  };

export default function Dropdown({
  color = "tertiary",
  dataCy = "dropdown",
  label = "",
  counter,
  placeholder,
  block = false,
  disabled = false,
  notOpenable,
  hideArrow,
  leftIcon,
  leftIconSize,
  leftConnectorIcon,
  withAddButton,
  onClickAddButton,
  className,
  style,
  children,
  maxHeight,
  size = "large",
  onBackground = "whiteBackground",
  overlayFixedBottom,
  closeOnClick,
  allowFlexibleWidth,
  forceWidthMatch,
  withSearch,
  search,
  onSearchChange,
  openOnInit,
  dropdownChildrenStyle,
  renderIconComponent,
  ...interactionEvents
}: DropdownProps) {
  const { refCallback, listPosition, open, handleClick, close } = useDropdown({
    onClick: interactionEvents.onClick,
    disabled,
    withAddButton,
    onClickAddButton,
    notOpenable,
    allowFlexibleHeight: maxHeight !== undefined,
    allowFlexibleWidth,
    forceWidthMatch,
    openOnInit,
  });

  const handleClose = () => {
    close();
    onSearchChange && onSearchChange("");
  };

  return (
    <InputWrapper {...{ label, isFocused: open, disabled }}>
      <StyledDropdown
        {...interactionEvents}
        ref={refCallback}
        color={color}
        style={style}
        className={classNames(className, { block, disabled, open })}
        onClick={disabled ? undefined : handleClick}
        data-cy={dataCy}
        data-intercom-target={dataCy}
        {...{
          withBackground: onBackground,
          disabled,
          open,
          block,
          size,
        }}
      >
        {withSearch && open && (
          <Grid
            gridTemplateColumns="1fr auto"
            alignItems="center"
            style={{ width: "calc(100% - 12px)" }}
          >
            <Input
              value={search}
              onChange={(v) => onSearchChange && onSearchChange(`${v}`)}
              autoFocus
              noBorder
              block
            />
            {!hideArrow && (
              <StyledDropdownIconRight>
                <Icon name="Dropdown" className="droper" size={18} />
              </StyledDropdownIconRight>
            )}
          </Grid>
        )}
        {(!withSearch || !open) && (
          <StyledDropdownMainContent
            template={[
              leftIcon || leftConnectorIcon ? "auto" : "",
              counter !== undefined && counter > 0 ? "auto" : "",
              "1fr",
              hideArrow ? "" : "auto",
            ].join(" ")}
            size={size}
            open={open}
            color={color}
          >
            {leftConnectorIcon ? (
              Array.isArray(leftConnectorIcon) ? (
                renderIconComponent?.()
              ) : (
                <StyledConnectorIconWrapper>
                  <ConnectorIcon name={leftConnectorIcon} size={10} />
                </StyledConnectorIconWrapper>
              )
            ) : (
              <></>
            )}
            {leftIcon && <Icon name={leftIcon} size={leftIconSize} />}
            {counter !== undefined && counter > 0 && (
              <Counter value={counter} disabled={disabled} variant="small" />
            )}
            <StyledDropdownValueDisplay
              disabled={disabled}
              color={color}
              open={open}
            >
              {placeholder}
            </StyledDropdownValueDisplay>
            {!hideArrow && (
              <StyledDropdownIconRight>
                <Icon name="Dropdown" className="droper" size={18} />
              </StyledDropdownIconRight>
            )}
          </StyledDropdownMainContent>
        )}
        {withAddButton && (
          <StyledDropdownAddButton
            className={classNames({ disabled })}
            onClick={(e) => {
              if (onClickAddButton && !disabled) {
                onClickAddButton(e);
              }
              return stopPropagation(e);
            }}
            data-cy="dropdown-add"
            {...{ withBackground: onBackground }}
          >
            <Icon name="Add" />
          </StyledDropdownAddButton>
        )}
        {open && !notOpenable && overlayFixedBottom && (
          <SyledFixedBottomShadow onClick={() => handleClose()} />
        )}
        {open && !notOpenable && (
          <StyleDropdownChildren
            onClick={() => closeOnClick && handleClose()}
            style={{
              ...(overlayFixedBottom ? fixedBottomListPosition : listPosition),
              ...dropdownChildrenStyle,
            }}
            maxHeight={maxHeight}
          >
            {typeof children === "function" ? children(handleClose) : children}
          </StyleDropdownChildren>
        )}
      </StyledDropdown>
    </InputWrapper>
  );
}
