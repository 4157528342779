import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

export const StyledReportTable = styled.div<{
  isShowingMessage: boolean;
  isShowingFooter: boolean;
  headerRows: number;
}>`
  max-height: 100%;

  display: grid;
  grid-template-columns: minmax(0px, auto) minmax(50%, 1fr);
  grid-template-rows: ${(p) => (p.isShowingMessage ? "min-content" : "")} ${(
      p,
    ) =>
      Array.from({ length: p.headerRows }, () => "min-content").join(
        " ",
      )} 1fr ${(p) => (p.isShowingFooter ? "min-content" : "")};
  gap: 4px;
  overflow: hidden;
`;

export const StyledReportSide = styled.div<{ isShowingMessage: boolean }>`
  grid-row: ${(p) => (p.isShowingMessage ? "3 / -1" : "1 / -1")};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: subgrid;
  overflow-x: auto;
  min-height: 0;
`;

export const StyledReportHeader = styled.div<{ headerRows: number }>`
  display: grid;
  margin-bottom: auto;
  background: white;
  grid-row: 1 / span ${(p) => p.headerRows};
  grid-column: 1;
  grid-template-rows: subgrid;
  height: 100%;
`;

export const StyledReportBody = styled.div<{
  isShowingMessage: boolean;
  headerRows: number;
}>`
  grid-row: ${(p) => 1 + p.headerRows + (p.isShowingMessage ? 1 : 0)};
  grid-column: 1;
  min-height: 0px;
  overflow-y: auto;

  &&::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledReportFooter = styled.div<{
  isShowingMessage: boolean;
  headerRows: number;
}>`
  background: white;
  grid-column: 1;
  grid-row: ${(p) => 2 + p.headerRows + (p.isShowingMessage ? 1 : 0)};
  position: sticky;
`;

export const StyledReportFooterLeftTotalCell = styled.div`
  height: 26px;
  box-sizing: border-box;
  background: ${theme.colors.bgLight30};
  border-radius: ${theme.borderRadius.r2px};
  border: 1px solid ${theme.colors.borderLight};
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: ${theme.colors.text100};

  & > span {
    position: sticky;
    left: 8px;
    width: min-content;
  }

  & > span > span {
    width: min-content;
    text-transform: none;
    color: #4d42fe;
    font-size: 10px;
    padding-left: ${legacyTheme.padding.large};
  }
`;
