import styled, { css } from "styled-components";

import { MenuFoldNavButton } from "../../icecube-ux";
import { MenuFoldNavButtonProps } from "../../icecube-ux/MenuFoldNavButton/MenuFoldNavButton";

import { NavigationSeparator } from "./NavigationSeparator";

const StyledNavigationToggleRow = styled.div<{ isToggledOpen: boolean }>`
  cursor: pointer;
  min-height: 12px;

  position: relative;

  display: flex;
  alignitems: center;

  margin-top: 4px;

  ${(p) =>
    !p.isToggledOpen &&
    css`
      color: var(--primary1);
    `}
`;

const StyledNavigationToggleIcon = styled(MenuFoldNavButton)<
  { isNavOpen: boolean } & MenuFoldNavButtonProps
>`
  position: absolute;
  right: 17px;
  top: -7px;

  ${(p) =>
    !p.isNavOpen &&
    css`
      right: 22px;
    `}
`;

type NavigationToggleProps = {
  isNavOpen: boolean;
  isToggledOpen: boolean;
  toggle: () => void;
};

export const NavigationToggle = ({
  isNavOpen,
  isToggledOpen,
  toggle,
}: NavigationToggleProps) => {
  return (
    <StyledNavigationToggleRow isToggledOpen={isToggledOpen}>
      <NavigationSeparator />
      <StyledNavigationToggleIcon
        onClick={toggle}
        isNavOpen={isNavOpen}
        isToggledOpen={isToggledOpen}
      />
    </StyledNavigationToggleRow>
  );
};
