import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  Annotation,
  createAnnotation,
  deleteAnnotation,
  editAnnotation,
  getAnnotations,
} from "../lib/annotationsService";
import { TRACKING_EVENTS, trackEvent } from "../utils/trackingUtils";

import { useAuth } from "./auth/auth";
import { useBootstrap } from "./bootstrap";
import { useSmartFilter } from "./useSmartFilter";

type AnnotationOpenMode = "add" | "edit";

type AnnotationOpenInfo = {
  mode: AnnotationOpenMode;
  selectedDate: string;
  id?: number;
  description?: string;
  viewIds?: string[];
};

type AnnotationContextType = {
  refetchManualAnnotations: () => Promise<
    QueryObserverResult<Annotation[], unknown>
  >;
  manualAnnotationsFetching: boolean;
  manualAnnotations: Annotation[] | undefined;
  openInfo: AnnotationOpenInfo | undefined;
  addAnnotation: (annotation: Annotation) => unknown;
  editAnnotation: (annotation: Annotation) => unknown;
  deleteAnnotation: (id: number) => unknown;
  setOpenInfo: (info: AnnotationOpenInfo | undefined) => void;
};
const AnnotationContext = createContext<AnnotationContextType | undefined>(
  undefined,
);

export const useAnnotationPopup = () => {
  const context = useContext(AnnotationContext);
  if (!context) {
    throw new Error(
      "useAnnotation must be used within an AnnotationProvider. Make sure the Route's component is wrapped with withAuthentication",
    );
  }
  return context;
};

const useProvideAnnotations = () => {
  const [openInfo, setOpenInfo] = useState<AnnotationOpenInfo | undefined>();
  const auth = useAuth();
  const { tenant } = useBootstrap();
  const { selectedViewIds } = useSmartFilter();

  useEffect(() => {
    if (openInfo?.mode) {
      trackEvent(TRACKING_EVENTS.METRIC_RECIPE_ANNOTATION_POPUP_OPENED, {
        mode: openInfo.mode,
      });
    }
  }, [openInfo]);

  const addAnnotation = useCallback(
    async (annotation: Annotation) => {
      const result = createAnnotation(await auth.getToken(), annotation);
      trackEvent(TRACKING_EVENTS.METRIC_RECIPE_ANNOTATION_CREATED, {
        annotation,
      });
      return result;
    },
    [auth],
  );

  const editCallback = useCallback(
    async (annotation: Annotation) => {
      const result = editAnnotation(await auth.getToken(), annotation);
      trackEvent(TRACKING_EVENTS.METRIC_RECIPE_ANNOTATION_EDITED, {
        annotation,
      });
      return result;
    },
    [auth],
  );

  const deleteCallback = useCallback(
    async (id: number) => {
      const result = deleteAnnotation(await auth.getToken(), id);
      trackEvent(TRACKING_EVENTS.METRIC_RECIPE_ANNOTATION_DELETED, {
        id,
      });
      return result;
    },
    [auth],
  );

  const {
    data: manualAnnotations,
    isFetching: manualAnnotationsFetching,
    refetch: refetchManualAnnotations,
  } = useQuery({
    queryKey: [
      "getAnnotations",
      tenant?.id,
      selectedViewIds,
      auth.user?.tenantId,
    ],
    queryFn: async () => {
      return getAnnotations(await auth.getToken(), selectedViewIds);
    },
    enabled: !auth.processing,
    staleTime: 2000 * 60,
  });

  const annotationContextValue: AnnotationContextType = {
    openInfo,
    setOpenInfo,
    addAnnotation,
    editAnnotation: editCallback,
    deleteAnnotation: deleteCallback,
    manualAnnotations,
    manualAnnotationsFetching,
    refetchManualAnnotations,
  };

  return annotationContextValue;
};
export const ProvideAnnotations: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const ctx = useProvideAnnotations();

  return (
    <AnnotationContext.Provider value={ctx}>
      {children}
    </AnnotationContext.Provider>
  );
};
