import { ReactNode, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useViews } from "../../../components/CountryFilters/views/ViewProvider";
import { useBootstrap } from "../../../hooks/bootstrap";
import { useConnectorObservability } from "../../../hooks/connectorObservability";
import { useMetricList } from "../../../hooks/metricList";
import { useIsInView } from "../../../hooks/useIsInView";
import { useLoadKeyIndicatorMetric } from "../../../hooks/useLoadKeyIndicatorMetric";
import { useMutableRef } from "../../../hooks/useMutableRef";
import { useSmartFilter } from "../../../hooks/useSmartFilter";
import {
  BarLineCharts,
  ChartLoader,
  ConnectorIconNames,
  Flex,
  FullWidthWrapper,
  KeyIndicatorCard,
  KeyIndicatorCardWithChart,
  KeyIndicatorCardWithChartProps,
} from "../../../icecube-ux";
import { useDeviceType } from "../../../icecube-ux/hooks/provideDeviceType";
import { KeyIndicatorCardProps } from "../../../icecube-ux/KeyIndicatorCard/KeyIndicatorCard";
import {
  getIntegrationIcon,
  withConnectorDescription,
} from "../../../integrations/integration";
import { KIGoalSettings } from "../../../lib/kiSectionsService";
import MetricInfoIcon from "../../../shared/Metrics/MetricInfoIcon";
import { MetricDescription } from "../../../types/synthesizer";
import { ComposeRules } from "../../../utils/composeUtils";
import {
  connectorStatus,
  getBusinessUsageOfConnector,
  getMissingLabel,
} from "../../../utils/connectors";
import { Granularity } from "../../../utils/dateUtils";
import { TRACKING_EVENTS, trackEvent } from "../../../utils/trackingUtils";
import {
  isMetricExplorable,
  isMetricExploreNew,
} from "../../custom/metric-components/utils";
import { shouldIgnoreStatusInKI } from "../../custom/shared/utils";
import { useGoalSettings } from "../hooks/useGoalSettings";

import { getSyncingConnectors } from "./getSyncingConnectors/getSyncingConnectors";

interface MetricCardWrapperProps {
  sectionId: number;
  metricIndex: number;
  metricName: string;
  label: string;
  requirements: string[] | string[][];
  invertedEvolution?: boolean;
  description?: MetricDescription[];
  onEditGoal: () => void;
  ratio: boolean;
  percentage: boolean;
  goal?: KIGoalSettings;
  icons: ConnectorIconNames[];
  mobileVersion?: boolean;
  isChartDisplay?: boolean;
  chartGranularity?: Granularity;
  hideGoalButton?: boolean;
  extraRules?: ComposeRules;
  forceNoCompare?: boolean;
}
export const getLoadingPriorities = ({
  isInView,
  isPixelMetric,
  isChartDisplay,
}: {
  isInView: boolean;
  isPixelMetric: boolean;
  isChartDisplay: boolean;
}) => {
  return {
    actualTotals: isInView ? 1 : isPixelMetric ? 998 : 4,
    compareTotals: isInView ? 1 : isPixelMetric ? 998 : 4,
    actualTable: isChartDisplay
      ? isInView
        ? 2
        : isPixelMetric
        ? 999
        : 5
      : 100,
    compareTable: isChartDisplay
      ? isInView
        ? 3
        : isPixelMetric
        ? 999
        : 6
      : 200,
  };
};
export default function MetricCardWrapper({
  sectionId,
  metricIndex,
  metricName,
  label,
  requirements,
  invertedEvolution,
  description,
  onEditGoal,
  ratio,
  percentage,
  goal,
  icons,
  mobileVersion,
  isChartDisplay = false,
  chartGranularity,
  hideGoalButton,
  extraRules,
  forceNoCompare,
}: MetricCardWrapperProps) {
  const navigate = useNavigate();
  const { tenant, isDemoData } = useBootstrap();
  const { range } = useSmartFilter();

  const { statuses: allStatuses } = useConnectorObservability();
  const {
    doSelectedViewsCoverConnector,
    doSelectedViewsCoverAccountId: doSelectedViewsCoverDatasource,
  } = useViews();
  const { tableKeyToConnectorKey, flattenMetrics } = useMetricList();

  const [cardNode, cardRef] = useMutableRef<HTMLDivElement>();
  const [chartNode, chartRef] = useMutableRef<HTMLDivElement>();

  const isCardInView = useIsInView(cardNode);
  const isChartInView = useIsInView(chartNode);
  const { isMobile } = useDeviceType();

  const isInView = isCardInView || isChartInView;
  const isPixelMetric = metricName.indexOf("pixel") !== -1;

  const missingConnectorOnClick = useCallback(
    () => navigate("/connectors"),
    [navigate],
  );

  const requirementsString = requirements.toString();

  const { missingConnectorLabel, loadingConnector, notReadyConnector } =
    useMemo(() => {
      const requirementList = Array.isArray(requirements[0] ?? [])
        ? (requirements as string[][])
        : [requirements as string[]];
      const getStatusesPerRequirementList = (connectorList: string[]) => {
        const statuses = Object.fromEntries(
          connectorList.map((tableKey) => [
            tableKey,
            tableKeyToConnectorKey[tableKey] &&
              tableKeyToConnectorKey[tableKey].map((c) =>
                connectorStatus(c, allStatuses),
              ),
          ]),
        );
        const availableConnectors = connectorList.filter(
          (tableKey) =>
            shouldIgnoreStatusInKI(tableKey) ||
            !statuses[tableKey] ||
            statuses[tableKey].some((status) => status !== "none"),
        );

        const notReadyConnectors = connectorList.filter(
          (tableKey) =>
            statuses[tableKey] &&
            (tableKey === "ga_main"
              ? statuses[tableKey].every((status) => status === "not_setup")
              : statuses[tableKey].some((status) => status === "not_setup")),
        );

        const inProgressConnectors = connectorList.filter(
          (tableKey) =>
            statuses[tableKey] &&
            statuses[tableKey].some((status) => status === "loading"),
        );

        const missingLabelStatus = connectorList.reduce(
          (acc, c) => {
            const connectorKey = tableKeyToConnectorKey[c]?.[0];
            const businessUsage = getBusinessUsageOfConnector(connectorKey);
            const pushTarget = businessUsage.isAdPlatform
              ? acc.adPlatform
              : businessUsage.isSalesChannel
              ? acc.salesChannel
              : acc.unknownSource;
            pushTarget.push(connectorKey || c);
            return acc;
          },
          {
            adPlatform: [] as string[],
            salesChannel: [] as string[],
            unknownSource: [] as string[],
          },
        );
        const missingLabel = getMissingLabel({
          connectorList: connectorList?.map(
            (tableKey) => tableKeyToConnectorKey[tableKey]?.[0],
          ),
          ...missingLabelStatus,
        });

        const loadingIcon = getIntegrationIcon(
          tableKeyToConnectorKey[inProgressConnectors[0]]?.[0],
        );
        return {
          missingConnectorLabel:
            availableConnectors.length === 0 && !isDemoData
              ? missingLabel
              : undefined,
          notReadyConnector:
            notReadyConnectors.length === connectorList.length &&
            notReadyConnectors.length > 0 &&
            !isDemoData
              ? loadingIcon
              : undefined,
          loadingConnector:
            inProgressConnectors.length === connectorList.length &&
            inProgressConnectors.length > 0 &&
            !isDemoData
              ? loadingIcon
              : undefined,
        };
      };

      const statuses = requirementList.map(getStatusesPerRequirementList);

      return statuses.reduce((p, c) => ({
        missingConnectorLabel:
          c.missingConnectorLabel || p.missingConnectorLabel,
        notReadyConnector: c.notReadyConnector || p.notReadyConnector,
        loadingConnector: c.loadingConnector || p.loadingConnector,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allStatuses, requirementsString]);

  const skipCompose =
    Boolean(missingConnectorLabel) ||
    Boolean(loadingConnector) ||
    Boolean(notReadyConnector);

  const {
    actualQuery,
    formatter,
    leftSeries,
    onIndexChange,
    rangeValues,
    current,
    previous,
    focusedDate,
    issueWithMetric,
  } = useLoadKeyIndicatorMetric({
    goal,
    metricName,
    requestPriorities: getLoadingPriorities({
      isInView,
      isPixelMetric,
      isChartDisplay,
    }),
    options: {
      skipActualTableData: !isChartDisplay,
      skipCompareTableData: !isChartDisplay,
      skipAll: skipCompose,
    },
    extraRules,
    forceNoCompare,
  });

  const hasDataForSelectedView =
    !!current ||
    doSelectedViewsCoverConnector(requirements, tableKeyToConnectorKey);
  const rangeEndString = range.end.toISOString();
  const syncingConnectors = useMemo(
    () =>
      getSyncingConnectors({
        hasDataForSelectedView,
        requirements,
        allStatuses,
        rangeEnd: range.end,
        tableKeyToConnectorKey,
        doSelectedViewsCoverDatasource,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rangeEndString, allStatuses, requirementsString, hasDataForSelectedView],
  );

  const negative = parseFloat(current + "") < parseFloat(previous + "");
  const evolution =
    current !== undefined && previous
      ? Math.round((100 * (current - previous)) / previous)
      : undefined;

  const { goalSettings } = useGoalSettings({
    goal,
    ratio,
    percentage,
    current,
    formatter,
    granularity: chartGranularity,
  });

  const params = new URLSearchParams({
    sectionId: sectionId?.toString(),
    metricIndex: metricIndex?.toString(),
    ...(chartGranularity && { granularity: chartGranularity.toString() }),
  });

  const chart = useMemo(() => {
    const isLoading =
      skipCompose || issueWithMetric ? false : actualQuery.loadingData;
    if (
      (!isInView && isLoading) ||
      sectionId === undefined ||
      metricIndex === undefined
    ) {
      return null;
    }

    const height = 100;

    if (isLoading) {
      return (
        <Flex justifyContent="center" style={{ width: "100%" }}>
          <Flex
            flexDirection="column"
            style={{ paddingBottom: 8, width: "100%" }}
          >
            <ChartLoader withMargin={false} height={height}></ChartLoader>
          </Flex>
        </Flex>
      );
    }

    return (
      <FullWidthWrapper height={height}>
        <BarLineCharts
          width={0 /* unused */}
          height={0 /* unused */}
          series={leftSeries}
          dates={rangeValues.x}
          xAxisValues={rangeValues.x}
          xAxis={{
            withTicks: true,
            fontSize: 8,
            shortDateFormat: true,
            tickCount: 4,
          }}
          margin={[6, 2, 24, 0]}
          leftAxis={{
            fontSize: 9,
            tickCount: "adaptive",
            highlightValue: goalSettings?.chartValue ?? undefined,
          }}
          onIndexHoverChange={(index) => onIndexChange(index)}
          withTooltip={false}
          withVerticalLine={true}
          leftAxisFormatter={formatter}
        />
      </FullWidthWrapper>
    );
  }, [
    isInView,
    onIndexChange,
    leftSeries,
    rangeValues.x,
    formatter,
    sectionId,
    metricIndex,
    goalSettings,
    actualQuery.loadingData,
    issueWithMetric,
    skipCompose,
  ]);

  type SharedKeyIndicatorProps = Pick<
    KeyIndicatorCardProps,
    Extract<keyof KeyIndicatorCardProps, keyof KeyIndicatorCardWithChartProps>
  >;

  const connectorKey = tableKeyToConnectorKey[requirements?.[0] as string]?.[0];
  const infoComponent = useMemo(() => {
    return (
      <MetricInfoIcon
        metricKey={metricName}
        label={label}
        description={withConnectorDescription(connectorKey, description)}
        iconContext="key-indicators"
      />
    );
  }, [metricName, label, description, connectorKey]) as ReactNode;

  const sharedKeyIndicatorProps: SharedKeyIndicatorProps = {
    indicatorKey: metricName,
    loading: skipCompose || issueWithMetric ? false : actualQuery.loadingTotal,
    title: label,
    value: current === undefined ? undefined : formatter(current),
    hasNoDataForSelectedView: !hasDataForSelectedView,
    wasValue: previous === undefined ? undefined : formatter(previous),
    evolution:
      evolution === undefined || isNaN(evolution)
        ? "-"
        : `${Math.floor(evolution)}%`,
    negative,
    icons,
    infoComponent,
    invertedEvolution,
    missingConnectorLabel,
    notReadyConnector,
    loadingConnector,
    issueWithMetric,
    missingConnectorOnClick,
    syncingConnectors,
    onEditGoal,
    mobileVersion,
    hideGoalButton,
    showExploreMetric:
      isMetricExplorable(flattenMetrics, metricName) && !isMobile,
    isMetricExploreNew: isMetricExploreNew(metricName),
  };

  return isChartDisplay ? (
    <KeyIndicatorCardWithChart
      {...sharedKeyIndicatorProps}
      focusedDate={focusedDate}
      hasGoal={!!goalSettings}
      ref={chartRef}
    >
      <Link
        to={`${window.location.pathname}?${params.toString()}`}
        style={{ width: "100%" }}
        onClick={() => {
          trackEvent(TRACKING_EVENTS.DETAILED_KI_OPENED, {
            tenantId: tenant.id,
            search: params,
            metricLabel: label,
          });
        }}
      >
        {chart}
      </Link>
    </KeyIndicatorCardWithChart>
  ) : (
    <KeyIndicatorCard
      {...sharedKeyIndicatorProps}
      ref={cardRef}
      goal={goalSettings}
    />
  );
}
