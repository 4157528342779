import { useState } from "react";
import styled, { css } from "styled-components";

import { Box } from "../../icecube-ux";
import { AddonTab } from "../Addon/AddonTab";
import { AvatarBlock } from "../Avatar/Avatar";

import { FeedbackTab } from "./FeedbackTab";
import { NavigationItem } from "./NavigationItem";
import { NavigationToggle } from "./NavigationToggle";
import "../global.css";
import { NavigationItemType } from "./types";

const StyledNavigation = styled.nav<{
  isNavOpen: boolean;
  isToggledOpen: boolean;
  isHovered: boolean;
  hasTopBanner: boolean;
}>`
  position: sticky;
  left: 24px;
  top: 24px;
  box-sizing: border-box;
  height: calc(100vh - 48px);
  z-index: 3;
  transition: width 0.1s;
  width: 72px;
  z-index: 51;

  ${(p) =>
    p.isToggledOpen &&
    css`
      width: 250px;
    `}

  ${(p) =>
    p.isHovered &&
    css`
      z-index: 302;
    `}

  ${(p) =>
    p.hasTopBanner &&
    css`
      height: calc(100vh - 108px);
      top: 12px;
    `}
`;

const StyledNavigationContent = styled.div<{ closedButHovered: boolean }>`
  position: absolute;

  inset: 0px;

  flex-direction: column;
  display: flex;

  border-radius: 12px;
  overflow: hidden;
  background: white;
  box-shadow: 0px -8px 34px rgba(33, 195, 253, 0.05);
  transition: all 0.1s;

  ${(p) =>
    p.closedButHovered &&
    css`
      right: -158px;
      width: 250px;
      box-shadow:
        5px 0px 24px rgb(47 41 156 / 8%),
        0px 2px 4px rgb(47 41 156 / 12%);
    `}
`;

const StyledNavigationItems = styled.ul<{ isNavOpen: boolean }>`
  margin-top: -2px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.2s ease-in;
  -ms-overflow-style: none;
  scrollbar-width: none;

  padding: 8px 0px;
  gap: 5px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(p) =>
    !p.isNavOpen &&
    css`
      padding-top: 14px;
      margin-top: -6px;
    `}
`;

interface NavigationProps {
  isToggledOpen: boolean;
  onToggleOpen: (state: boolean) => void;
  logo: string;
  email: string;
  company?: string;
  username?: string;
  items: NavigationItemType[];
  className?: string;
  onClickReferrals?: () => void;
  hasTopBanner: boolean;
  onClickOpenTenantList?: () => void;
  onClickUserBoxSettings?: () => void;
  onClickUserBoxHelp?: () => void;
  onClickUserBoxLogout?: () => void;
}

export default function Navigation({
  isToggledOpen,
  onToggleOpen,
  logo,
  email,
  company,
  username,
  items,
  className = "",
  onClickReferrals,
  hasTopBanner,
  onClickOpenTenantList,
  onClickUserBoxSettings,
  onClickUserBoxHelp,
  onClickUserBoxLogout,
}: NavigationProps) {
  const [isHovered, setIsHovered] = useState(false);
  const isNavOpen = isToggledOpen || isHovered;

  return (
    <div>
      <StyledNavigation
        data-cy="navigation-bar"
        isNavOpen={isNavOpen}
        isToggledOpen={isToggledOpen}
        isHovered={isHovered}
        hasTopBanner={hasTopBanner}
        className={className}
      >
        <StyledNavigationContent
          closedButHovered={!isToggledOpen && isHovered}
          onMouseLeave={() => setIsHovered(false)}
        >
          <AvatarBlock
            onClickReferrals={onClickReferrals}
            onClickSettings={onClickUserBoxSettings}
            onClickOpenTenantList={onClickOpenTenantList}
            onClickHelp={onClickUserBoxHelp}
            onClickLogout={onClickUserBoxLogout}
            logo={logo}
            email={email}
            company={company}
            username={username}
            isExpanded={isNavOpen}
            onMouseEnter={() => setIsHovered(true)}
          />

          <NavigationToggle
            isNavOpen={isNavOpen}
            isToggledOpen={isToggledOpen}
            toggle={() => {
              setIsHovered(false);
              onToggleOpen(!isToggledOpen);
            }}
          />

          <StyledNavigationItems
            onMouseEnter={() => setIsHovered(true)}
            isNavOpen={isNavOpen}
          >
            {items &&
              items.map((item, i) => (
                <NavigationItem
                  key={`f-${i}`}
                  isNavOpen={isNavOpen}
                  item={item}
                  itemIndex={i}
                  selected={item.selected}
                />
              ))}
          </StyledNavigationItems>

          <Box
            padding="6px"
            boxShadow="0px -1px 0px #eae9ff"
            borderRadius="0px 0px 12px 12px"
          >
            <FeedbackTab isExpanded={isNavOpen} />
            <AddonTab isExpanded={isNavOpen} />
          </Box>
          <Box clear="both" />
        </StyledNavigationContent>
      </StyledNavigation>
    </div>
  );
}
