import { NotificationMessage, Text, theme } from "../../../../icecube-ux";
import { _, _node } from "../../../../languages/helper";

const { primary110, text110, primary100 } = theme.colors;

export const AmazonSellerConnectMessage = () => (
  <>
    <NotificationMessage
      variant="long"
      style={{ alignItems: "start", padding: "12px" }}
      textContent={
        <>
          <Text variant="body12Regular" color={text110}>
            <Text variant="body12SemiBold" color={primary110}>
              {_`Important`}
            </Text>
            <Text variant="body12Regular" color={text110} inline>
              {_`If you have an error while connecting your Amazon Seller account to the app, it's most likely a permission issue.`}{" "}
              {_node`Read the detailed article ${[
                "link",
                <a
                  href="https://intercom.help/polar-app/en/articles/7833361-how-can-i-connect-amazon-seller-central-to-polar"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Intercom help"
                >
                  <Text
                    variant="body12Regular"
                    color={primary100}
                    inline
                    underline
                  >
                    {_`here|||link`}
                  </Text>
                </a>,
              ]}.`}{" "}
            </Text>
          </Text>
          <Text variant="body12Regular" color={text110}>
            <Text
              style={{ marginTop: "12px" }}
              variant="body12SemiBold"
              color={primary110}
            >
              {" "}
              {_`Step ${["number", "1"]}`}{" "}
            </Text>
            <span>
              {_node`In Amazon's ${[
                "link",
                <a
                  href="https://sellercentral.amazon.com/gp/account-manager/home.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="User permissions"
                >
                  <Text
                    variant="body12Regular"
                    color={primary100}
                    inline
                    underline
                  >
                    User permissions
                  </Text>
                </a>,
              ]}, ensure you have maximal ${[
                "viewAndEdit",
                <span className="italic">View and edit</span>,
              ]} permissions. If you don’t, you'll need to invite to Polar someone who does, provide them with Polar Admin rights, and let them make the connection.`}{" "}
            </span>
          </Text>
          <Text variant="body12Regular" color={text110}>
            <Text
              style={{ marginTop: "12px" }}
              color={primary110}
              variant="body12SemiBold"
            >
              {" "}
              {_`Step ${["number", "2"]}`}{" "}
            </Text>
            <span>
              {" "}
              {_node`Navigate to your Polar Connectors and connect your Amazon Seller account. ${[
                "check-page",
                <Text variant="body12SemiBold" color={text110} inline>
                  {_`Make sure you are on the page of the correct country.`}
                </Text>,
              ]} If you aren’t, keep the same URL and edit the last part of the URL (the top level domain: https://sellercentral.amazon${[
                "topLevelDomain",
                <Text inline variant="body12SemiBold" color={text110}>
                  .com
                </Text>,
              ]}/…) to redirect the page of the country you wish to connect. Grant Polar all permissions, then navigate back to Polar and select the right region in the connectors tab.`}
            </span>
          </Text>
        </>
      }
    />
    <br />
    <NotificationMessage
      variant="long"
      style={{ alignItems: "start", padding: "12px" }}
      textContent={
        <>
          <Text variant="body12Regular" color={text110}>
            <Text variant="body12SemiBold" color={primary110}>
              {_`API Limitations`}
            </Text>
            <Text variant="body12Regular" color={text110} inline>
              {_`The Amazon Seller Central initial historical sync can take up to 2 weeks because the Amazon API only releases a small number of data points per second, and is limited to 2 years of historical data.`}{" "}
            </Text>
          </Text>
        </>
      }
    />
    <br />
  </>
);
