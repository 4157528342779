import { useState } from "react";
import { NotificationManager } from "react-notifications";

import { useAuth } from "../hooks/auth/auth";
import { useBootstrap } from "../hooks/bootstrap";
import { _ } from "../languages/helper";
import { createTenant } from "../lib/usersService";
import { deriveUserTimezone } from "../utils/dateUtils";

export const useAvailableTenants = () => {
  const auth = useAuth();
  const bootstrap = useBootstrap();

  const [loadingLogAsTenant, setLoadingTenant] = useState<null | string>(null);
  const [loadingCreateTenant, setLoadingCreateTenant] = useState(false);

  const handleLogAsTenant = async (tenantId: string) => {
    setLoadingTenant(tenantId);
    const result = await auth.logAsTenant(tenantId);
    await bootstrap.forceRefresh();
    if (result.error) {
      NotificationManager.error(_(result.message || "Unknown error"), _`Error`);
    }

    return {
      error: !!result.error,
    };
  };

  const handleCreateTenant = async ({
    newTenantName,
    sourceTenantId,
  }: {
    newTenantName: string;
    sourceTenantId?: string;
  }) => {
    setLoadingCreateTenant(true);
    let success = true;
    const result = await createTenant(await auth.getToken(), {
      sourceTenantId: sourceTenantId || undefined,
      companyName: newTenantName,
      timezone:
        bootstrap.getUserTenantSetting("timezone", deriveUserTimezone()) ||
        deriveUserTimezone(),
    });
    if (result.error) {
      success = false;
      NotificationManager.error(_(result.message || "Unknown error"), _`Error`);
    } else {
      const logAsResult = await handleLogAsTenant(result.data.id);
      success = !logAsResult.error;
    }
    setLoadingCreateTenant(false);

    return {
      error: !success,
    };
  };

  return {
    handleLogAsTenant,
    loadingLogAsTenant,
    handleCreateTenant,
    loadingCreateTenant,
  };
};
